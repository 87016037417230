import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox, Radio, Select } from 'final-form-material-ui';
import Autocomplete from '../../../../components/ui/AutoComplete'
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { getTag, putTag, createTag } from '../../../../business/actions/tags'
import { validateFields } from '../../../../utils'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getExpensesCategories } from '../../../../business/actions/expense-categorisation';
import { postCostManagementInvoicesPDFS, postFileToPresignedURL } from '../../../../business/actions/invoices-pdfs';
const testData = { "text": "Booster" }

export default function Create(props) {
  const history = useHistory();
  const tag_id = props.match.params?.tag_id || null
  const [busy, setBusy] = useState(false)
  const [expensesCategories, setExpensesCategories] = useState([])

  const inputEl = useRef(null)

  useEffect(() => {
    getExpensesCategories({}).then(json => setExpensesCategories(json))
  }, [tag_id])
  const validate = values => validateFields(values, ["invoice", "name", "amount", "expensesCategory"])
  const onSubmit = (values) => {
    setBusy(true)
    let { name, amount, invoice } = values;
    let postData = { original_file_name: invoice[0].name, name, amount, expense_categorization_id: expensesCategories.find(item => item.text === values.expensesCategory).id }
    postCostManagementInvoicesPDFS(postData)
      .then(result => {
        let { invoice: url } = result;
        if (url) {
          return postFileToPresignedURL(url, invoice).then(response => {
            toast('Invoice uploaded');
            setTimeout(history.goBack, 1000)
          })
        }
        throw new Error("Invoice Path not specified")
      })
      .catch(error => toast(error.response.data?.message || "Unexpected error"))
      .finally(() => { setBusy(false) })
    return true;
  }
  return (
    <div className={"form-wrapper"}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          invoice: "",
          expensesCategory: "",
          name: "",
          amount: ""
        }}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper>
              <div style={{ padding: 16 }}>
                <Typography variant="body1" component="div">{tag_id ? 'Edit' : 'Create'} Invoice</Typography>
              </div>
              <Divider />
              <div style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field<FileList> name="invoice" id="invoice" fullWidth required label="Browse" >
                      {({ input: { value, onChange, ...input } }) => (
                        <>
                          <input
                            {...input}
                            ref={inputEl}
                            style={{ display: "none" }}
                            type="file"
                            name="invoice"
                            id="invoice"
                            onChange={({ target }) => onChange(target.files)} // instead of the default target.value
                          />
                          <Button fullWidth type="button" variant="contained" color="primary" onClick={() => inputEl.current.click()} disabled={submitting}>
                            Browse Invoice
                          </Button>
                          {value ? <p>{value[0].name}</p> : null}
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="expensesCategory"
                      fullWidth required component={Autocomplete} label="Expenses Category"
                      formControlProps={{ fullWidth: true }}
                      options={(expensesCategories || []).map(({ text }) => text)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="name" fullWidth required component={TextField} type="text" label="Invoice Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="amount" fullWidth required component={TextField} type="number" label="Amount" />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button type="button" variant="contained" onClick={() => form.reset()} disabled={submitting || pristine}>
                      Reset
                    </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                      {'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </form>
        )}
      />
      {busy ? <div className='busy-progress-circular'><CircularProgress /></div> : null}
    </div>
  );
}



const FileField = ({ name, ...props }: Props) => (
  <Field<FileList> name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <input
        {...input}
        type="file"
        onChange={({ target }) => onChange(target.files)} // instead of the default target.value
        {...props}
      />
    )}
  </Field>
)
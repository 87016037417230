import List from './List'
import Create from './Create'
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

export default () => <Switch>
  <Route path={'/users/list'} component={List} />
  <Route path={'/users/create'} component={Create} />
  <Route path={'/users/:id/edit'} component={Create} />
  <Route path={'*'}>
    <Redirect to="/users/list" />
  </Route>
</Switch>
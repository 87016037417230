import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Button,
  CssBaseline,
  Modal,
  Grid,
  MenuItem,
  makeStyles,
  IconButton,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { useAuth } from '../../../business/authProvider';
import DataGrid from '../../../components/ui/Table'
import { archiveTransaction, getTransaction, refreshTransaction, retryTransaction, unarchiveTransaction, updateTransactionNotes } from '../../../business/actions/transactions';
import { getPayee, } from '../../../business/actions/payees'
import { convertDateToItalyTime } from '../../../utils'
import ViewField from '../../../components/ui/ViewField'
import LinkField from '../../../components/ui/LinkField'
import PageHeading from '../../../components/ui/PageHeading';
import RefreshIcon from '@material-ui/icons/Refresh';
import CachedIcon from '@material-ui/icons/Cached';
import ArchiveIcon from '@material-ui/icons/Archive';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import NotesField from '../../../components/ui/NotesField'
import TransactionNotes from './Notes';
import { IColumn } from '../../../core';
import ProgressActionButton from '../../../components/ProgressActionButton';
import { useHistory } from 'react-router-dom';
import { ADVCASH, PAYPAL, TRANSFERWISE } from '../../../utils/vars';
import { downloadFromS3 } from '../../../business/utils';
import RichButton from '../../../components/RichButton';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),

  },
  list: {
    maxHeight: 400,
    minHeight: 400,
    overflowY: 'auto'
  }
}));

const columns: Array<IColumn> = [
  { field: 'payout_id', headerName: 'Payout Id' },
  { field: 'payout_amount', headerName: 'Payout Amount', formatting: num => num.toFixed(2) },
  { field: 'payout_currency', headerName: 'Currency' },
  { field: 'description', headerName: 'Description' }
];

const status_column: Array<IColumn> = [
  { field: 'timestamp', headerName: 'Date and Time', style: { minWidth: 180 }, formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'message', headerName: 'Status' },
];


export default function View(props) {
  const auth = useAuth();
  const history = useHistory();
  /**
   * transaction_id from the route
   */
  const { transaction_id } = props.match.params;
  const [transaction, setTransaction] = useState({
    "added_by": "",
    "payout_status": "",
    "payouts": [],
    "status_history": [],
    "parent_id": "",
    "payee_id": "",
    "paypal_batch_id": "",
    "transferwise_id": "",
    "transaction_note": "",
    "archived": false,
    "tw_estimated_arrival": "",
    "transaction_invoice": "",
    "transaction_notes": [{
      admin_name: "",
      transaction_note: "",
      timestamp: ""
    }]
  })
  const [payee, setPayee] = useState({
    "payout_email": "",
    "discord": "",
    "payout_method": "",
    "email": "",
    "payee_id": "",
    "user_id": "",
    "last_name": "",
    "pause_payee": "",
    "first_name": "",
    "username": "",
    "transferwise_id": "",
    "tags": [],
    "executed_transactions": [],
    "future_transactions": { "number_of_payouts": 0, "payout_amount": "0" }
  })
  const [openModal, showTransactions] = useState(null)
  const [busy, setBusy] = useState(false)
  const classes = useStyles();
  const authRoles = auth.roles;
  /**
   * 
   */
  const loadTransaction = () => {
    getTransaction(transaction_id).then(response => {
      getPayee(response.payee_id).then(setPayee)
      let { transaction_notes } = response;
      let transaction_note = "";
      if (transaction_notes.length) {
        transaction_notes = transaction_notes.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
        transaction_note = transaction_notes[0].transaction_note;
      }
      setTransaction({
        ...response,
        payouts_total: response.payouts.length,
        payout_amount_total: response.payouts?.reduce((a, b) => ({ payout_amount: a.payout_amount + b.payout_amount })).payout_amount.toFixed(2) || 0,
        sent_date: convertDateToItalyTime(response.created_at),
        tw_estimated_arrival: response.tw_estimated_arrival ? convertDateToItalyTime(response.tw_estimated_arrival) : null,
        payouts: response.payouts.map(payout => ({ ...payout, "payout_currency": "EUR" })),
        status_history: response.status_history.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1),
        transaction_note
      })
    })
  }

  /**
   * 
   */
  useEffect(loadTransaction, [transaction_id]);
  /**
   * 
   * @param {*} value 
   * @returns 
   */
  const onSaveNote = (value: string) => updateTransactionNotes(transaction_id, { transaction_note: value });
  /**
   * 
   */
  const downloadTransactionInvoice = () => downloadFromS3(transaction.transaction_invoice, "invoice.pdf")
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ margin: 'auto' }}>
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Paper>
              <PageHeading title={'Transactions Details'}>
                <div>
                  {transaction.payout_status === "failed" ?
                    <RichButton
                      type='IconButton'
                      icon='Cached'
                      onComplete={loadTransaction}
                      action={retryTransaction}
                      data={transaction_id}
                      iconColor={"inherit"}
                      toolTip="Retry Status"
                    />
                    :
                    null
                  }
                  <RichButton
                    type='IconButton'
                    icon='Refresh'
                    onComplete={loadTransaction}
                    action={refreshTransaction}
                    data={transaction_id}
                    iconColor={"inherit"}
                    toolTip="Refresh Status"
                  />
                  <RichButton
                    type='IconButton'
                    icon={transaction.archived ? 'Unarchive' : 'Archive'}
                    waitBeforeTriggerComplete={1000}
                    action={transaction.archived ? unarchiveTransaction : archiveTransaction}
                    data={transaction_id}
                    iconColor={"inherit"}
                    toolTip={transaction.archived ? "Unarchive Transaction" : "Archive Transaction"}
                  />
                  <RichButton
                    type='IconButton'
                    icon='CloudDownload'
                    action={downloadTransactionInvoice}
                    data={transaction}
                    iconColor={"inherit"}
                    toolTip="Download Invoice"
                  />
                </div>
              </PageHeading>
              <Divider />
              <div style={{ padding: 16 }}>
                <ViewField type="chips" record={payee} source="tags" labelField={"text"} label="Tags" />
                <LinkField record={transaction} source="payee_id" label="Payee ID" link={`/payees/${transaction['payee_id']}/view`} />
                <ViewField record={transaction} source="payout_status" label="Transaction Status" />
                {transaction.payout_status === "failed" ? <ViewField record={transaction} source="failure_reason" label="Failed Transaction Reason" /> : null}
                {
                  transaction.paypal_batch_id ?
                    <ViewField record={payee} source="payout_email" label="Payout Email" />
                    : transaction.transferwise_id ?
                      <ViewField record={payee} source="transferwise_id" label="TransferWise ID" />
                      :
                      null
                }
                {
                  (auth.permission || authRoles['post_transaction_transaction_id_update_note']) ?
                    <ViewField label="Transaction Note">
                      <NotesField
                        defaultValue={transaction.transaction_note}
                        saveAction={onSaveNote}
                        successMessage={'Transaction note saved.'}
                        errorMessage={'Transaction note is not saved. Unexpected error'}
                        maxLength={200}
                      />
                      <TransactionNotes items={transaction.transaction_notes || []} />
                    </ViewField>
                    :
                    <ViewField record={transaction} source="transaction_note" label="Transaction Note" />
                }
                <ViewField record={transaction} source="payouts_total" label="Total Payouts" />
                <ViewField record={transaction} source="payout_amount_total" label="Total Payout Amount" />
                <DataGrid canSort={false} pagination={false} extraColumns={false} canSelect={false} rows={transaction.payouts} columns={columns} pageSize={5} />
                <ViewField record={transaction} source="transaction_id" label="Blazing Boost Full Transaction ID" tooltip="This is an internal code that we use to track this unique payment that was sent to this payee." />
                <ViewField record={transaction} source="batch_id" label="BlazingBoost Batch ID" tooltip="This is the internal code that we use to track a mass payment. A Batch ID contains a Transaction ID per booster. Each transaction ID contains Payout IDs, which corresponds to the order items like S21ABC123 etc. When a batch is executed, Paypal Transaction IDs and more will be generated." />
                <ViewField record={transaction} source="payout_method" label="Payout Method" />
                {
                  transaction.payout_method === PAYPAL ?
                    <>
                      <ViewField record={transaction} source="payout_fee" label="Payout fee at the time of payment" />
                      <ViewField record={transaction} source="paypal_batch_id" label="Paypal Batch ID" tooltip="If we complete a payment through Paypal using the Mass Payment system, Paypal assigns an ID to the mass Payment (which corresponds, in our language, to a batch). This is what this ID corresponds to." />
                      <ViewField record={transaction} source="paypal_payout_id" label="Paypal Payout ID" tooltip="This is the code that the payee will receive on Paypal when he gets paid. We also show this ID because occasionally payees see different transaction IDs on their Paypal as opposed to what we see." />
                      <ViewField record={transaction} source="paypal_transaction_id" label="Paypal Transaction ID" tooltip="This is the code that identifies the single payment through Paypal to a payee (even if made through a Mass Payment) on our side. We always see this code and it corresponds to the code we show on our sold order items when customers buy something on Blazingboost." />
                      <ViewField record={payee} source="payout_email" label="Payout Email" />
                      <ViewField record={transaction} source="payout_email" label="Paypal email at time of payment" />
                    </>
                    : transaction.payout_method === TRANSFERWISE ?
                      <>
                        <ViewField record={transaction} source="transferwise_fee_by_payee" label="Payout fee paid by payee:" />
                        <ViewField record={payee} source="transferwise_id" label="TransferWise ID" />
                        <ViewField record={transaction} source="transferwise_id" label="Transferwise recipient ID at time of payment" />
                        <ViewField record={transaction} source="tw_transaction_id" label="TransferWise Transaction ID" tooltip="This is the code that identifies the single payment through Transferwise to a payee on our side. We always see this code and it corresponds to the code we show on our sold order items when customers buy something on Blazingboost" />
                      </>
                      : transaction.payout_method === ADVCASH ?
                        <>
                          <ViewField record={transaction} source="advcash_payout_fee" label="Payout fee at the time of payment" />
                          <ViewField record={transaction} source="advcash_transaction_id" label="AdvCash Transaction ID" tooltip="This is the code that identifies the single payment through AdvCash to a payee (even if made through a Mass Payment) on our side. We always see this code and it corresponds to the code we show on our sold order items when customers buy something on Blazingboost." />
                          <ViewField record={payee} source="advcash_payout_email" label="Payout Email" />
                          <ViewField record={transaction} source="advcash_payout_email" label="AdvCash email at time of payment" />
                        </>
                        :
                        null
                }
                <ViewField record={transaction} source="sent_date" label="Sent Date" />
                {transaction.transferwise_id ? (transaction.tw_estimated_arrival ? <ViewField record={transaction} source="tw_estimated_arrival" label="Estimated Arrival Date" /> : null) : null}
                <ViewField record={transaction} source="sent_by" label="Sent By" />
              </div>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper>
              <PageHeading title={'Transactions History'}></PageHeading>
              <Divider />
              <div style={{ padding: 16 }}>
                <DataGrid canSort={false} pagination={false} extraColumns={false} canSelect={false} rows={transaction.status_history} columns={status_column} pageSize={5} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
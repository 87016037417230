import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select, Radio } from 'final-form-material-ui';
import {
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';

const PayeeBillingForm = ({
  validate,
  onSubmit,
  formData,
  mode
}) => <Form
    onSubmit={onSubmit}
    initialValues={{
      real_first_name: "",
      real_last_name: "",
      address: "",
      region: "",
      city: "",
      country: "",
      ...formData
    }}
    validate={validate}

    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit} noValidate>
        <FormControl>
          <div style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field name="real_first_name" fullWidth component={TextField} type="text" label="First Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="real_last_name" fullWidth required component={TextField} type="text" label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="address" fullWidth component={TextField} type="text" label="Address" />
              </Grid>
              <Grid item xs={12}>
                <Field name="region" fullWidth component={TextField} type="text" label="Region" />
              </Grid>
              <Grid item xs={12}>
                <Field name="city" fullWidth required component={TextField} type="text" label="City" />
              </Grid>
              <Grid item xs={12}>
                <Field name="country" fullWidth component={TextField} type="text" label="Country" />
              </Grid>

              <Grid item style={{ marginTop: 16 }}>
                <Button type="button" variant="contained" onClick={() => form.reset()} disabled={submitting || pristine}>
                  Reset
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                  {mode === 'edit' ? 'Update' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </FormControl>
      </form>
    )}
  />



export default PayeeBillingForm
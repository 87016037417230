import { createContext, useContext, useEffect, useState } from "react";
import { Auth } from 'aws-amplify';

const authContext = createContext();

function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
function useAuth() {
  return useContext(authContext);
}

function useAuthProvider() {
  const [permission, setPermission] = useState(false);
  const [roles, setRoles] = useState(null);
  const [user, setUser] = useState(null);
  const [canUserPay, setUserCanPay] = useState(null);
  const [canUserBypass, setUserCanBypass] = useState(null);
  useEffect(() => { checkPermission() }, [])
  const checkPermission = () => {
    Auth.currentAuthenticatedUser().then(user => {
      let { payload } = user.signInUserSession.accessToken;
      let roles = payload["cognito:groups"];
      let full_access = roles.find(role => role === 'Full')
      //let roles = ["Full"];
      //setPermission(full_access ? true : false)
      setRoles(roles.reduce(function (obj, v) { obj[v] = true; return obj; }, {}));
      //setUserCanPay(roles?.indexOf("Pay") >= 0)
      //setUserCanBypass(roles?.indexOf("Bypass") >= 0)
      setUser(user)
    }).catch(e => {/** */ })
  }

  const cani = () => {
    return true;
  }
  return {
    cani,
    canUserBypass,
    canUserPay,
    permission,
    roles,
    user
  };
}
export {
  AuthProvider,
  useAuth
}
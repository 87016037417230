import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

/**
 * Define context
 */
const listContext = createContext();
/**
 * 
 * @returns 
 * Use context
 */
function useList() {
  return useContext(listContext);
}
/**
 * 
 * @returns 
 * List Provider
 */
function useListProvider() {
  const history = useHistory()
  const query = useQuery();
  const paginationLimit = parseInt(localStorage.getItem("rows") || 5, 10);
  const [pagination, setPagination] = useState(null)
  const [sort, setSorting] = useState({ order: "desc", orderBy: null })
  const [filters, setFilters] = useState(null)



  useEffect(() => {
    /**
     * convert query array to object
     */
    let obj = {};
    query.forEach((value, key) => {
      obj[key] = decodeURIComponent(value);
      if (obj[key] === "false") obj[key] = false; else if (obj[key] === "true") obj[key] = true;
    });
    /**
     * get the current page number for paginations
     */
    const currentPage = obj.page || 1;
    /**
     * Set pagination, current page always start with 1 while offset must be start with 0 so we should calculate it correctly here
     */
    setPagination({ offset: (currentPage - 1) * paginationLimit, limit: paginationLimit });
    /**
     * delete the page param because filter doesnt have it
     */
    delete obj.page;
    setFilters(obj)
  }, [query])
  /**
   * 
   * @param {*} values 
   * @returns 
   */
  const onUpdateFilter = (values) => redirect(values, { page: 1 })
  /**
   * 
   * @param {*} values 
   * @returns 
   */
  const onUpdatePagination = (values) => redirect(filters, { page: parseInt(values.offset / values.limit, 10) + 1 })
  /**
   * 
   * @param {*} f 
   * @param {*} p 
   */
  const redirect = (f, p) => {
    let queryObject = { ...f, ...p };
    let queryString = Object.keys(queryObject).map(key => `${key}=${encodeURIComponent(queryObject[key])}`).join("&")
    history.push(`${history.location.pathname}?${queryString}`)
  }
  const onUpdateSort = (order, orderBy) => setSorting({ order, orderBy })
  return {
    filters,
    pagination,
    sort,
    setFilters,
    onUpdatePagination,
    setSorting,
    onUpdateSort,
    onUpdateFilter
  };
}
/**
 * 
 * @param {*} param0 
 * @returns 
 */
function ListProvider({ children }) {
  const list = useListProvider();
  return (
    <listContext.Provider value={list}>
      {list.filters && list.pagination ? children : null}
    </listContext.Provider>
  );
}

function useQuery() {
  const { search, hash } = useLocation();
  return React.useMemo(() => new URLSearchParams(search+hash), [search+hash]);
}

export {
  ListProvider,
  useList
}
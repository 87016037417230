import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../../business/authProvider';
import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Paper } from "@material-ui/core";
import PageHeading from "../../../../components/ui/PageHeading";
import ViewField from "../../../../components/ui/ViewField";
import { Auth } from "aws-amplify";
import { Field, Form, useField } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { validateFields } from "../../../../utils";
import { OnChange } from 'react-final-form-listeners'
import { toast } from 'react-toastify';
import { Hub } from "@aws-amplify/core";

const UpdatePasswordPage = () => {
  const [badge, setBadge] = useState<number>(0)
  const colors = ["grey", "red", "orange", "blue", "green"];

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPassword, newPassword);
      toast('Password updated.');
      setTimeout(async () => {
        await Auth.signOut();
        Hub.dispatch("UI Auth", {
          event: "AuthStateChange",
          message: "signedout",
        });
      }, 2000)
    } catch (err) {
      toast('Password could not be updated. Please try again with correct password.');
    }
  };

  const onSubmit = async values => {
    if (strengthChecker(values.newPassword) === 4) {
      await changePassword(values.oldPassword, values.newPassword)
    }
  };
  const strengthChecker = (password) => {
    // We then change the badge's color and text based on the password strength
    var currentBadge = 0;
    if (password.length < 8 || password.length > 16) {
      currentBadge = 0
    }
    else {
      if (password.match(/[a-z]+/)) {
        currentBadge = 1
      }
      if (password.match(/[A-Z]+/)) {
        currentBadge = 2
      }
      if (password.match(/[0-9]+/)) {
        currentBadge = 3
      }
      if (password.match(/[$@#?&!]+/)) {
        currentBadge = 4
      }
    }
    setBadge(currentBadge)
    return currentBadge;
  }

  return (<div style={{ height: 400, width: '100%' }}>
    <Paper>
      <PageHeading title={'Update Password'}>
      </PageHeading>
      <Divider />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        }}
        validate={values => {
          const errors = {};
          if (!values.oldPassword) {
            errors.oldPassword = "Old password is Required";
          }
          if (!values.newPassword) {
            errors.newPassword = "Passwod is Required";
          } else if (values.newPassword.length < 8) {
            errors.newPassword = "Passwod should be minimum 8 character long";
          } else if (values.newPassword.length > 16) {
            errors.newPassword = "Passwod should not be more than 16 character long";
          } else {
            if (!(values.newPassword.match(/[a-z]+/) &&
              values.newPassword.match(/[A-Z]+/) &&
              values.newPassword.match(/[0-9]+/) &&
              values.newPassword.match(/[$@#?&!]+/))) {
              errors.newPassword = "Passwod should be 8-16 character long and contain one uppercase, one lowercase, a number and one special character. Allowed special characters are $@#?&!";
            }
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          } else if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = "Password and confirm password does not match";
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16 }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Field name="oldPassword" fullWidth required component={TextField} type="password" label="Current Password" />
                </Grid>
                <Grid item xs={12}>
                  <Field name="newPassword" fullWidth required component={TextField} type="password" label="New Password" />
                  <OnChange name="newPassword">
                    {(value: string, previous: string) => {
                      strengthChecker(value)
                    }}
                  </OnChange>
                  <div style={{
                    marginTop: 8,
                    marginBottom: 8,
                    borderRadius: 6,
                    display: "flex",
                    gap: 12,
                    alignItems: "center"
                  }}>
                    <span>Password strength:</span>
                    <div style={{ display: "flex", gap: 2 }}>
                      <div style={{ width: 24, height: 4, backgroundColor: colors[badge], borderRadius: 8 }}></div>
                      <div style={{ width: 24, height: 4, backgroundColor: colors[badge], borderRadius: 8 }}></div>
                      <div style={{ width: 24, height: 4, backgroundColor: badge < 2 ? "grey" : colors[badge], borderRadius: 8 }}></div>
                      <div style={{ width: 24, height: 4, backgroundColor: badge < 3 ? "grey" : colors[badge], borderRadius: 8 }}></div>
                      <div style={{ width: 24, height: 4, backgroundColor: badge < 4 ? "grey" : colors[badge], borderRadius: 8 }}></div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Field name="confirmPassword" fullWidth component={TextField} type="password" label="Confirm Password" />
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => form.reset()}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </Paper>
  </div>)
}

export default UpdatePasswordPage
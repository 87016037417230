import React, { useEffect, useState } from 'react';
import TextField from './ui/TextField';
import Select from './ui/Select'
import Checkbox from './ui/Checkbox'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles';
import TagFilter from "./ui/TagFilter"
import { Button, colors, FormControl, FormControlLabel, InputLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import { IFilter, ISelectOption } from '../core';
import RoleFilter from './ui/RoleFilter';
import CategorizationFilter from './ui/CategorizationFilter';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    '& > div:first-child': {
      marginLeft: 0
    },
    '& .MuiTextField-root': {
      width: '25ch',
      marginTop: 0
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterOptions: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    rowGap: 16,
    columnGap: 8
  }
}));

interface IFIlterProp {
  data: Array<IFilter>,
  filters: object,
  onUpdateFilter: (values: object) => void
}
interface IFIlterField {
  [key: string]: any;
}
export default ({ data, onUpdateFilter, filters }: IFIlterProp) => {
  const classes = useStyles();
  const [filterFields, setFilterFields] = useState<IFIlterField>({})
  useEffect(() => {
    let defaultValues = {}
    data.map((filter) => {
      if (filter.value) defaultValues[filter.id] = filter.value;
    });
    setFilterFields({ ...defaultValues, ...filters });
  }, [filters])
  /**
   * 
   * @param id 
   * @param value 
   */
  const onChange = (id: string, value: any) => {
    if (value === "") delete filterFields[id];
    else filterFields[id] = value;
    setFilterFields({ ...filterFields })
  }
  /**
   * 
   * @param id 
   * @param value 
   */
  const onChangeDate = (id: string, value: number) => {
    if (isNaN(value)) {
      delete filterFields[id];
      setFilterFields({ ...filterFields })
    }
    else {
      onChange(id, value)
    }
  }
  /**
   * 
   * @param id 
   * @param value 
   */
  const onSelect = (id: string, value: any) => {
    onChange(id, value)
    handleFilter()
  }
  /**
   * 
   * @param id 
   * @param value 
   */
  const onChecked = (id: string, value: any) => {
    onChange(id, value)
    handleFilter()
  }
  /**
   * 
   * @returns 
   */
  const handleFilter = () => onUpdateFilter({ ...filterFields });
  /**
   * 
   */
  const resetFilter = () => {
    setFilterFields({})
    onUpdateFilter({});
  }
  /**
   * 
   * @param filter 
   * @returns 
   */
  const getMultipleOption = (filter: IFilter): any => filter.options?.filter((option: any) => {
    let label = filter.idField ? option[filter.idField] : option.id;
    let selectedOptions = filterFields[filter.id]?.split(",") || [];
    return selectedOptions.find((selectedOption: string) => selectedOption === label)
  })
  /**
   * 
   * @param filter 
   * @param index 
   * @returns 
   */
  const getFilterInput = (filter: IFilter, index: number) => {
    switch (filter.type) {
      case "TextField": return <TextField onEnterKey={handleFilter} value={filterFields[filter.id] || ""} InputLabelProps={{ shrink: filterFields[filter.id] ? true : false }} key={index} onChange={onChange} id={filter.id} label={filter.label} variant="outlined" />
      case "CheckBox": return <FormControlLabel id={filter.id} control={<Checkbox id={filter.id} checked={filterFields[filter.id] || false} onChange={onChecked} />} label={filter.label} />
      case "Date": return <TextField onEnterKey={handleFilter} value={filterFields[filter.id] ? moment.unix(filterFields[filter.id]).format('YYYY-MM-DD') : ""} InputLabelProps={{ shrink: true }} key={index} onChange={onChangeDate} id={filter.id} label={filter.label} type="date" variant="outlined" />
      case "Range": return <div key={index} style={{ display: 'flex' }}>
        <div>{filter.label}</div>
        <TextField onEnterKey={handleFilter} id={filter.id} label={filter.labelFrom} type="search" variant="outlined" />
        <TextField onEnterKey={handleFilter} id={filter.id} label={filter.labelTo} type="search" variant="outlined" />
      </div>
      case "Select": return <FormControl key={filter.id} variant="outlined" className={classes.formControl}>
        <InputLabel id={filter.id}>{filter.label}</InputLabel>
        <Select {...filter} multiple={filter.multipleSelect} onChange={onSelect} value={filterFields[filter.id] || ""} />
      </FormControl>;
      case "TagFilter": return <TagFilter key={filter.id} name="tags" value={filterFields[filter.id] || ""} {...filter} onChange={onSelect} />
      case "RoleFilter": return <RoleFilter key={filter.id} name="roles" value={filterFields[filter.id] || ""} {...filter} onChange={onSelect} />
      case "CategorizationFilter": return <CategorizationFilter key={filter.id} name="roles" value={filterFields[filter.id] || ""} {...filter} onChange={onSelect} />
      case "MultipleSelect": return <AutoComplete
        multiple
        filterSelectedOptions
        key={filter.id}
        onChange={(event, newValue) => onSelect(filter.id, newValue.map((entry: any) => filter.idField ? entry[filter.idField] : entry.id).join(","))}
        options={filter.options as any}
        getOptionLabel={(option: any) => filter.labelField ? option[filter.labelField] : option.label}
        renderInput={(params) => <TextField {...params} label={filter.label} margin="normal" variant="outlined" />}
        value={getMultipleOption(filter)}
      //getOptionSelected={(option, value) => console.log(option, value)}
      />;
      default: return null;
    }
  }
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Typography variant="body1" component="div">Filters</Typography>
      <div className={classes.filterOptions}>
        {data.map(getFilterInput)}
      </div>
      <div>
        <Button onClick={handleFilter} variant="contained" color="primary">
          Filter
        </Button>
        <Button onClick={resetFilter} variant="contained" color="primary">
          Reset
        </Button>
      </div>
    </form>
  );
}
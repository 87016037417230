import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { toast } from "react-toastify";
import Autocomplete from '../../../../components/ui/AutoComplete'
import {
  Paper,
  Grid,
  MenuItem,
  Button,
  Divider,
  CircularProgress
} from '@material-ui/core';
import MaterialSwitch from '../../../../components/ui/MaterialSwitch';
import {
  getPermissionGroups,
  createPermissionRole,
  getPermissionRole,
  putPermissionRole,
  getPermissionUser,
  getPermissionRoles,
  putPermissionUser,
  createPermissionUser
} from '../../../../business/actions/permissions'
import {
  createPayout
} from '../../../../business/actions/payouts'
import { useHistory } from 'react-router-dom';
import { validateFields } from '../../../../utils'
import PageHeading from '../../../../components/ui/PageHeading'
import { IPermissionGroup, IPermissionUser, IRole } from '../../../../core';
export default function Create(props) {
  const user_email = props.match.params?.user_email || null
  const [busy, setBusy] = useState(false)
  const [permissionRoles, setPermissionRoles] = useState<Array<IRole>>([])
  const [roleData, setRoleData] = useState<IPermissionUser>({ email: user_email, role: { name: "", cognito_groups: [] } })

  const history = useHistory()

  useEffect(() => {
    getPermissionRoles({}).then(json => {
      let { TotalItems, PaginatedItems } = json;
      setPermissionRoles(PaginatedItems)
    })
    if (user_email) getPermissionUser(user_email).then(json => {
      setRoleData(json)
    });
  }, [])
  const onSubmit = async values => {
    setBusy(true)
    if (user_email) {
      putPermissionUser(user_email, values)
        .then(response => { toast('Role assigned.'); setTimeout(history.goBack, 1000) })
        .catch(error => toast(error.response.data?.message || "Unexpected error"))
        .finally(() => { setBusy(false) })
    } else {
      createPermissionUser(values)
        .then(response => { history.goBack(); toast('Role assigned'); })
        .catch(error => toast(error.response.data?.message || "Role is not created. Unexpected error"))
        .finally(() => { setBusy(false) })
    }
  };
  const validate = values => validateFields(values, ["email", "role_id"])
  return (
    <div className={"form-wrapper"}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          email: roleData.email,
          role_id: roleData.role.id
        }}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper>
              <PageHeading title={`${user_email ? 'Edit' : 'Create'} User Permissions`} />
              <Divider />
              <div style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field name="email" fullWidth required component={TextField} type="text" label="User email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="role_id"
                      component={Select}
                      required
                      label="Select a role *"
                      formControlProps={{ fullWidth: true }}
                    >{
                        permissionRoles.map(permissionGroup => <MenuItem value={permissionGroup.id}>{permissionGroup.name}</MenuItem>)
                      }
                    </Field>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => form.reset()}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </form>
        )
        }
      />
      {busy ? <div className='busy-progress-circular'><CircularProgress /></div> : null}
    </div >
  );
}



const CheckboxGroup = ({ fields, options }) => {
  const toggle = (event, option) => {
    if (event.target.checked) fields.push(option);
    else fields.remove(option);
  };
  return (
    <div style={{ color: "blue" }}>
      {options.map(option => (
        <div key={option}>
          <MaterialSwitch
            label={option}
            input={{
              onChange: event => toggle(event, option),
              value: option,
              defaultChecked: fields.value.findIndex(field => field === option) >= 0
            }}
          />
        </div>
      ))}
    </div>
  );
};
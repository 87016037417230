import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../../../business/authProvider';
import PageHeading from '../../../../components/ui/PageHeading';
import { createSettingsEmail, getSettingsEmail } from '../../../../business/actions/settings';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { validateFields } from '../../../../utils';
import { toast } from 'react-toastify';

const columns = [
  { field: 'text', headerName: 'Email' },
];


export default function DataTable() {
  const auth = useAuth();
  const [busy, setBusy] = useState(false)
  const [data, setData] = useState([])

  const authRoles= auth.roles;
  useEffect(() => {
    setBusy(true)
    getSettingsEmail().then(json => {
      let { support_email_addresses } = json;
      setData(support_email_addresses)
    }).finally(e => setBusy(false))
  }, []);

  const validate = values => validateFields(values, ["support_email_addresses"])
  const onSubmit = (values) => {
    let { support_email_addresses } = values;
    support_email_addresses = support_email_addresses.split(",").map(email => email.trim())
    createSettingsEmail({ support_email_addresses })
      .then(response => {
        toast('Email updated.');
        setData(response.support_email_addresses);
      })
      .catch(error => {
        toast(error.response.data?.message || "Email is not updated. Unexpected error")
      })

  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Email Setting'}>
        </PageHeading>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <Form
          onSubmit={onSubmit}
          initialValues={{ support_email_addresses: data.join(","), }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field disabled={!(auth.permission || authRoles['patch_settings'])} name="support_email_addresses" fullWidth required component={TextField} type="email" label="Email" />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button type="button" variant="contained" onClick={() => form.reset()} disabled={submitting || pristine}>
                      Reset
                    </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        />
      </Paper>
    </div>
  );
}
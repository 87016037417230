import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Button,
  Grid,
  Modal,
  Switch,
  makeStyles,
  IconButton,
  Divider,
} from '@material-ui/core';
import { convertDateToItalyTime } from '../../../utils'
import DataGrid from '../../../components/ui/Table'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom';
import { getPayeeWiseData, putPayee, archivePayee, getPayee, getPayeesFutureTransactions, getPayeesExecutedTransactions } from '../../../business/actions/payees'
import ViewField from '../../../components/ui/ViewField'
import { useAuth } from '../../../business/authProvider';
import PageHeading from '../../../components/ui/PageHeading';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { IColumn, IPayee } from "../../../core"
import { ADVCASH, PAYPAL, TRANSFERWISE } from '../../../utils/vars';
import RichButton from '../../../components/RichButton';
import { AddToPhotos, Edit, PlusOne, PostAdd } from '@material-ui/icons';
import ToolTip from '../../../components/ToolTip';
const status_column: Array<IColumn> = [
  { field: 'timestamp', headerName: 'Date and Time', style: { minWidth: 180 }, formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'message', headerName: 'Status' },
];

const payout_info_history: Array<IColumn> = [
  { field: 'payment_info', headerName: 'Payout Email/Transferwise Recepient ID/Revolut Counterparty ID' },
  { field: 'admin', headerName: 'Admin' },
  { field: 'timestamp', headerName: 'Date and Time', style: { minWidth: 180 }, formatting: timestamp => convertDateToItalyTime(timestamp) },
];

const et_columns: Array<IColumn> = [
  { field: 'transaction_id', headerName: 'Transaction Id', renderValue: value => <Link to={`/transactions/${value}/view`} style={{ display: 'flex', cursor: "pointer", padding: '8px 0px' }}>{value}</Link> },
  { field: 'payout_info', headerName: "Payout Email/Transferwise Recepient ID/Revolut Counterparty ID" },
  { field: 'payout_amount', headerName: "Payout Amount" }
];

export default function View(props) {
  const history = useHistory();
  let auth = useAuth();
  const confirm = useConfirm();
  const authRoles = auth.roles;
  /**
   * Payee id from the route
   */
  const { payee_id } = props.match.params;
  /**
   * Payee details
   * we use a default state here
   */
  const [payee, setPayee] = useState<IPayee>({
    "payout_email": "",
    "discord": "",
    "payout_method": "",
    "email": "",
    "payee_id": "",
    "user_id": "",
    "last_name": "",
    "pause_payee": false,
    "first_name": "",
    "username": "",
    "archival_status": null,
    "transferwise_id": "",
    "is_italian": "0",
    "tags": [],
    "tag_text": [],
    "status_messages": [],
    "payout_info_history": [],
    "executed_transactions_summary": { "payout_number": 0, payout_ids: [], "payout_amount": 0, currency: "EUR", number_failed: 0 },
    errors: [],
    "future_transactions_summary": { "payout_number": 0, payout_ids: [], "payout_amount": 0, currency: "EUR" }
  })
  /**
   * 
   */
  const [wiseData, setPayeeWiseData] = useState(null)
  const [loadingET, setETLoading] = useState(false)
  /**
   * Display all Executed transactions in a modal window
   */
  const [openModal, showTransactionsModal] = useState(false)
  const [payeeExecutedTransactions, setPayeeExecutedTransactions] = useState([])
  /**
   * Style object
   */
  const classes = useStyles();
  /**
   * On the component mount we should fetch payee details
   * We have 2 different route, but the details route is what needed here
   */
  useEffect(() => {
    getPayee(payee_id).then(data => {
      data.archived_status = data.archived ? "Payee is archived" : "Payee is not archived"
      switch (data.payout_method) {
        case PAYPAL:
          data.payout_fee = `${parseFloat(data.payout_fee) * 100}%`; break;
        case ADVCASH:
          data.payout_fee = `${parseFloat(data.advcash_payout_fee) * 100}%`; break;
        case TRANSFERWISE:
          data.payout_fee = data.transferwise_fee_by_payee ? "By Payee" : "By Blazing Boost"; break;
        default: data.payout_fee = "";
      }
      //data.status_messages = data.status_messages?.sort((a, b) => a.timestamp - b.timestamp)
      setPayee(data)
      if (data.transferwise_id) getPayeeWiseData(payee_id).then(json => {
        json.bank_code = json.bic || json.swift_code;
        setPayeeWiseData(json)
      })
    })
  }, [payee_id]);
  /**
   * 
   * @param {*} event 
   * @param {*} value 
   */
  const onChangePausePayee = (event, value) => {
    putPayee(payee_id, { pause_payee: value })
      .then(response => {
        setPayee({ ...payee, pause_payee: value });
        toast("Payee status updated.")
      })
      .catch(error => toast(error.response.data?.message || 'Payee status is not updated. Unexpected error'))
  }
  /**
   * 
   * @param event 
   * @param value 
   */
  const onChangeItalianPayee = (event, value) => {
    putPayee(payee_id, { is_italian: value })
      .then(response => {
        setPayee({ ...payee, is_italian: value });
        toast("Payee updated.")
      })
      .catch(error => toast(error.response.data?.message || 'Payee is not updated. Unexpected error'))
  }
  /**
   * 
   * @returns 
   * Show a modal and list all the executed transaction from a payee
   */
  const openTransactionModal = () => {
    setETLoading(true)
    showTransactionsModal(true)
    getPayeesExecutedTransactions(payee_id).then(json => {
      setPayeeExecutedTransactions(json.map(t => {
        t.payout_info = t.payout_email || t.transferwise_id;
        return t
      }))
    });
  }
  /**
   * 
   * @returns 
   * Close the modal
   */
  const closeTransactionModal = () => showTransactionsModal(false);
  /**
   * 
   */
  const handleArchivePayee = () => {
    toast('Payee archived.');
    setTimeout(history.goBack, 1000)
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Payee Details'}>
                  {payee.archived === false ?
                    <div>
                      {(auth.permission || authRoles['post_payout']) ?
                        <ToolTip tooltip={"Add Payout"}>
                          <IconButton component={Link} to={`/payouts/create?payee_id=${payee_id}`}>
                            <PostAdd fontSize={"small"} style={{ color: "inherit" }} />
                          </IconButton>
                        </ToolTip>
                        : null
                      }
                      {(auth.permission || authRoles['patch_payee_payee_id']) ?
                        <ToolTip tooltip={"Edit Payee"}>
                          <IconButton component={Link} to={`/payees/${payee_id}/edit`}>
                            <Edit fontSize={"small"} style={{ color: "inherit" }} />
                          </IconButton>
                        </ToolTip>
                        : null
                      }

                      {(auth.permission || authRoles['delete_payee_payee_id']) ?
                        <RichButton type='IconButton' icon='Archive' shouldConfirm={true}
                          confirmText={`Are you sure you want to archive payee ${payee_id}?`}
                          onComplete={handleArchivePayee} action={archivePayee}
                          data={payee_id} iconColor={"inherit"} toolTip="Archive Payee"
                        />
                        :
                        null
                      }

                    </div>
                    : null}
                </PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <ViewField type="chips" record={payee} source="tags" labelField={"text"} label="Tags" />
                  <ViewField record={payee} source="payee_id" label="Payee ID" />
                  <ViewField record={payee} source="username" label="Username" is_italian={payee.is_italian} />
                  <ViewField record={payee} source="first_name" label="First Name" />
                  <ViewField record={payee} source="last_name" label="Last Name" />
                  <ViewField record={payee} source="email" label="E-mail" />
                  <ViewField record={payee} source="discord" label="Discord" />
                  <ViewField record={payee} source="archived_status" label="Payee Archived Status" />
                  <ViewField record={payee} source="created_by" label="Payee Created By" />
                  {/*<ViewField record={payee} source="pause_payee" label="Payee Pause Status" />*/}
                  <ViewField label="Payee Pause Status" tooltip='This is the Payee status. When the status is turned on it means that the Payee is Paused and payments can not be sent to this payee until his status is changed back = unpaused.'>
                    <div>
                      <Switch disabled={!((auth.permission || authRoles['patch_payee_payee_id']))} onChange={onChangePausePayee} checked={payee['pause_payee']} color="primary" />
                      <span>
                        {payee['pause_payee'] ? 'Payee is paused, click to unpause.' : 'Payee is not paused, click to pause.'}
                      </span>
                    </div>
                  </ViewField>
                  <ViewField record={payee} source="is_italian" label="Italian Payee" ></ViewField>
                  {/*<ViewField label="Italian Payee" >
                    <div>
                      <Switch disabled={!auth.permission} onChange={onChangeItalianPayee} checked={payee['is_italian']} color="primary" />
                      <span>
                        {payee['pause_payee'] ? 'Payee is paused, click to unpause.' : 'Payee is not paused, click to pause.'}
                  </span>
                    </div>
                  </ViewField>*/}
                  <ViewField record={payee} source="payee_note" label="Payee Note" />
                  <ViewField record={payee} source="payout_method" label="Payout Method" />
                  <ViewField record={payee} source="payout_fee" label="Payout Fee" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Billing Details'}>
                  {payee.archived === false ?
                    <div>
                      {(auth.permission || authRoles['patch_payee_payee_id']) ?
                        <ToolTip tooltip={"Edit Billing"}>
                          <IconButton component={Link} to={`/payees/${payee_id}/billing/edit`}>
                            <Edit fontSize={"small"} style={{ color: "inherit" }} />
                          </IconButton>
                        </ToolTip>
                        : null
                      }
                    </div>
                    : null}
                </PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <ViewField record={payee} source="real_first_name" label="First Name" />
                  <ViewField record={payee} source="real_last_name" label="Last Name" />
                  <ViewField record={payee} source="address" label="Address" />
                  <ViewField record={payee} source="region" label="Region" />
                  <ViewField record={payee} source="city" label="City" />
                  <ViewField record={payee} source="country" label="Country" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Payout Details'}></PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <ViewField record={payee} source="payout_email" label="Payout Email" />
                  <ViewField record={payee} source="transferwise_id" label="Transferwise Recipient ID" />
                  <ViewField record={payee} source="advcash_payout_email" label="AdvCash Payout Email" />
                  <ViewField record={payee} source="revolut_id" label="Revolut Counterparty ID" />
                </div>
              </Paper>
            </Grid>
            {wiseData ?
              <Grid item xs={12}>
                <Paper>
                  <PageHeading title={'Recepient Details'}></PageHeading>
                  <Divider />
                  <div style={{ padding: 16 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}><DetailField record={wiseData} source="account_holder_name" label="Account Holder Name" /></Grid>
                      <Grid item xs={8}><DetailField record={wiseData} source="bank_code" label="Bank Code (BIC/SWIFT)" /></Grid>
                      <Grid item xs={4}><DetailField record={wiseData} source="currency" label="Currency" /></Grid>
                      <Grid item xs={8}><DetailField record={wiseData} source="iban" label="IBAN" /></Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              : null
            }
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Payout Details History'}></PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <DataGrid
                    canSort={false}
                    pagination={false}
                    extraColumns={false}
                    canSelect={false}
                    rows={payee.payout_info_history}
                    columns={payout_info_history}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Transactions'}></PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <div style={{ display: 'flex', padding: '8px 0px', alignItems: "center" }}>
                    <div style={{ width: 210 }}><span style={{ fontWeight: '700' }}>Future Transactions</span></div>
                    <div style={{ display: 'flex' }}>
                      {
                        payee?.future_transactions_summary ? (
                          payee['future_transactions_summary'].payout_number ?
                            <>
                              <Link to={`/payees/${payee_id}/future-transactions`} style={{ color: "red", cursor: 'pointer', fontSize: 16 }}>
                                {payee['future_transactions_summary'].payout_number}
                              </Link>
                              &nbsp;
                              <div style={{ fontSize: 16 }}>{`(${payee['future_transactions_summary'].payout_amount})`}</div>
                            </>
                            : <div>No future payouts</div>
                        )
                          :
                          null
                      }
                    </div>
                  </div>
                  <div style={{ display: 'flex', padding: '8px 0px' }}>
                    <div style={{ width: 210 }}><span style={{ fontWeight: '700' }}>Executed Transactions</span></div>
                    <div style={{ display: 'flex' }}>
                      <div onClick={openTransactionModal} style={{ cursor: 'pointer', paddingRight: 4, textDecoration: 'underline' }}>{payee['executed_transactions_summary'].payout_number || 0}</div>
                      {payee['executed_transactions_summary'].number_failed > 0 ? `(${payee['executed_transactions_summary'].number_failed} failed)` : null}
                      {/*<div> {`(${payee['executed_transactions'].currency} ${payee['executed_transactions'].cost})`}</div>*/}
                    </div>
                  </div>
                </div>
              </Paper>

            </Grid>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'History'}></PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <DataGrid canSort={false} pagination={false} extraColumns={false} actions={false} canSelect={false} rows={payee.status_messages} columns={status_column} pageSize={payee.status_messages.length} />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        className={classes.modal}
        onClose={closeTransactionModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" component="div" className={classes.modalTitle}>
              Total Transations</Typography>
            <IconButton onClick={closeTransactionModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className={classes.list}>
            <DataGrid
              canSort={false}
              pagination={false}
              extraColumns={false}
              canSelect={false}
              rows={payeeExecutedTransactions}
              columns={et_columns}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

interface IDetailField {
  record?: object,
  source?: string,
  label?: string,
}

const DetailField = ({ record, source, label }: IDetailField) => <div style={{ display: 'flex', padding: '8px 0px', flexDirection: "column" }}>
  <div style={{ width: 210, marginRight: 8 }}>
    <span style={{ fontWeight: '700' }}>{label}</span>
  </div>
  <div>{record ? record[source] : ''}</div>
</div>

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: "#D8DBE0",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    padding: theme.spacing(1)
  },
  modalTitle: {
    fontSize: 16,
    color: "rgba(44,56,74,.95)"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.3rem",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 21,.5)",
    borderStyle: "solid"
  },
  list: {
    minWidth: 500,
    maxHeight: 400,
    minHeight: 400,
    overflowY: 'auto',
    padding: theme.spacing(1)
  }
}));
import React from 'react';
import Checkbox from '../../theme/components/ui/Checkbox';


export default (props) => {
  const { onChange } = props;
  const _onChange = event => {
    if (typeof onChange === "function") {
      onChange(props.id, event.target.checked)
    }
  }
  return (
    <Checkbox
      {...props}
      onChange={_onChange}
      color="primary"
    />
  )
}
import List from './List'
import View from './View'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

/*
export default () => <Switch>
  <Route path={'/transactions/list'} component={List} />
  <Route path={'/transactions/:transaction_id/view'} component={View} />
  <Route path={'*'}>
    <Redirect to="/transactions/list" />
  </Route>
</Switch>
*/

const TransactionRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_transactions']) ? <Route path={`${url}/list`} component={List} /> : null}
    {(auth.permission || authRoles['get_transaction_transaction_id']) ? <Route path={`${url}/:transaction_id/view`} component={View} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>
  )
}

export default TransactionRoutes
import { Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
  arrow:{
    color: theme.palette.common.black,
  }
}))(Tooltip);

export default ({ tooltip, children }: { tooltip: string, children: any }) => <LightTooltip arrow title={tooltip}><span>
  {
    children
    ||
    <div style={{
      marginLeft: 4,
      marginRight: 4,
      /*padding: "2px 0px",*/
      textAlign: "center",
      fontWeight: '700',
      borderRadius: 100,
      backgroundColor: "#3f51b5",
      color: "#FFFFFF",
      cursor: "pointer",
      display: "inline-block",
      width: 25,
      height: 25,
      fontSize: 14,
      lineHeight: "24px"
    }} >?</div>
  }
</span>
</LightTooltip>
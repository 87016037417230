import { get, post, del, patch, responseResult } from './api'
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const createPayout = (data) => post(`payout`, data)
/**
 * 
 * @param {*} payout_id 
 * @param {*} data 
 * @returns 
 */
export const updatePayout = (payout_id, data) => patch(`payout/${payout_id}`, data)
/**
 * 
 * @param {*} payout_id 
 * @returns 
 */
export const deletePayout = payout_id => del(`payout/${payout_id}`)
/**
 * 
 * @param {*} payout_ids 
 * @returns 
 */
export const deletePayouts = payout_ids => del(`payouts`, payout_ids)
/**
 * 
 * @param {*} payout_id 
 * @returns 
 */
export const getPayout = (payout_id) => get(`payout/${payout_id}`).then(responseResult)

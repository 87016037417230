import { TextField } from "@material-ui/core";
import AutoComplete from '@material-ui/lab/Autocomplete'
import { useEffect, useState } from "react";
import { getExpensesCategories } from "../../business/actions/expense-categorisation";
import { IExpensesCategory } from "../../core";

export interface CategorizationFilterProp {
  id: string,
  label: string,
  name: string,
  value: string,
  style?: React.CSSProperties,
  multipleSelect: boolean,
  onChange: (id: string, value: string) => void
}

const CategorizationFilter = (prop: CategorizationFilterProp): JSX.Element => {
  const [expensesCategories, setExpensesCategories] = useState<Array<IExpensesCategory>>([]);
  const [selectedExpensesCategories, setSelectedExpensesCategories] = useState<IExpensesCategory | Array<IExpensesCategory>>(prop.multipleSelect ? [] : null);
  useEffect(() => {
    getExpensesCategories({}).then((expensesCategories: Array<IExpensesCategory>) => setExpensesCategories(expensesCategories));
  }, [])

  useEffect(() => {
    if (prop.multipleSelect) {
      let value = prop.value.split(",");
      setSelectedExpensesCategories(expensesCategories.filter(item => value.find(text => item.text === text)) || [])
    } else {
      let value = prop.value;
      setSelectedExpensesCategories(expensesCategories.find(item => item.text === value) || null)
    }
  }, [expensesCategories, prop.value])

  const onChange = (event: any, value: IExpensesCategory | Array<IExpensesCategory>) => {
    setSelectedExpensesCategories(value)
    if (prop.multipleSelect) {
      prop.onChange(prop.id, (value as Array<IExpensesCategory>).map((entry: IExpensesCategory) => entry.text).join(","))
      return;
    }
    prop.onChange(prop.id, (value as IExpensesCategory).text)
  }
  return (
    <AutoComplete
      filterSelectedOptions
      multiple={prop.multipleSelect}
      name={prop.name}
      key={prop.id}
      onChange={onChange}
      options={expensesCategories}
      getOptionLabel={(option: IExpensesCategory) => option.text}
      renderInput={(params) => <TextField {...params} label={prop.label} margin="normal" variant="outlined" />}
      value={selectedExpensesCategories}
      style={
        prop.style
      }
    />
  )
}
CategorizationFilter.defaultProps = {
  multipleSelect: false
}
export default CategorizationFilter;
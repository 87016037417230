import moment from "moment-timezone";

export const validateFields = (values, fields) => {
  const errors = {};
  fields.map(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    } else if (Array.isArray(values[field]) && values[field].length == 0) {
      errors[field] = 'Required';
    }
  })
  return errors;
}

export const isCETInDST = () => {
  var tz = 'Europe/Rome';
  return moment().tz(tz).isDST()
}

export const convertDateToItalyTime = timestamp => moment.unix(timestamp).utc().utcOffset(isCETInDST() ? 120 : 60).format('Do MMM YYYY HH:mm:ss')

export const cleanPayload = (payload, includeEmpty) => {
  let data = {};
  Object.keys(payload).map(key => {
    let entry = payload[key];
    if (entry === false) { data[key] = entry; }
    else if (entry) { data[key] = entry; }
    else if (!entry && includeEmpty) { data[key] = ""; }
  })
  return data;
}

export const trimFilterData = (payload) => {
  let data = {};
  Object.keys(payload).map(key => {
    let entry = payload[key];
    data[key] = entry.toString().trim();
  })
  return data;
}

export const transactionStatuses = [{ label: 'unpaid' }, { label: 'processing' }, { label: 'sent' }, { label: 'paid' }]
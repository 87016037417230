import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '../../../lib/Table'
import Filters from '../../../lib/Filters'
import ChipsField from '../../../components/ui/ChipsField'
import { Link } from 'react-router-dom';
import { getPayees } from '../../../business/actions/payees'
import { getTags } from '../../../business/actions/tags'
import { useAuth } from '../../../business/authProvider';
import { useList, ListProvider } from '../../../business/providers/listProvider'
import { LockOpenRounded, LockRounded } from '@material-ui/icons';
import { trimFilterData } from '../../../utils';
import { IColumn, IFilter, IPayeeListItem, ITag } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
import { ADVCASH, PAYPAL } from '../../../utils/vars';
import { getRequestLink } from '../../../business/actions/requests';
import { toast } from 'react-toastify';
import RichButton from '../../../components/RichButton';
const columns: Array<IColumn> = [
  { field: 'tags', headerName: 'Tags', renderValue: data => <ChipsField data={data} labelField={"text"} /> },
  { field: 'payee_id', headerName: 'Payee ID' },
  {
    field: 'username', headerName: 'Username', renderValue: (data, entry) => <>{data}{
      (entry.is_italian === "Italian Ritenuta" || entry.is_italian === "Italian Partita Iva") ? <>
        <img src="/assets/media/images/italian-flag.svg" style={{ width: 24, marginLeft: 4, marginRight: 4 }} />
        {
          entry.is_italian === "Italian Ritenuta" ? "-20" : "P.Iva"
        }</> : null
    }
    </>
  },
  { field: 'email', headerName: 'Payee Email' },
  { field: 'payout_method', headerName: 'Payout Method' },
  { field: 'payout_email', headerName: 'Payout Email' },
  { field: 'future_transaction_payout_amount', headerName: 'Future Transaction' },
  { field: 'transactions_payout_amount', headerName: 'Executed Transaction' },
  { field: 'pause_payee_icon', headerName: 'Paused Payee status' },
];
//offset=0&limit=10&payee_id=a&username=transferwise&email=a&payout_method=TransferWise
const _filters: Array<IFilter> = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'email', type: 'TextField', label: "Payee Email" },
  { id: 'payout_email', type: 'TextField', label: "Payout Email" },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
  { id: 'payout_method', type: 'Select', allowEmpty: true, label: "Payout Method", options: [{ label: 'Paypal' }, { label: 'TransferWise' }, { label: 'AdvCash' }, { label: 'Payoneer' }, { label: 'Revolut' }] },
  { id: 'is_italian', type: 'Select', allowEmpty: true, label: "Italian Payees", options: [{ label: 'Yes -20% Ritenuta', value: "italian_ritenuta" }, { label: 'Yes with Tax Number', value: "italian_partita_iva" }, { label: 'No', value: "not_italian" }] }
]

function DataTable() {
  const [payees, setPayees] = useState<Array<IPayeeListItem>>([])
  const [totalPayees, setTotalPayees] = useState<number>(0);
  const [busy, setBusy] = useState<boolean>(false)
  let auth = useAuth()
  let list = useList();
  const authRoles= auth.roles;
  let filterFields: Array<IFilter> = [..._filters];
  /**
   * 
   */
  useEffect(() => {
    setPayees([])
    loadPayee();
  }, [list.filters, list.pagination, list.sort])
  /**
   * 
   */
  const loadPayee = () => {
    setBusy(true)
    let appliedFilters = { ...list.filters }
    if (appliedFilters.is_italian && appliedFilters.is_italian !== "Any") {
      appliedFilters[appliedFilters.is_italian] = true;
      delete appliedFilters.is_italian;
    }
    getPayees({ ...trimFilterData(appliedFilters), ...list.pagination, ...list.sort }).then(json => {
      let { TotalItems, PaginatedItems } = json;
      setTotalPayees(TotalItems);
      let payees = PaginatedItems.map((entry: IPayeeListItem) => {
        let e_t = entry.executed_transactions_summary;
        let f_t = entry.future_transactions_summary;
        entry.pause_payee_icon = entry.pause_payee ? <LockRounded /> : <LockOpenRounded />;
        entry.transactions_payout_amount = e_t ? `${e_t.payout_amount.toFixed(2)} ${e_t.currency}` : ''
        entry.future_transaction_payout_amount = f_t ? `${f_t.payout_amount.toFixed(2)} ${f_t.currency}` : '';
        entry.payout_email = entry.payout_method === ADVCASH ? entry.advcash_payout_email : (entry.payout_method === PAYPAL ? entry.payout_email : "")
        //entry.tag_text = entry.tags.map(entry => entry.text).join(" ")
        return entry;
      })
      setPayees(payees)
    }).finally(e => setBusy(false))
  }
  const generateLink = (entry: IPayeeListItem) => getRequestLink(entry.payee_id).then(response => navigator.clipboard.writeText(response.link)).then(response => toast("Link copied!", { type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_CENTER }))
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'Payees: One-way Synchronization with all boosters we have on our admin panel. Editing here will not edit on the panel. Any edits on the panel will also show here.'}>
          {(auth.permission || authRoles['post_payee']) ?
            <Button component={Link} to={'/payees/create'} variant="contained" color="primary">Add New Payee</Button>
            : null
          }
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'payee_id'}
            canSelect={false}
            route={'payees'}
            canEdit={(auth.permission || authRoles['patch_payee_payee_id'])}
            canView={(auth.permission || authRoles['get_payee_payee_id']) }
            title={'Payees'}
            rows={payees.map(entry => ({
              ...entry
            }))}
            canSort={false}
            columns={columns}
            count={totalPayees}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}


export default ({ }): JSX.Element => <ListProvider><DataTable /></ListProvider>

import { get, post, patch, responseResult, del, normaliseTagText } from './api'
import { cleanPayload } from '../../utils'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getPayees = (filters) => get('payees', filters).then(responseResult)
/**
 * 
 * @param {*} payee_id 
 * @returns 
 */
export const getPayee = (payee_id) => get(`payee/${payee_id}`).then(responseResult)
/**
 * 
 * @param {*} payee_id 
 * @returns 
 */
export const getPayeeDetails = (payee_id) => get(`payee/${payee_id}/details`).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 * Create a new payee in the system
 */
export const createPayee = (data) => post(`payee`, cleanPayload(data))
/**
 * 
 * @param {*} payee_id 
 * @param {*} data 
 * @returns 
 * Update a payee in the system
 */
export const putPayee = (payee_id, data) => patch(`payee/${payee_id}`, cleanPayload(data, true))
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getPayeesFutureTransactions = (payee_id) => get(`payee/${payee_id}/future_transactions`).then(responseResult)
/**
 * 
 */
export const getPayeesExecutedTransactions = (payee_id) => get(`payee/${payee_id}/transactions`).then(responseResult)
/**
 * 
 * @param {*} payees 
 * @returns 
 */
export const payPayee = (data) => post('pay', data).then(responseResult)
/**
 * 
 * @param {*} payee_id 
 * @returns 
 */
export const deleteAllPayoutOfPayee = (payee_id) => del(`payee/${payee_id}/payouts`).then(responseResult);
/**
 * 
 * @param {*} payee_id 
 * @returns 
 */
export const archivePayee = (payee_id) => del(`payee/${payee_id}`).then(responseResult)
/**
 * 
 * @param {*} payee_id 
 * @returns 
 */
export const unarchivePayee = (payee_id) => post(`payee/${payee_id}/unarchive`, null, {
  'Content-Type': 'application/json'
}).then(responseResult)
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getArchivedPayees = (filters) => get('payees/archived', filters).then(responseResult)

export const getPayeeWiseData = payee_id => get(`payee/${payee_id}/wise_data`).then(responseResult)

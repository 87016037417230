import List from './List'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

const FutureTransactionRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_future_transactions']) ? <Route path={`${url}/list`} component={List} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>
  )
}

export default FutureTransactionRoutes
import React, { useState } from 'react';
import { Paper, Tabs, Tab, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ByPassKey from './ByPassKey'
import Billing from './Billing'
import { useAuth } from '../../../business/authProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: '100%'
  }
}));

export default () => {
  const auth = useAuth();
  const authRoles = auth.roles;
  const [selectedTab, setTab] = useState(authRoles['post_generate_token'] ? "generate_token" : (authRoles['get_company_billing_details'] ? "company_billing_details" : ""));

  const handleChange = (event, whichTab) => {
    setTab(whichTab)
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <div style={{ padding: 16 }}>
          <Tabs value={selectedTab} onChange={handleChange}>
            {authRoles['post_generate_token'] ? <Tab value="generate_token" label="Generate Key" /> : null}
            {authRoles["get_company_billing_details"] ? <Tab value="company_billing_details" label="Company's Billing Details" /> : null}
            {/*authRoles["post_generate_token"] */}
            {/*authRoles["post_generate_token"] */}
          </Tabs>
          <div style={{ paddingTop: 16 }}>
            {selectedTab === "generate_token" ? <ByPassKey /> : null}
            {selectedTab === "company_billing_details" ? <Billing /> : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}
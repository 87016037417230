import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '../../../../lib/Table'
import ChipsField from '../../../../components/ui/ChipsField'
import { Link } from 'react-router-dom';
import { deletePermissionRole, getPermissionRoles } from '../../../../business/actions/permissions'
import { useAuth } from '../../../../business/authProvider';
import { useList, ListProvider } from '../../../../business/providers/listProvider'
import { IColumn, IRole } from '../../../../core';
import { IRoleListItem } from "../../../../core"
import PageHeading from '../../../../components/ui/PageHeading';
import FewChipsField from '../../../../components/ui/FewChipsField';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
const columns: Array<IColumn> = [
  { field: 'name', headerName: 'Role Name' },
  { field: 'groups', headerName: 'Group', renderValue: data => <FewChipsField max={4} data={data} labelField={"text"} /> }
];


function DataTable() {
  const [roles, setRoles] = useState<Array<IRoleListItem>>([])
  const [totalroles, setTotalRoles] = useState<number>(0);
  const [busy, setBusy] = useState<boolean>(false)
  let auth = useAuth()
  let list = useList();
  const confirm = useConfirm();
  const authRoles= auth.roles;
  /**
   * 
   */
  useEffect(() => {
    loadRoles();
  }, [list.filters, list.pagination, list.sort])
  /**
   * 
   */
  const loadRoles = () => {
    setBusy(true)
    setRoles([])
    getPermissionRoles().then(json => {
      let { TotalItems, PaginatedItems } = json;
      setTotalRoles(TotalItems);
      setRoles(
        PaginatedItems.map((entry: IRole) => {
          let listItem: IRoleListItem = { ...entry, groups: entry.cognito_groups.map(cognito_group => ({ text: cognito_group })) };
          return listItem;
        })
      )
    }).finally(e => setBusy(false))
  }
  const onDelete = entry => deletePermissionRole(entry.id)
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'Roles'}>
          {(auth.permission || authRoles['post_permissions_role']) ? <Button component={Link} to={'/permissions/roles/create'} variant="contained" color="primary">Add New Role</Button> : null}
        </PageHeading>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'id'}
            canSelect={false}
            route={'permissions/roles'}
            canEdit={(auth.permission || authRoles['patch_permissions_role_role_id'])}
            canDelete={(auth.permission || authRoles['delete_permissions_role_role_id'])}
            onDelete={onDelete}
            onCompleteDelete={loadRoles}
            deleteField={"name"}
            canView={false}
            title={'Roles'}
            rows={roles.map(entry => ({...entry}))}
            canSort={false}
            columns={columns}
            count={totalroles}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}


export default ({ }): JSX.Element => <ListProvider><DataTable /></ListProvider>
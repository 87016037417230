import ToolTip from '../ToolTip';
import ChipsField from './ChipsField';

interface IViewFIeld {
  record?: object,
  source?: string,
  label?: string,
  tooltip?: string,
  type?: string,
  labelField?: string,
  is_italian?: string,
  children?: JSX.Element[] | JSX.Element
}
const ViewField = ({ record, source, label, tooltip, type, labelField, children, is_italian }: IViewFIeld): JSX.Element => <div style={{ display: 'flex', padding: '8px 0px', alignItems: 'center' }}>
  {label ?
    <div style={{ width: 210, marginRight: 8, display: 'flex', columnGap: 4 }}>
      <span style={{ fontWeight: '700' }}>{label}</span>
      {tooltip ? <ToolTip tooltip={tooltip} /> : null}
    </div>
    :
    null
  }
  {
    record ? (type === "chips" ? <ChipsField data={record[source]} labelField={labelField} /> : record[source]) : ''
  }
  {
    (is_italian === "Italian Ritenuta" || is_italian === "Italian Partita Iva") ? <>
      <img src="/assets/media/images/italian-flag.svg" style={{ width: 24, marginLeft: 4, marginRight: 4 }} />
      {
        is_italian === "Italian Ritenuta" ? "-20" : "P.Iva"
      }</> : null
  }
  {children}
</div>
export default ViewField;
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { deletePayouts } from '../business/actions/payouts'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { IFutureTransaction, IPayee } from '../core/index'
import { useState } from 'react';
import ProgressModal from './ui/ProgressModal';
/**
 * 
 */
interface IDeletePayoutButton {
  label: string,
  disabled: boolean,
  futureTransactions: Array<IFutureTransaction>,
  onComplete: Function
}

export default ({
  label,
  disabled,
  futureTransactions,
  onComplete
}: IDeletePayoutButton): JSX.Element => {
  const confirm = useConfirm();
  /**
   * 
   */
  const [busy, setBusy] = useState<boolean>(false);
  /**
   * 
   * @returns 
   */
  const confirmDelete = () => {
    confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description: "Are you sure? All the payouts inside selected transactions will be deleted?" })
      .then(() => submitDeletion())
      .catch(() => { /* ... */ });
  }
  const submitDeletion = () => {
    setBusy(true);
    let payout_ids = futureTransactions.map(futureTransaction => futureTransaction.payout_ids);
    deletePayouts(payout_ids.flat())
      .then(response => {
        let { errors, status } = response;
        if (errors?.length) {
          toast.error(errors.map(error => (error.message || error)).join("\n"))
        }
        else {
          toast.success(status);
        }
        onComplete()
      })
      .catch(error => {
        toast(error.response.data?.message || "Unexpected error")
      })
      .finally(f => setBusy(false))
  }
  return (
    <>
      <Button disabled={disabled} onClick={confirmDelete} style={{ marginRight: 8 }} variant="contained" color="secondary">{label}</Button>
      <ProgressModal open={busy} note={"Please wait while deletion is in progress."} />
    </>
  )
}
import React, { useEffect, useRef } from 'react'
import { IRoute } from "../../core/interfaces/Menu";
import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import {
  Link,
  useLocation
} from "react-router-dom";

const useMenuStyle = makeStyles((theme) => ({
  menuItemSelected: {
    fontWeight: "bold"
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));


export default (props: IRoute) => {
  const { pathname } = useLocation();
  const classes = useMenuStyle();
  return (
    <ListItem classes={{ selected: classes.menuItemSelected }} button component={Link} to={props.to} selected={pathname.match(props.selected)?.length >= 1}>
      <ListItemText disableTypography={true} primary={props.label} style={{ paddingLeft: 8 * props.level }} />
    </ListItem>
  )
}
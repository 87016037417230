import List from './List'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

/*
export default () => <Switch>
  <Route path={'/requests/list'} component={List} />
  <Route path={'*'}>
    <Redirect to="/requests/list" />
  </Route>
</Switch>
*/

const RequestRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_payout_requests']) ? <Route path={`${url}/list`} component={List} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>
  )
}

export default RequestRoutes
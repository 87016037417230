import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import DataGrid from '../../../components/ui/Table'
import { getBatch } from '../../../business/actions/batches';
import ViewField from '../../../components/ui/ViewField'
import { convertDateToItalyTime } from '../../../utils'
import ChipsField from '../../../components/ui/ChipsField'
import { IColumn } from '../../../core';
const columns: Array<IColumn> = [
  { field: 'tags', headerName: 'Tags', renderValue: data => <ChipsField data={data} labelField={"text"} /> },
  { field: 'payee_id', headerName: 'Payee ID / Booster ID' },
  { field: 'username', headerName: 'Username' },
  { field: 'payout_number', headerName: 'Payouts (Order Items Paid)' },
  { field: 'payout_amount', headerName: 'Payout Amount (€)' },
  { field: 'payout_method', headerName: 'Payout Method' },
  { field: 'sent_by', headerName: 'Sent By' }
];
export default function DataTable({ match }) {
  const [busy, setBusy] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [batch, setBatchDetails] = useState(null)
  //const [transferwiseTransaction, setTransferwiseTransaction] = useState(null)
  const batch_id = match.params.batch_id;
  useEffect(() => {
    setBusy(true)
    getBatch(batch_id).then(response => {
      setBatchDetails({ ...response, sent_date: convertDateToItalyTime(response?.created_at || 0) })
      setTransactions(response.transactions.map(transaction => ({ ...transaction, payout_number: transaction.payout_ids?.length || 0 })))
      //setTransferwiseTransaction(response.transactions.find(trans => trans.payout_method === 'TransferWise'))
    }).finally(e => setBusy(false))
  }, [batch_id])

  const viewRoute = entry => `/transactions/${entry.transaction_id}/view`;
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <div style={{ padding: 16 }}>
          <Typography variant="body2" component="div">Batch Details</Typography>
        </div>
        <Divider />
        {busy ? <LinearProgress /> : <>
          <div style={{ padding: 16 }}>
            <ViewField label={'Blazing Boost Batch ID'} source={'batch_id'} record={batch} />
            <ViewField label={'Date and Time'} source={'sent_date'} record={batch} />
            <ViewField label={'Batch Status'} source={'batch_status'} record={batch} />
          </div>
          <div style={{ padding: 16 }}>
            <DataGrid canSort={false} canView viewRoute={viewRoute} title={'Batches'} rows={transactions} columns={columns} pageSize={transactions.length} checkboxSelection />
          </div>
        </>
        }
      </Paper>
    </div>
  );
}
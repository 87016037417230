import React, { useEffect, useState } from 'react';
import {
  Typography,
  Modal,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { convertDateToItalyTime } from '../../../utils'
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseIcon from '@material-ui/icons/Close'
import HistoryIcon from '@material-ui/icons/History';
import DataGrid from '../../../components/ui/Table'
import { IColumn } from '../../../core'
const columns: Array<IColumn> = [
  { field: 'admin_name', headerName: 'Admin Name' },
  { field: 'transaction_note', headerName: 'Note Text', style: { wordBreak: "break-all" } },
  { field: 'timestamp', headerName: 'Date and Time', formatting: timestamp => convertDateToItalyTime(timestamp), style: { width: 170 } }
];


interface INoteItem {
  admin_name: string,
  transaction_note: string,
  timestamp: string
}

interface INoteList {
  items: INoteItem[],
}

export default function View({ items, ...props }: INoteList): JSX.Element {
  const classes = useStyles();
  const [open, showModal] = useState(false)
  const [listItems, setListItems] = useState(items.slice(0, 5))
  /**
   * 
   */
  useEffect(() => setListItems(items.slice(0, 10)), [items])
  /**
   * 
   */
  const fetchData = () => {
    let currentIndex = listItems.length;
    setListItems(listItems.concat(items.slice(currentIndex, currentIndex + 5)));
  }
  /**
   * 
   * @returns 
   */
  const toggleModal = () => showModal(!open);

  return (
    <div>
      <IconButton onClick={toggleModal}><HistoryIcon fontSize="small" /></IconButton>
      <Modal
        open={open}
        className={classes.modal}
        onClose={toggleModal}
      >
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" component="div" className={classes.modalTitle}>Notes History</Typography>
            <IconButton onClick={toggleModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className={classes.list}>
            <InfiniteScroll
              dataLength={listItems.length} //This is important field to render the next data
              next={fetchData}
              hasMore={true}
              height={380}
            >
              <DataGrid
                canSort={false}
                pagination={false}
                extraColumns={false}
                actions={false}
                canSelect={false}
                rows={listItems}
                columns={columns}
                pageSize={listItems.length}
              />
            </InfiniteScroll>
          </div>
        </div>
      </Modal>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.3rem",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 21,.5)",
    borderStyle: "solid"
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: "#D8DBE0",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    padding: theme.spacing(1)
  },
  modalTitle: {
    fontSize: 16,
    color: "rgba(44,56,74,.95)"
  },
  list: {
    minWidth: 600,
    maxWidth: 1000,
    maxHeight: 400,
    minHeight: 400,
    padding: theme.spacing(1)
  }
}));

import React, { useEffect, useRef } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Divider, Drawer, List, ListItem, ListItemText, makeStyles, useTheme, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { Auth } from '@aws-amplify/auth';
import { Hub } from "@aws-amplify/core";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import Payees from './pages/payees';
import Payouts from './pages/payouts';
import FutureTransactions from './pages/future-transactions'
import Transactions from './pages/transactions'
import TransactionList from './pages/transactions/List'
import Batches from './pages/batches'
import Instructions from './pages/instructions';
import Tags from './pages/tags';
import ExpensesCategorization from './pages/expense_categorization'
import CostsInvoicePDF from './pages/costs_management/invoices_pdfs'
import CostsFullReports from './pages/costs_management/full_reports'
import CostsCommissions from './pages/costs_management/commissions'
import BlacklistedPayees from './pages/blacklisted-payees'
import Users from './pages/users';
import Archives from './pages/archive'
import Settings from './pages/settings';
import HistoryLogsList from './pages/history-logs';
import PayoutsToItaliansSettings from './pages/payouts-to-italians/settings';
import { useAuth } from '../business/authProvider';
import getMenuItem from '../components/menu/GetMenuItem';
import { IRoute } from '../core/interfaces/Menu';
import Requests from './pages/requests';
import RolesPage from './pages/permissions/roles'
import UsersPage from './pages/permissions/users'
import AccountPage from './pages/account'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //width: `calc(100% - ${drawerWidth}px)`,
    //marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth + 16,
  },
  menuItemSelected: {
    fontWeight: "bold"
  },
  backButtonWrapper: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    alignContent: 'center'
  },

}));

const useMenuStyle = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const AppTopBar = (props) => {
  const theme = useTheme();
  const auth = useAuth();
  const classes = useStyles()
  const [open, setOpen] = React.useState(localStorage.getItem('menuOpen') === "true");
  const basicRoutes: Array<IRoute> = prepareMenu(auth.roles);
  const routes: Array<IRoute> = basicRoutes;
  /**
   * 
   */
  useEffect(() => { props.onDrawerStateChange(open); localStorage.setItem('menuOpen', open) }, [open])
  /**
   * 
   */
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogout = async () => {
    try {
      await Auth.signOut();
      Hub.dispatch("UI Auth", {
        event: "AuthStateChange",
        message: "signedout",
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (<>
    <AppBar position="fixed"
      className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">BBPO</Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {routes.map(getMenuItem)}
        <ListItem button onClick={onLogout}>
          <ListItemText disableTypography={true} primary={'Logout'} style={{ paddingLeft: 8 }} />
        </ListItem>
      </List>
    </Drawer>
  </>
  )
}
const Layout = () => {
  const auth = useAuth();
  const classes = useStyles()
  const history = useHistory();
  const mainBlock = useRef(null);
  /**
   * 
   * @param {*} open 
   * @returns 
   */
  const onDrawerStateChange = open => mainBlock.current.style.marginLeft = open ? `${drawerWidth + 16}px` : "16px";
  return (<>
    <AppTopBar onDrawerStateChange={onDrawerStateChange} />
    <main ref={mainBlock} key={"main"} className={classes.content}>
      <div className={classes.drawerHeader} />
      <div className={classes.backButtonWrapper}>
        <Button onClick={() => history.goBack()} variant="contained" disableElevation color="primary" startIcon={<ChevronLeftIcon />}>Back</Button>
      </div>
      <Switch>
        <Route path={'/payees'} component={Payees} />
        <Route path={'/future-transactions'} component={FutureTransactions} />
        <Route path={'/transactions'} component={Transactions} />
        {auth.roles['get_transactions'] ?
          <Route path={'/failed-transactions/list'} render={props => <><TransactionList showFailed={true} {...props} /></>} />
          :
          null
        }
        <Route path={'/batches'} component={Batches} />
        <Route path={'/requests'} component={Requests} />
        <Route path={'/tags'} component={Tags} />
        <Route path={'/expensecategorizations'} component={ExpensesCategorization} />

        <Route path={'/costsmanagement/invoices'} component={CostsInvoicePDF} />
        <Route path={'/costsmanagement/full_reports'} component={CostsFullReports} />
        <Route path={'/costsmanagement/commissions'} component={CostsCommissions} />

        <Route path={'/blacklisted-payees'} component={BlacklistedPayees} />
        {auth.roles['get_transactions'] ?
          <Route path={'/payouts-to-italians/payments'} render={props => <><TransactionList showItaliansOnly={true} {...props} /></>} />
          :
          null
        }
        <Route path={'/payouts-to-italians/settings'} component={PayoutsToItaliansSettings} />
        <Route path={'/archive'} component={Archives} />
        <Route path={'/payouts'} component={Payouts} />
        {/*
          Missing Permission group
        */}
        <Route path={'/permissions/roles'} component={RolesPage} />
        <Route path={'/permissions/users'} component={UsersPage} />
        {/** */}
        <Route path={'/instructions'} component={Instructions} />
        <Route path={'/history-logs'} component={HistoryLogsList} />
        <Route path={'/users'} component={Users} />
        <Route path={'/settings'} component={Settings} />
        <Route path={'/account'} component={AccountPage} />
        <Route path="*">
          <Redirect to="/payees" />
        </Route>
      </Switch>
    </main>
  </>)
}
export default Layout




const prepareMenu = (authRoles) => {
  let basicRoutes: Array<IRoute> = []
  /**
   * Payee List
   */
  if (authRoles["get_payees"]) {
    basicRoutes.push({ label: 'Payees', to: "/payees", selected: /\/payees\//, level: 0 })
  }
  /**
   * Future transcation list
   */
  if (authRoles["get_future_transactions"]) {
    basicRoutes.push({ label: 'Future Transactions', to: "/future-transactions", selected: /\/future-transactions\//, level: 0 })
  }
  /**
   * Transactions list
   */
  if (authRoles["get_transactions"]) {
    basicRoutes.push({ label: 'Successful Transactions', to: "/transactions", selected: /\/transactions\//, level: 0 });
    basicRoutes.push({ label: 'Failed Transactions', to: "/failed-transactions/list", selected: /\/failed-transactions\//, level: 0 });
  }
  /**
   * Batches List
   */
  if (authRoles["get_batches"]) {
    basicRoutes.push({ label: 'Batches', to: "/batches", selected: /\/batches\//, level: 0 })
  }
  /**
   * Request List
   */
  if (authRoles["get_payout_requests"]) {
    basicRoutes.push({ label: 'Requests', to: "/requests", selected: /\/requests\//, level: 0 })
  }
  if (authRoles["get_invoices"] || authRoles["get_report"] || authRoles["get_commissions"]) {
    let costManagementRoutes: IRoute = { label: 'Costs Management', selected: /\/costsmanagement/g, level: 0 };
    costManagementRoutes.data = [];
    if (authRoles["get_invoices"]) {
      costManagementRoutes.data.push({ label: 'Invoices & PDF Storage', to: "/costsmanagement/invoices", selected: /\/costsmanagement\/invoices/, level: 1 })
    }
    if (authRoles["get_report"]) {
      costManagementRoutes.data.push({ label: 'Full Reports', to: "/costsmanagement/full_reports", selected: /\/costsmanagement\/full_reports/, level: 1 })
    }
    if (authRoles["get_commissions"]) {
      costManagementRoutes.data.push({ label: 'Commissions', to: "/costsmanagement/commissions", selected: /\/costsmanagement\/commissions/, level: 1 })
    }
    basicRoutes.push(costManagementRoutes)
  }
  /**
   * Tags list
   */
  if (authRoles["get_tags"]) {
    basicRoutes.push({ label: 'Tags', to: "/tags", selected: /\/tags\//, level: 0 })
  }
  if (authRoles["get_expense_categorizations"]) {
    basicRoutes.push({ label: 'Expense Categorizations', to: "/expensecategorizations", selected: /\/expensecategorizations\//, level: 0 })
  }

  //
  if (authRoles["get_blacklisted_payees"]) {
    basicRoutes.push({ label: 'Blacklisted payees', to: "/blacklisted-payees", selected: /\/blacklisted-payees\//, level: 0 })
  }
  if (authRoles["get_transactions"]) {
    basicRoutes.push(
      {
        label: 'Payouts to Italians', selected: /\/payouts-to-italians/g, level: 0, data: [
          { label: 'Payments', to: "/payouts-to-italians/payments?italian_ritenuta=true&italian_partita_iva=true", selected: /\/payouts-to-italians\/payments/, level: 1 },
          { label: 'Settings', to: "/payouts-to-italians/settings", selected: /\/payouts-to-italians\/settings/, level: 1 },
        ]
      }
    )
  }
  if (authRoles["get_payees_archived"] || authRoles["get_transactions"]) {
    let archivedRoutes:IRoute = { label: 'Archived', selected: /\/archive/g, level: 0};
    archivedRoutes.data = [];
    if (authRoles["get_payees_archived"]) {
      archivedRoutes.data.push({ label: 'Payees', to: "/archive/payees", selected: /\/archive\/payees/, level: 1 })
    }
    if (authRoles["get_transactions"]) {
      archivedRoutes.data.push({ label: 'Transactions', to: "/archive/transactions", selected: /\/archive\/transactions/, level: 1 })
      archivedRoutes.data.push({ label: 'Failed Transactions', to: "/archive/failed-transactions", selected: /\/archive\/failed-transactions/, level: 1 })
    }
    basicRoutes.push(archivedRoutes)
  }
  if (authRoles["get_permissions_roles"] || authRoles["get_permissions_users"]) {
    let permissionRoutes:IRoute = { label: 'Permissions', selected: /\/permissions/g, level: 0 }
    permissionRoutes.data = [];
    if (authRoles["get_permissions_roles"]) {
      permissionRoutes.data.push({ label: 'Roles', to: "/permissions/roles", selected: /\/permissions\/roles/, level: 1 })
    }
    if (authRoles["get_permissions_users"]) {
      permissionRoutes.data.push({ label: 'Users', to: "/permissions/users", selected: /\/permissions\/users/, level: 1 })
    }
    basicRoutes.push(permissionRoutes)
  }

  if (authRoles["post_generate_token"] || authRoles["get_company_billing_details"]) {
    basicRoutes.push({ label: 'Settings', to: "/settings", selected: /\/settings\//, level: 0 })
  }

  if (authRoles["get_manual_payouts"]) {
    basicRoutes.push({ label: 'History Logs', to: "/history-logs", selected: /\/history-logs\//, level: 0 })
  }
  basicRoutes.push({ label: 'My Account', to: "/account", selected: /\/account\/profile\//, level: 0 })
  basicRoutes.push({ label: 'Instructions', to: "/instructions", selected: /\/instructions\//, level: 0 })

  return basicRoutes
}
import List from './List'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

const ManualPayouts = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_manual_payouts']) ? <Route path={'/history-logs/list'} component={List} /> : null}
    <Route path={'*'}>
      <Redirect to="/history-logs/list" />
    </Route>
  </Switch>
  )
}

export default ManualPayouts
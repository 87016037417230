import React, { useEffect, useState } from 'react';
import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import DataGrid from '../../../components/ui/Table'
import { getExpensesCategories } from '../../../business/actions/expense-categorisation'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../business/authProvider';
import PageHeading from '../../../components/ui/PageHeading';
const columns = [
  { field: 'text', headerName: 'Expense Categorization' },
];


export default function DataTable() {
  const auth = useAuth();
  const paginationLimit = parseInt(localStorage.getItem("rows") || 5, 10);
  
  const [busy, setBusy] = useState(false)
  const [data, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({ offset: 0, limit: paginationLimit })

  const authRoles= auth.roles;
  useEffect(() => {
    setBusy(true)
    getExpensesCategories({}).then(json => {
      setTotalItems(json.length);
      setData(json.sort((a, b) => b.created_at - a.created_at))
    }).finally(e => setBusy(false))
  }, []);
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Expense Categorization'}>
          {(auth.permission || authRoles['post_expense_categorization']) ?
            <Button component={Link} to={'/expensecategorizations/create'} variant="contained" color="primary">
              Add New Expense Categorization
            </Button>
            : null
          }
        </PageHeading>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <DataGrid
            idField={'id'}
            route={'expensecategorizations'}
            canEdit={(auth.permission || authRoles['patch_expense_categorization_categorization_id'])}
            canDelete={false}
            canSort={false}
            extraColumns={false}
            rows={data?.slice(pagination.offset, pagination.offset + pagination.limit) || []}
            columns={columns}
            count={totalItems}
            pagination={pagination}
            onUpdatePagination={setPagination}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}
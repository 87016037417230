import { get, post, del, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getCostManagementInvoicesPDFS = (filters) => get('invoices', filters).then(responseResult)
export const postCostManagementInvoicesPDFS = (data) => post('invoice', data)
export const deleteCostManagementInvoicesPDFS = (invoice_id) => del(`invoice/${invoice_id}`)
export const postFileToPresignedURL = (url, files) => {
  return new Promise((resolve, reject) => {
    let file = files[0]
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.onload = () => {
      resolve()
    };
    xhr.onerror = (error) => {
      reject()
    };
    xhr.send(file);
  })
}
import React, { useEffect, useState } from 'react';

import { Button, Grid, Typography, Paper, TextField, CircularProgress, FormControl } from '@material-ui/core';
import {
  generateKeys, getCompanyBillingDetails, patchCompanyBillingDetails, submitKeys
} from '../../../business/actions/settings'
import PageHeading from '../../../components/ui/PageHeading'
import { toast } from 'react-toastify';
import { Field, Form } from 'react-final-form';
import { validateFields } from '../../../utils';
import { TCompanyBilling, TPayeeBilling } from '../../../core/types';
import CompanyBillingForm from '../../../components/forms/CompanyBilling';

export default function Create({
}) {

  const [data, setData] = useState<TCompanyBilling>()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    getCompanyBillingDetails().then(json => {
      const { company_name, company_address, vat_number } = json
      setData({company_name, company_address, vat_number})
    })
  }

  const onSubmit = (values) => {
    patchCompanyBillingDetails(values)
      .then(response => { toast('Billing Details updated.'); })
      .catch(error => toast(error.response.data?.message || "Unexpected error"))
    //return true;
  }

  const validate = values => validateFields(values, [
    "company_name",
    "company_address",
    "vat_number"
  ])
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompanyBillingForm mode={'edit'} formData={data} onSubmit={onSubmit} validate={validate}></CompanyBillingForm>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
import { toast } from 'react-toastify';
import { Button, CircularProgress, IconButton } from '@material-ui/core'
import React, { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import * as Icons from "@material-ui/icons"
import ProgressModal from './ui/ProgressModal';
import ToolTip from './ToolTip';

interface IRichActionButton {
  type: "IconButton" | "NormalButton",
  toolTip: string | null,
  icon?: string,
  label?: string,
  data: any,
  shouldConfirm?: boolean,
  disabled?: boolean,
  action: (data: any | null | undefined) => Promise<any> | void
  confirmText?: string,
  onComplete?: () => void,
  waitBeforeTriggerComplete?: number,
  iconColor?: string,
  component?: JSX.Element
}

export default ({ toolTip, data, type, icon, label, disabled, shouldConfirm, confirmText, onComplete, waitBeforeTriggerComplete, action, iconColor, component }: IRichActionButton): JSX.Element => {
  const confirm = useConfirm();
  /**
   * 
   */
  const [busy, setBusy] = useState<boolean>(false);
  /**
   * 
   */
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (shouldConfirm) {
      confirmBeforeAction()
    } else {
      triggerAction();
    }
  }
  /**
   * 
   */
  const triggerAction = () => {
    setBusy(true);
    action(data)
      .then((response: any) => { if (onComplete) setTimeout(onComplete, waitBeforeTriggerComplete || 0) })
      .catch((error: any) => toast(error.response?.data?.message || "Unexpected error", { type: "error" }))
      .finally(() => { setBusy(false); })
  }
  /**
   * 
   */
  const confirmBeforeAction = () => {
    confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description: confirmText })
      .then(triggerAction)
      .catch(() => { /* ... */ });
  }
  /**
   * 
   */
  /*
  const renderButton = (): JSX.Element => type === "IconButton" ? <RichIconButton busy={busy} onClick={onClick} icon={icon || ""} fontSize="small" />
    : <NormalButton fontSize='small' busy={busy} onClick={onClick} icon={icon || ""} label={label || ""} />
  */

  const renderButton = (): JSX.Element => {
    let Icon = Icons[icon]
    if (type === "IconButton") {
      return (<IconButton disabled={disabled} onClick={onClick}>
        {busy ? <CircularProgress size={15} /> : <Icon fontSize={"small"} style={{ color: iconColor }} />}
      </IconButton>
      )
    }
    return <Button
      disabled={busy}
      style={{ marginLeft: 8 }}
      variant='contained'
      color='primary'
      onClick={onClick}
      startIcon={busy ? <CircularProgress size={15} /> : <Icon />}>{label}</Button>
  }

  /**
   * 
   */
  return (<>
    {toolTip?.length ? <RichToolTipButton title={toolTip}>{renderButton()}</RichToolTipButton> : renderButton()}
    <ProgressModal note={"Please wait..."} open={busy} />
  </>)
}


const RichIconButton = ({ icon, busy, fontSize, onClick }: {
  icon: string,
  fontSize: string,
  busy: boolean,
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  let Icon = Icons[icon]
  return (<IconButton onClick={onClick}>
    {busy ? <CircularProgress size={15} /> : <Icon fontSize={fontSize || "small"} />}
  </IconButton>
  )
}

const NormalButton = ({ label, icon, busy, onClick, title }: {
  label: string,
  icon: string,
  fontSize: string,
  busy: boolean,
  title: string,
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  let Icon = Icons[icon]
  return (<Button
    disabled={busy}
    style={{ marginLeft: 8 }}
    variant='contained'
    color='primary'
    title={title}
    onClick={onClick}
    startIcon={busy ? <CircularProgress size={15} /> : <Icon />}>{label}</Button>)
}


const RichToolTipButton = ({ title, children }: { title: string, children: any }): JSX.Element => <ToolTip tooltip={title || "A"}>{children}</ToolTip>
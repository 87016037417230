import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { payPayee } from '../business/actions/payees'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { IFutureTransaction, IPayee } from '../core/index'
import { useAuth } from '../business/authProvider';
import { useState } from 'react';
import ProgressModal from './ui/ProgressModal';
import BypassInputModal from './ui/BypassInputModal';
interface IPayButton {
  label: string,
  disabled: boolean,
  futureTransactions: Array<IFutureTransaction>
}

export default ({
  label,
  disabled,
  futureTransactions,
}: IPayButton): JSX.Element => {
  const confirm = useConfirm();
  const history = useHistory();
  const auth = useAuth();
  /**
   * 
   */
  const [busy, setBusy] = useState<boolean>(false);
  const [shouldShowBypassInputModal, showBypassInputModal] = useState<boolean>(false);
  /**
   * 
   * @returns 
   */
  const onCloseBypassInputModal = () => showBypassInputModal(false)
  /**
   * 
   * @returns 
   */
  const confirmPayment = () => {
    let totalPayout = futureTransactions.reduce((a, b) => ({ payout_amount: (a.payout_amount || 0) + (b.payout_amount || 0) })).payout_amount
    let description = `Are you sure you want to send ${totalPayout}EUR to ${futureTransactions.length} Payees?`;
    confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description })
      .then(() => submitPayment())
      .catch(() => { /* ... */ });
  }

  const onSubmitBypassKey = values => {
    const { bypass_key } = values;
    onCloseBypassInputModal();
    submitPayment(bypass_key)
  }
  const submitPayment = (one_time_token: string | null = null) => {
    setBusy(true);
    let payee_ids = futureTransactions.map(futureTransaction => futureTransaction.payee_id);

    payPayee(one_time_token ? { payees: payee_ids, one_time_token } : { payees: payee_ids })
      .then(response => {
        let { batch_id, errors, status } = response;
        if (errors?.length) {
          toast.error(errors.map(error => (error.message || error)).join("\n"))
        }
        else {
          toast.success(status);
        }
        if (batch_id) {
          history.push(`/batches/${batch_id}/view`);
        }
      })
      .catch(error => {
        toast(error.response.data?.message || "Unexpected error")
        if (error.response.data?.duration_limit_exceeded) {
          if (!one_time_token) showBypassInputModal(true)
        }
      })
      .finally(f => setBusy(false))
  }
  return (
    <>
      <Button disabled={disabled} onClick={confirmPayment} style={{ marginRight: 8 }} variant="contained" color="primary">{label}</Button>
      <ProgressModal open={busy} />
      <BypassInputModal open={shouldShowBypassInputModal} onSubmitBypassKey={onSubmitBypassKey} onClose={onCloseBypassInputModal} />
    </>
  )
}
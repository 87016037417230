import React, { useEffect, useState } from 'react';
import TextField from '../../../../components/ui/TextField';
import Select from '../../../../components/ui/Select'
import Checkbox from '../../../../components/ui/Checkbox'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles';
import TagFilter from "../../../../components/ui/TagFilter"
import { Button, colors, Divider, LinearProgress, FormControlLabel, InputLabel, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { IFilter, ISelectOption } from '../core';
import CategorizationFilter from '../../../../components/ui/CategorizationFilter';
import { ListProvider, useList } from '../../../../business/providers/listProvider';
import PageHeading from '../../../../components/ui/PageHeading';
import { getCostReport } from '../../../../business/actions/report';
import ViewField from '../../../../components/ui/ViewField';
import { IExpensesCategory, ITag } from '../../../../core';
import { getTags } from '../../../../business/actions/tags';
import { getExpensesCategories } from '../../../../business/actions/expense-categorisation';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: '100%'
  },
  filterOuter: {
    padding: 16,
    display: "flex",
    columnGap: 40,
    alignItems: "center"
  },
  filterRows: {

  },
  filterButton: {

  },
  filterOptions: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    rowGap: 16,
    columnGap: 8,
    alignItems: "center"
  },
  tableCell: {
    border: "none"
  }
}));

const FullReport = () => {
  const classes = useStyles();
  const list = useList();
  const [busy, setBusy] = useState(false)
  const [transactionFilters, setTransactionFilters] = useState<IFIlterField>({})
  const [invoiceFilters, setInvoiceFilters] = useState<IFIlterField>({})
  const [reportData, setReportData] = useState()
  const [expensesCategories, setExpensesCategories] = useState<Array<IExpensesCategory>>([]);
  const [tags, setTags] = useState<Array<ITag>>([]);

  const transactionsFilter: Array<IFilter> = [
    { id: 'transaction_report', type: 'CheckBox', label: "Transactions" },
    { id: 'transaction_report_from_date', type: 'Date', label: "Period From" },
    { id: 'transaction_report_to_date', type: 'Date', label: "Period To" },
    { id: 'tag_ids', type: 'TagFilter', label: "Tags", returnData: 'id' }
  ]

  const InvoiceFilter: Array<IFilter> = [
    { id: 'invoice_report', type: 'CheckBox', label: "Invoices & PDF Storage" },
    { id: 'invoice_report_from_date', type: 'Date', label: "Period From" },
    { id: 'invoice_report_to_date', type: 'Date', label: "Period To" },
    { id: 'category_ids', type: 'CategorizationFilter', label: "Categorization", returnData: 'id' }
  ]

  useEffect(() => {
    getTags({}).then((tags: Array<ITag>) => setTags(tags));
    getExpensesCategories({}).then((expensesCategories: Array<IExpensesCategory>) => setExpensesCategories(expensesCategories));
  }, [])
  const handleFilter = () => {
    setBusy(true)
    let applied_filters = { ...transactionFilters, ...invoiceFilters }
    if (applied_filters.category_ids) {
      applied_filters.category_ids = applied_filters.category_ids.split(",").map(item => expensesCategories.find(c => c.text === item).id).join(",")
    }
    if (applied_filters.tag_ids) {
      applied_filters.tag_ids = applied_filters.tag_ids.split(",").map(item => tags.find(c => c.text === item).id).join(",")
    }
    getCostReport(applied_filters)
      .then(json => {
        let { transaction_report } = json
        json.transaction_report.map(item => {
          item.label = tags.find(tag => tag.id === item.tag_id).text
        })
        json.invoice_report.map(item => {
          item.label = expensesCategories.find(expensesCategory => expensesCategory.id === item.category_id).text
        })
        setReportData(json)
      }).finally(() => setBusy(false))
  }
  const onUpdateTransactionFilters = (values) => setTransactionFilters({ ...values })
  const onUpdateInvoiceFilters = (values) => setInvoiceFilters({ ...values })
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'What do you want to see?'}></PageHeading>
        <Divider />
        <div className={classes.filterOuter}>
          <div className={classes.filterRows}>
            <Table>
              <TableBody>
                <TableRow>
                  <Filters data={transactionsFilter} onUpdateFilter={onUpdateTransactionFilters} filters={list.filters} />
                </TableRow>
                <TableRow>
                  <Filters data={InvoiceFilter} onUpdateFilter={onUpdateInvoiceFilters} filters={list.filters} />
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={classes.filterButton}>
            <Button onClick={handleFilter} variant="contained" color="primary">
              Run Request
            </Button>
          </div>
        </div>
      </Paper>
      {busy ? <LinearProgress /> : null}
      {reportData ?
        <div style={{ paddingTop: 16 }}>
          <div style={{ display: "flex", columnGap: 16 }}>
            {transactionFilters.transaction_report ? <TransactionReport data={reportData} /> : null}
            {invoiceFilters.invoice_report ? <InvoiceReport data={reportData} /> : null}
          </div>
          <div style={{ padding: 16, textAlign: "center", fontWeight: "bold" }}>
            Total {reportData?.grand_total_summary || 0}€
          </div>
        </div>
        :
        null
      }
    </div>
  );
}

const TransactionReport = ({ data }) => <div style={{ height: '100%', width: '100%' }}>
  <Paper>
    <PageHeading title={'Transactions'}>
      <div>{data?.transaction_report_summary || 0}€</div>
    </PageHeading>
    <Divider />
    <div style={{ padding: 16 }}>
      {data.transaction_report.map(item => <ViewField record={item} source="amount" label={item.label} />)}
    </div>
  </Paper>
</div>
const InvoiceReport = ({ data }) => <div style={{ height: '100%', width: '100%' }}>
  <Paper>
    <PageHeading title={'Invoices'}>
      <div>{data?.invoice_report_summary || 0}€</div>
    </PageHeading>
    <Divider />
    <div style={{ padding: 16 }}>
      {data.invoice_report.map(item => <ViewField record={item} source="amount" label={item.label} />)}
    </div>
  </Paper >
</div >
interface IFIlterField {
  [key: string]: any;
}

const Filters = ({ data, onUpdateFilter, filters }) => {
  const classes = useStyles();
  const [filterFields, setFilterFields] = useState<IFIlterField>({})

  const onChange = (id: string, value: any) => {
    if (value === "") delete filterFields[id];
    else filterFields[id] = value;
    setFilterFields({ ...filterFields })
    handleFilter()
  }
  const onChangeDate = (id: string, value: number) => {
    if (isNaN(value)) {
      delete filterFields[id];
      setFilterFields({ ...filterFields })
    }
    else {
      onChange(id, value)
    }
  }
  const onSelect = (id: string, value: any) => {
    onChange(id, value)
  }

  const onChecked = (id: string, value: any) => {
    onChange(id, value)
  }
  const handleFilter = () => onUpdateFilter({ ...filterFields });
  const getFilterInput = (filter: IFilter, index: number) => {
    switch (filter.type) {
      case "CheckBox": return <FormControlLabel id={filter.id} control={<Checkbox id={filter.id} checked={filterFields[filter.id] || false} onChange={onChecked} />} label={filter.label} />
      case "Date": return <TextField onEnterKey={handleFilter} value={filterFields[filter.id] ? moment.unix(filterFields[filter.id]).format('YYYY-MM-DD') : ""} InputLabelProps={{ shrink: true }} key={index} onChange={onChangeDate} id={filter.id} label={filter.label} type="date" variant="outlined" />
      case "TagFilter": return <TagFilter name="tags" value={filterFields[filter.id] || ""} {...filter} onChange={onSelect} />
      case "CategorizationFilter": return <CategorizationFilter multipleSelect={true} style={{ minWidth: 200 }} name="categorization" value={filterFields[filter.id] || ""} {...filter} onChange={onSelect} />
      default: return null;
    }
  }

  return (
    <>
      {data.map((filter: IFilter, index: number) => <TableCell key={index} className={classes.tableCell}>{getFilterInput(filter, index)}</TableCell>)}
    </>
  )
}



export default ({ }): JSX.Element => <ListProvider><FullReport /></ListProvider>
import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Paper, Typography, Checkbox, IconButton } from '@material-ui/core';
import DataGrid from '../../../lib/Table';
import Filters from '../../../lib/Filters';
import { getTransactions, updateTransactionNeedFixing } from '../../../business/actions/transactions';
import DescriptionIcon from '@material-ui/icons/Description';
import { convertDateToItalyTime, trimFilterData, transactionStatuses } from '../../../utils';
import { ListProvider, useList } from '../../../business/providers/listProvider';
import ChipsField from '../../../components/ui/ChipsField'
import {
  Link,
  useHistory
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
import NoteButton from '../../../components/NoteButton';
const columns: Array<IColumn> = [
  { field: 'tags', headerName: 'Tags', renderValue: data => <ChipsField data={data} labelField={"text"} /> },
  { field: 'payee_id', headerName: 'Payee ID' },
  {
    field: 'username', headerName: 'Username', renderValue: (value, entry) => <>
      <Link to={`/payees/${entry.payee_id}/view`} target={"_blank"}>{value}</Link>
      {
        (entry.is_italian === "Italian Ritenuta" || entry.is_italian === "Italian Partita Iva") ? <>
          <img src="/assets/media/images/italian-flag.svg" style={{ width: 24, marginLeft: 4, marginRight: 4 }} />
          {
            entry.is_italian === "Italian Ritenuta" ? "-20" : "P.Iva"
          }</> : null
      }
    </>
  },
  { field: 'payout_number', headerName: 'Payouts' },
  { field: 'created_at', headerName: 'Date and Time', formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'transaction_id', headerName: 'Blazing Boost Transaction ID' },
  { field: 'payout_amount', headerName: 'Transaction Amount', formatting: num => num?.toFixed(2) || num },
  { field: 'payout_status', headerName: 'Transaction Status' },
  { field: 'payout_method', headerName: 'Payout Method' },
  { field: 'sent_by', headerName: 'Sent By' },
];

const _filters: Array<IFilter> = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'transaction_id', type: 'TextField', label: "Blazing Boost Transaction ID" },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'from_amount', type: 'TextField', label: "Amount from" },
  { id: 'to_amount', type: 'TextField', label: "Amount to" },
  { id: 'payout_method', type: 'Select', allowEmpty: true, label: "Payout Method", options: [{ label: 'Paypal' }, { label: 'TransferWise' }, { label: 'AdvCash' }, { label: 'Payoneer' }, { label: 'Revolut' }] },
  { id: 'is_italian', type: 'Select', allowEmpty: true, label: "Italian Payees", options: [{ label: 'Yes -20% Ritenuta', value: "italian_ritenuta" }, { label: 'Yes with Tax Number', value: "italian_partita_iva" }, { label: 'No', value: "not_italian" }] },
  { id: 'payout_id', type: 'TextField', label: "Payout Id" },
  { id: 'payout_status', type: 'MultipleSelect', name: "payout_status", multipleSelect: true, label: "Transaction Status", labelField: "label", idField: "label", options: transactionStatuses },
  { id: 'need_fixing', type: 'Select', label: "Fixing Status", value: "Any", options: [{ label: 'Any' }, { label: 'Fixed', value: "true" }, { label: 'Not Fixed', value: "false" }] },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
  { id: 'hide_failed', type: 'CheckBox', label: "Hide Failed", value: true }
]
function DataTable(props) {
  const list = useList();
  const auth = useAuth();
  const { failed, archived } = props;
  const filterFields = [..._filters.filter(_filter => (failed && (_filter.id !== "payout_status" && _filter.id !== "hide_failed")) || !failed)]

  const [busy, setBusy] = useState(false)
  const [listData, setListData] = useState([])
  const [totalItems, setTotalItems] = useState(0);

  const authRoles= auth.roles;

  /**
   * Load list data when filter/paging updates
   */
  useEffect(() => {
    setListData([]);
    loadData();
  }, [list.filters, list.pagination, list.sort])

  const loadData = () => {
    setBusy(true)
    let appliedFilters = { ...list.filters }
    
    if (appliedFilters.is_italian && appliedFilters.is_italian !== "Any") {
      appliedFilters[appliedFilters.is_italian] = true;
      delete appliedFilters.is_italian;
    }

    if (appliedFilters.need_fixing === "Any") delete appliedFilters.need_fixing;
    if (failed) {
      appliedFilters.payout_status = "failed"
    } else {
      let hideFailed = appliedFilters.hide_failed == null ? true : appliedFilters.hide_failed;
      delete appliedFilters.hide_failed;
      if (!appliedFilters.payout_status && hideFailed) {
        appliedFilters.payout_status = "unpaid,processing,sent,paid"
      }
    }
    /**
     * Query with archived
     */
    if (archived) appliedFilters.archived = 1;
    getTransactions({ ...trimFilterData(appliedFilters), ...list.pagination, ...list.sort })
      .then(json => {
        let { TotalItems, PaginatedItems } = json;
        setTotalItems(TotalItems);
        setListData(PaginatedItems.map(entry => ({
          ...entry,
          payout_number: entry.payout_ids?.length || 0
        })))
      }).finally(e => setBusy(false))
  }
  /**
   * 
   * @param {*} transaction 
   * @returns 
   */
  const handleNeedFixing = transaction => event => {
    let need_fixing_status = event.target.checked
    updateTransactionNeedFixing(transaction.transaction_id, { need_fixing_status }).then(e => {
      if (e) {
        let index = listData.findIndex(entry => transaction.transaction_id === entry.transaction_id)
        listData[index].need_fixing = need_fixing_status;
        setListData([...listData])
      }
    })
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Transactions: list of executed payouts (group of order items) per specific booster/payee'}>
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <DataGrid
            idField="transaction_id"
            route={'transactions'}
            canView={true}
            rows={listData.map(entry => ({
              ...entry,
              need_fixing_cb: entry.payout_status === "failed" ? <Checkbox checked={entry.need_fixing} onChange={handleNeedFixing(entry)} /> : null,
              customActions: [<NoteButton data={entry} />]

            }))}
            columns={(auth.permission || authRoles['post_transaction_transaction_id_update_need_fixing']) ? [{ field: 'need_fixing_cb', headerName: 'Fixed' }, , ...columns] : columns}
            count={totalItems}
            canSort={false}
            checkboxSelection
          />
        </div>
      </Paper>
    </div>
  );
}

const List = (props) => <ListProvider><DataTable {...props} /></ListProvider>

export default List
import List from './List'
import Create from './Create'
import View from './View'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../../business/authProvider';

const PayeeRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_permissions_users']) ? <Route path={`${url}/list`} component={List} /> : null}
    {(auth.permission || authRoles['post_permissions_user']) ? <Route path={`${url}/create`} component={Create} /> : null}
    {(auth.permission || authRoles['patch_permissions_user_email']) ? <Route path={`${url}/:user_email/edit`} component={Create} /> : null}
    {(auth.permission || authRoles['get_permissions_user_email']) ? <Route path={`${url}/:user_id/view`} component={View} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>
  )
}
export default PayeeRoutes
import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '../../../lib/Table'
import Filters from '../../../lib/Filters'
import ChipsField from '../../../components/ui/ChipsField'
import { Link } from 'react-router-dom';
import PageHeading from '../../../components/ui/PageHeading';
import { getArchivedPayees, unarchivePayee } from '../../../business/actions/payees'
import { useAuth } from '../../../business/authProvider';
import { getTags } from '../../../business/actions/tags';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useList, ListProvider } from '../../../business/providers/listProvider'
import { useConfirm } from 'material-ui-confirm';
import { trimFilterData } from '../../../utils';
import { IColumn, IFilter } from '../../../core';
const columns: Array<IColumn> = [
  { field: 'tags', headerName: 'Tags', renderValue: data => <ChipsField data={data} labelField={"text"} /> },
  { field: 'payee_id', headerName: 'Payee ID' },
  { field: 'username', headerName: 'Username', renderValue: (value, entry) => <>{value}</> },
  { field: 'email', headerName: 'Payee Email' },
  { field: 'payout_method', headerName: 'Payout Method' },
  { field: 'future_transaction_payout_amount', headerName: 'Future Transaction' },
  { field: 'transactions_payout_amount', headerName: 'Executed Transaction' },
  { field: 'action', headerName: '' }
];
//offset=0&limit=10&payee_id=a&username=transferwise&email=a&payout_method=TransferWise
const _filters: Array<IFilter> = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'email', type: 'TextField', label: "Email" },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
  { id: 'payout_method', type: 'Select', allowEmpty: true, label: "Payout Method", options: [{ label: 'Paypal' }, { label: 'TransferWise' }, { label: 'AdvCash' }, { label: 'Payoneer' }, { label: 'Revolut' }] },
  { id: 'is_italian', type: 'Select', allowEmpty: true, label: "Italian Payees", options: [{ label: 'Yes -20% Ritenuta', value: "italian_ritenuta" }, { label: 'Yes with Tax Number', value: "italian_partita_iva" }, { label: 'No', value: "not_italian" }] },
]

function DataTable() {
  /**
   * Hooks
   */
  const auth = useAuth()
  const list = useList();
  const history = useHistory();
  const confirm = useConfirm();
  /**
   * State
   */
  const [payees, setPayees] = useState([])
  const [totalPayees, setTotalPayees] = useState(0);
  const [busy, setBusy] = useState(false);
  /**
   * Private
   */
  const filterFields = [..._filters];

  const authRoles= auth.roles;

  useEffect(() => {
    setPayees([])
    loadPayee();
  }, [list.filters, list.pagination, list.sort])
  const loadPayee = () => {
    setBusy(true)
    let appliedFilters = { ...list.filters }

    if (appliedFilters.is_italian && appliedFilters.is_italian !== "Any") {
      appliedFilters[appliedFilters.is_italian] = true;
      delete appliedFilters.is_italian;
    }

    getArchivedPayees({ ...trimFilterData(appliedFilters), ...list.pagination, ...list.sort }).then(json => {
      let { TotalItems, PaginatedItems } = json;
      if (TotalItems) setTotalPayees(TotalItems);
      if (PaginatedItems) {
        let payees = PaginatedItems.map(entry => {
          let e_t = entry.executed_transactions_summary;
          let f_t = entry.future_transactions_summary;
          entry.transactions_payout_amount = e_t ? `${e_t.payout_amount.toFixed(2)} ${e_t.currency}` : ''
          entry.future_transaction_payout_amount = f_t ? `${f_t.payout_amount.toFixed(2)} ${f_t.currency}` : ''
          return entry;
        })
        setPayees(payees)
      }
    }).finally(e => setBusy(false))
  }
  const handleUnarchivePayee = (payee) => {
    if (busy) return;
    confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description: `Are you sure you want to unarchive payee ${payee.payee_id}?` })
      .then(response => {
        setBusy(true)
        unarchivePayee(payee.payee_id)
          .then(response => { toast('Payee unarchived.'); })
          .catch(error => toast(error.response.data?.message || "Payee is not unarchived. Unexpected error"))
          .finally(() => setTimeout(loadPayee, 1000))
      }).catch(() => { /* ... */ });

  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'Archived Payees: These are all the boosters that we archived from the system and they can not be paid until they are unarchived.'}>
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'payee_id'}
            canSelect={false}
            route={'payees'}
            canEdit={false}
            canSort={false}
            canView={false}
            title={'Payees'}
            rows={payees.map(payee => ({
              ...payee,
              action: (auth.permission || authRoles['post_payee_payee_id_unarchive']) ? <div style={{ textAlign: 'center' }}>
                <Button
                  onClick={(event) => handleUnarchivePayee(payee)}
                  variant="contained" color="primary">
                  Unarchive
                </Button>
              </div> : null,
            }))}
            columns={columns}
            count={totalPayees}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}


const List = () => <ListProvider><DataTable /></ListProvider>

export default List
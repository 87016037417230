import { API, Auth } from 'aws-amplify';
const API_NAME = process.env.REACT_APP_AWS_API_NAME;
//
export const get = async (route, params) => {
  return API.get(API_NAME, route, {
    queryStringParameters: params,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  })
}

export const post = async (route, params, headers) => {
  return API.post(API_NAME, route, {
    body: params,
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      ...headers
    }
  });
}

export const del = async (route, params) => {
  return API.del(API_NAME, route, {
    body: params,
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  });
}

export const patch = async (route, params) => {
  return API.patch(API_NAME, route, {
    body: params,
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  });
}

export const responseResult = response => response;

export const tagTextFromArray = array => array.map(entry => {
  entry.tag_text = entry.tags.map(entry => entry.text)
  return entry;
})

export const normaliseTagText = response => {
  if (response.PaginatedItems) {
    //response.PaginatedItems = tagTextFromArray(response.PaginatedItems);
  } else if (typeof response === "Array") {
    tagTextFromArray(response)
  } else if (response.tag_text) {
    response.tag_text = response.tags.map(entry => entry.text)
  }
  return response;
}

import List from './List'
import View from './View'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

/*
const BatchesRoutes = () => <Switch>
  <Route path={'/batches/list'} component={List} />
  <Route path={'/batches/:batch_id/view'} component={View} />
  <Route path={'*'}>
    <Redirect to="/batches/list" />
  </Route>
</Switch>;
*/
const BatchesRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_batches']) ? <Route path={'/batches/list'} component={List} /> : null}
    {(auth.permission || authRoles['get_batch_batch_id']) ? <Route path={'/batches/:batch_id/view'} component={View} /> : null}
    <Route path={'*'}>
      <Redirect to="/batches/list" />
    </Route>
  </Switch>
  )
};

export default BatchesRoutes
import { Button, Divider, Grid, IconButton, makeStyles, Modal, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { validateFields } from '../../utils';
import PageHeading from './PageHeading';
import CloseIcon from '@material-ui/icons/Close'
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),

  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    background: '#FFFFFF',
    padding: 16,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    paddingTop: 10
  },
}));

const BypassInputModal = ({ open, onSubmitBypassKey, onClose }) => {
  const classes = useStyles();
  const validate = values => validateFields(values, ["bypass_key"])

  return (<Modal
    open={open}
    className={classes.modal}
    onClose={onClose}
  >
    <Paper>
      <PageHeading title={'Please input one time bypass key'}>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </PageHeading>
      <Divider />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Form
            onSubmit={onSubmitBypassKey}
            initialValues={{
              bypass_key: ""
            }}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="bypass_key" fullWidth required component={TextField} type="text" label="One time bypass key" />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
      </div>
    </Paper>
  </Modal>
  )
}
export default BypassInputModal;
import List from './List'
import Create from './Create'
import BillingCreate from './BillingCreate'
import View from './View'
import FT from './FT'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';
/*
const PayeeRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  return (<Switch>
    <Route path={`${url}/list`} component={List} />
    {auth.permission ? <Route path={`${url}/create`} component={Create} /> : null}
    {auth.permission ? <Route path={`${url}/:payee_id/edit`} component={Create} /> : null}
    <Route path={`${url}/:payee_id/view`} component={View} />
    <Route path={`${url}/:payee_id/future-transactions`} component={FT} />
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>
  )
}
*/
const PayeeRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (
    <Switch>
      {(auth.permission || authRoles['get_payees']) ? <Route path={`${url}/list`} component={List} /> : null}
      {(auth.permission || authRoles['post_payee']) ? <Route path={`${url}/create`} component={Create} /> : null}
      {(auth.permission || authRoles['patch_payee_payee_id']) ? <Route path={`${url}/:payee_id/edit`} component={Create} /> : null}
      {(auth.permission || authRoles['patch_payee_payee_id']) ? <Route path={`${url}/:payee_id/billing/edit`} component={BillingCreate} /> : null}
      {(auth.permission || authRoles['get_payee_payee_id']) ? <Route path={`${url}/:payee_id/view`} component={View} /> : null}
      {(auth.permission || authRoles['get_payee_payee_id_future_transactions']) ? <Route path={`${url}/:payee_id/future-transactions`} component={FT} /> : null}
      <Route path={'*'}>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  )
}



export default PayeeRoutes
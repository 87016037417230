import React from 'react';
import ProfilePage from './profile'
import ProfilePasswordPage from './profile/updatePassword'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
const AccountRoutes = () => {
  let { url } = useRouteMatch();
  return (<Switch>
    <Route exact path={`${url}/profile`} component={ProfilePage} />
    <Route exact path={`${url}/profile/updatePassword`} component={ProfilePasswordPage} />
    <Route path={'*'}>
      <Redirect to={`${url}/profile`} />
    </Route>
  </Switch>)
}

export default AccountRoutes
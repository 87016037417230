import React from 'react';
import ReactDOM from 'react-dom';
import { Form, Field } from 'react-final-form';
import { TextField, Checkbox, Radio, Select } from 'final-form-material-ui';
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

const onSubmit = async values => {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};
const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  return errors;
};


export default function Create(props) {
  const edit = props.match.params?.id || null

  return (
    <div style={{ height: '100%', width: '100%', maxWidth: 600, }}>
      <div style={{ margin: 'auto' }}>
        <CssBaseline />
        <Typography variant="h6" id="tableTitle" component="div" gutterBottom>
          {edit ? 'Edit' : 'Create'} User
      </Typography>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            id: 1,
            username: "Nicolas",
            email: 'nicolas@gmail.com',
            password: '123456789',
            permission: "1"
          }}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field name="username" fullWidth required component={TextField} type="text" label="Username" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="email" fullWidth required component={TextField} type="email" label="Email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="password" fullWidth required component={TextField} type="password" label="Password" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="permission"
                      component={Select}
                      label="Permissions"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="1">View</MenuItem>
                      <MenuItem value="2">View and Edit</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                  </Button>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                  </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}
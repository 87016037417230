import Create from './Create'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

/*
const PayoutRoutes = () => <Switch>
  <Route path={'/payouts/create'} component={Create} />
  <Route path={'*'}>
    <Redirect to="/payouts/create" />
  </Route>
</Switch>
*/
const PayoutRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (
    <Switch>
      {(auth.permission || authRoles['post_payout']) ? <Route path={`${url}/create`} component={Create} /> : null}
      <Route path={'*'}>
        <Redirect to={`${url}/create`} />
      </Route>
    </Switch>
  )
}

export default PayoutRoutes
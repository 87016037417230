import React, { useEffect, useState } from 'react';
import { TextField, IconButton, CircularProgress, } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { toast } from 'react-toastify';

interface INoteField {
  saveAction: Function,
  errorMessage: string,
  defaultValue: string,
  successMessage: string,
  onSaveSuccess?: Function,
  maxLength?: number
}

export default ({ saveAction, errorMessage, defaultValue, successMessage, onSaveSuccess, maxLength }: INoteField): JSX.Element => {
  const [busy, setBusy] = useState(false);
  const [note, setNote] = useState(defaultValue);
  /**
   * 
   * @param {*} event 
   * @returns 
   */
  const onChange = (event) => setNote(event.target.value);
  /**
   * 
   */
  const onSave = () => {
    setBusy(true);
    saveAction(note)
      .then(response => {
        if (onSaveSuccess) { onSaveSuccess(); }
        toast(successMessage)
      })
      .catch(error => toast(error.response.data?.message || errorMessage))
      .finally(() => setBusy(false))
  }
  return (<>
    <TextField onChange={onChange} value={note || defaultValue} inputProps={{ maxLength: maxLength || undefined }} />
    {busy ? <CircularProgress size={20} /> : <IconButton onClick={onSave}><CheckIcon fontSize="small" /></IconButton>}
  </>
  );
}
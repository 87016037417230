import List from './List'
import Create from './Create'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';
/*
export default () => <Switch>
<Route path={'/blacklisted-payees/list'} component={List} />
<Route path={'/blacklisted-payees/create'} component={Create} />
<Route path={'*'}>
  <Redirect to="/blacklisted-payees/list" />
</Route>
</Switch>
*/

const BlackListePayeesRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_blacklisted_payees']) ? <Route path={`${url}/list`} component={List} /> : null}
    {(auth.permission || authRoles['post_blacklisted_payees']) ? <Route path={`${url}/create`} component={Create} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>)
}

export default BlackListePayeesRoutes
import { get, patch, post, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const generateKeys = () => get('admin/public_key').then(responseResult)
export const submitKeys = (api_key) => post('admin/tw_key', { api_key }).then(responseResult)
/**
 * 
 * @returns 
 */
export const generateByPassThresholdKey = () => post('generate_token').then(responseResult)

export const createSettingsEmail = (data) => patch('settings', data).then(responseResult)
export const getSettingsEmail = () => get('settings').then(responseResult)
/**
 * 
 */
export const getCompanyBillingDetails = () => get('company_billing_details').then(responseResult)
export const patchCompanyBillingDetails = (data) => patch('company_billing_details', data).then(responseResult)
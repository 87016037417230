import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Divider, LinearProgress, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import Table from '../../../lib/Table'
import Filters from '../../../lib/Filters'
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import { getFutureTransaction } from '../../../business/actions/future-transactions'
import { deleteAllPayoutOfPayee } from '../../../business/actions/payees'
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { useAuth } from '../../../business/authProvider';
import { ListProvider, useList } from '../../../business/providers/listProvider';
import { LockOpenRounded, LockRounded } from '@material-ui/icons';
import ChipsField from '../../../components/ui/ChipsField'
import { trimFilterData } from '../../../utils';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
import PayButton from '../../../components/PayButton';
import DeleteButton from '../../../components/DeleteButton';
import { IFutureTransactionItem } from '../../../core';
import { ADVCASH, PAYPAL } from '../../../utils/vars';

const _filters: Array<IFilter> = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'payee_email', type: 'TextField', label: 'Payee Email' },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'amount_from', type: 'TextField', label: "Amount from" },
  { id: 'amount_to', type: 'TextField', label: "Amount to" },
  { id: 'payout_method', type: 'Select', allowEmpty: true, label: "Payout Method", options: [{ label: 'Paypal' }, { label: 'TransferWise' }, { label: 'AdvCash' }, { label: 'Payoneer' }, { label: 'Revolut' }] },
  { id: 'is_italian', type: 'Select', allowEmpty: true, label: "Italian Payees", options: [{ label: 'Yes -20% Ritenuta', value: "Italian Ritenuta" }, { label: 'Yes with Tax Number', value: "Italian Partita Iva" }, { label: 'No', value: "Not italian" }] },
  { id: 'payout_email', type: 'TextField', label: "Payout Email" },
  { id: 'payout_ids', type: 'TextField', label: "Payout Id" },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
  { id: 'show_paused', type: 'CheckBox', label: "Show Paused Payees", value: true }
]
const useStyles = makeStyles((theme) => ({
  selection: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
function DataTable() {
  const auth = useAuth();
  let list = useList();
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [transactions, setTransactions] = useState<Array<IFutureTransactionItem>>([]);
  const [filteredData, setFilteredData] = useState<Array<IFutureTransactionItem>>([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [busy, setBusy] = useState<boolean>(false);
  const [busyDelete, setBusyForDeletion] = useState<boolean>(false);
  const filterFields: IFilter[] = [..._filters]
  const confirm = useConfirm();
  const authRoles = auth.roles;
  /**
   * 
   */
  let columns: Array<IColumn> = [
    { field: 'tags', headerName: 'Tags', renderValue: data => <ChipsField data={data} labelField={"text"} /> },
    { field: 'payee_id', headerName: 'Payee ID' },
    { field: 'email', headerName: 'Payee Email' },
    {
      field: 'username', headerName: 'Username', renderValue: (data, entry) => <>{data}{
        (entry.is_italian === "Italian Ritenuta" || entry.is_italian === "Italian Partita Iva") ? <>
          <img src="/assets/media/images/italian-flag.svg" style={{ width: 24, marginLeft: 4, marginRight: 4 }} />
          {
            entry.is_italian === "Italian Ritenuta" ? "-20" : "P.Iva"
          }</> : null
      }
      </>
    },
    { field: 'number_of_payouts', headerName: 'Payouts' },
    { field: 'payout_amount', headerName: 'Payout Amount', formatting: num => num.toFixed(2) },
    { field: 'payout_method', headerName: 'Payout Method', renderValue: (column, row) => (row.warning && row.warning !== "") ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><WarningIcon style={{ color: '#FFCC00' }} /><div>{column}</div></div> : column, },
    { field: 'payout_email', headerName: 'Payout Email' },
    { field: 'paused', headerName: 'Paused Payee status', renderValue: (paused, row) => paused ? <LockRounded /> : <LockOpenRounded /> },
    { field: 'warnings', headerName: '', renderValue: (column, row) => column?.length ? <div title={column.join("\n")} style={{ textAlign: 'center' }}><WarningIcon style={{ color: '#FFCC00' }} /></div> : null },
  ].concat(auth.canUserPay ? [{ field: 'pay', headerName: 'Action', renderValue: (column, row) => (auth.permission || authRoles['post_pay']) ? <PayButton label="Pay" disabled={false} futureTransactions={[row]} /> : null },] : []);
  /**
   * Load all future transactions
   */
  useEffect(() => loadFT(), [])
  useEffect(() => {
    updateDataWithFilter();
  }, [list.filters, list.pagination, list.sort])
  /**
   * 
   */
  const updateDataWithFilter = () => {
    let data = transactions.filter(entry => {
      let keys = 0, found = 0;
      let filters = trimFilterData(list.filters);
      for (var key in filters) {
        ++keys;
        if (list.filters[key] === "") {
          ++found;
          continue;
        }
        switch (key) {
          case "payee_id":
            if (entry.payee_id.toLowerCase().indexOf(list.filters[key].toLowerCase()) > -1) ++found;
            break;
          case "payee_email":
            if (entry.email) {
              if (entry.email.toLowerCase().indexOf(list.filters[key].toLowerCase()) > -1) ++found;
            }
            break;
          case "username":
            if (entry.username?.toLowerCase().indexOf(list.filters[key].toLowerCase()) > -1) ++found;
            break;
          case "tags":
            let filterTags = list.filters[key].split(",");
            let matchedTags = entry.tags?.filter(etag => filterTags.find(tagLabel => tagLabel === etag.text));
            if (matchedTags.length === filterTags.length) ++found;
            break;
          case "amount_from":
            if (entry.payout_amount >= list.filters[key]) ++found;
            break;
          case "amount_to":
            if (entry.payout_amount <= list.filters[key]) ++found;
            break;
          case "payout_method":
            if (list.filters[key] === "" || entry.payout_method === list.filters[key]) ++found;
            break;
          case "payout_email":
            if (entry.payout_email) {
              if (entry.payout_email.toLowerCase().indexOf(list.filters[key].toLowerCase()) > -1) ++found;
            }
            break;
          case "payout_ids":
            if (entry.payout_ids) {
              let payout_id = list.filters[key].toLowerCase();
              if (entry.payout_ids.find(payout => payout.toLowerCase().indexOf(payout_id) > -1)) ++found;
            }
            break;
          case "show_paused":
            if (!list.filters[key] && !entry.paused) {
              ++found
            } else if (list.filters[key]) {
              ++found
            }
            break;
          case "is_italian":
            if (entry.is_italian) {
              if (entry.is_italian.toLowerCase().indexOf(list.filters[key].toLowerCase()) > -1) ++found;
            }
            break;
          default: return;
        }
      }
      return keys === found;
    })
    if (list.sort) {
      let { sort } = list;
      if (sort.orderBy === 'payout_amount') {
        data.sort((a, b) => sort.order === 'asc' ? a.payout_amount - b.payout_amount : b.payout_amount - a.payout_amount)
      } else if (sort.orderBy === 'number_of_payouts') {
        data.sort((a, b) => sort.order === 'asc' ? a.number_of_payouts - b.number_of_payouts : b.number_of_payouts - a.number_of_payouts)
      } else if (sort.orderBy === 'payee_id' || sort.orderBy === 'tags' || sort.orderBy === 'payout_method' || sort.orderBy === 'username') {
        data.sort((a, b) => {
          var nameA = a[sort.orderBy].toUpperCase();
          var nameB = b[sort.orderBy].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        })
        if (sort.order === 'desc') data.reverse();
      }
    }
    let data1 = data.slice(list.pagination.offset, list.pagination.offset + list.pagination.limit)
    setSelectedItems([])
    setFilteredData(data1)
    setTotalItems(data.length)
  }
  const loadFT = () => {
    setBusy(true);
    getFutureTransaction({ ...trimFilterData(list.filters), ...list.pagination })
      .then(response => {
        setTotalItems(response.length)
        let data = response;
        data = data.map(entry => {
          entry.payout_email = entry.payout_method === ADVCASH ? entry.advcash_payout_email : (entry.payout_method === PAYPAL ? entry.payout_email : "")
          return entry
        })
        setTransactions(data.sort((a, b) => b.created_at - a.created_at))
        setSelectedItems([])
        setFilteredData(data.slice(0, list.pagination.limit))
      }).finally(e => setBusy(false))
  }
  const onSelectItems = items => setSelectedItems(items);
  /**
   * 
   * @param {*} payees 
   */
  const getViewRoute = entry => `/payees/${entry.payee_id}/future-transactions`
  /**
   * 
   * @param entry 
   */
  const handleDelete = entry => {
    return deleteAllPayoutOfPayee(entry.payee_id).then(response => {
      let { batch_id, errors, status } = response;
      if (errors?.length) {
        toast.error(errors.map(error => (error.error || error)).join("\n"))
      }
      if (batch_id !== "null" || !batch_id) {
        toast.success(status);
      }
    })
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Future Transactions'}>
          {(auth.permission || authRoles['post_payout']) ? <Button component={Link} to={`/payouts/create`} variant="contained" color="primary">Add Payout</Button> : null}
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        <div className={classes.selection}>
          {(auth.permission || authRoles['post_pay']) ? <PayButton label="Pay All Selected" disabled={selectedItems.length === 0} futureTransactions={selectedItems.map((item, index) => filteredData[item])} /> : null}
          {(auth.permission || authRoles['delete_payouts']) ? <DeleteButton label="Delete All Selected" disabled={selectedItems.length === 0} futureTransactions={selectedItems.map((item, index) => filteredData[item])} onComplete={loadFT} /> : null}
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            selected={selectedItems}
            onSelectItems={onSelectItems}
            viewRoute={getViewRoute} route={'future-transactions'}
            canView
            canSort={false}
            canDelete={(auth.permission || authRoles['delete_payouts'])}
            rows={filteredData}
            onDelete={handleDelete}
            onCompleteDelete={loadFT}
            deleteConfirmText={"Are you sure? All the payouts inside this transaction will be deleted."}
            columns={columns}
            count={totalItems}
            canSelect={(auth.permission || authRoles['delete_payouts'] || authRoles['post_pay'])} />
        </div>
      </Paper>
    </div>
  );
}
const List = () => <ListProvider><DataTable /></ListProvider>

export default List
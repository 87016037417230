import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '../../../../lib/Table'
import Filters from '../../../../lib/Filters'
import ChipsField from '../../../../components/ui/ChipsField'
import { Link } from 'react-router-dom';
import { deletePermissionUser, getPermissionUsers } from '../../../../business/actions/permissions'
import { getTags } from '../../../../business/actions/tags'
import { useAuth } from '../../../../business/authProvider';
import { useList, ListProvider } from '../../../../business/providers/listProvider'
import { LockOpenRounded, LockRounded } from '@material-ui/icons';
import { trimFilterData } from '../../../../utils';
import { IColumn, IFilter, IPermissionUser, IRole } from '../../../../core';
import PageHeading from '../../../../components/ui/PageHeading';
import { ADVCASH, PAYPAL } from '../../../../utils/vars';
import { getRequestLink } from '../../../../business/actions/requests';
import { toast } from 'react-toastify';
import RichButton from '../../../../components/RichButton';
import { useConfirm } from 'material-ui-confirm';
const columns: Array<IColumn> = [
  { field: 'email', headerName: 'User Email' },
  { field: 'role', headerName: 'Role', formatting: (data: IRole) => data.name }
];
const _filters: Array<IFilter> = [
  { id: 'email', type: 'TextField', label: "User Email" },
  { id: 'roles', type: 'RoleFilter', label: "Roles" }
]
function DataTable() {
  const [permissionUsers, setPermissionUsers] = useState<Array<IPermissionUser>>([])
  const [totalPermissionUsers, setTotalPermissionUsers] = useState<number>(0);
  const [busy, setBusy] = useState<boolean>(false)
  let filterFields: Array<IFilter> = [..._filters];
  let auth = useAuth()
  let list = useList();
  const confirm = useConfirm();
  const authRoles = auth.roles;
  /**
   * 
   */
  useEffect(() => {

    loadPermissionUser();
  }, [list.filters, list.pagination, list.sort])
  /**
   * 
   */
  const loadPermissionUser = () => {
    setBusy(true)
    setPermissionUsers([])
    getPermissionUsers(list.filters).then(json => {
      let { TotalItems, PaginatedItems } = json;
      setTotalPermissionUsers(TotalItems);
      let permissionUsers = PaginatedItems.map((entry: IPermissionUser) => {
        return entry;
      })
      setPermissionUsers(permissionUsers)
    }).finally(e => setBusy(false))
  }
  const onDelete = entry => deletePermissionUser(entry.email)
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'Users'}>
          {(auth.permission || authRoles['post_permissions_user']) ? <Button component={Link} to={'/permissions/users/create'} variant="contained" color="primary">Add User</Button> : null}
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'email'}
            canSelect={false}
            route={'permissions/users'}
            canEdit={(auth.permission || authRoles['patch_permissions_user_email'])}
            canDelete={(auth.permission || authRoles['delete_permissions_user_email'])}
            onDelete={onDelete}
            onCompleteDelete={loadPermissionUser}
            deleteField={"email"}

            canView={false}
            title={'Permission Users'}
            rows={permissionUsers.map(entry => ({ ...entry, mode: (entry.role.name === "unknown" || entry.role.created_at === 0) ? "error" : "" }))}
            canSort={false}
            columns={columns}
            count={totalPermissionUsers}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}


export default ({ }): JSX.Element => <ListProvider><DataTable /></ListProvider>
import React from 'react';
import TextField from '../../theme/components/ui/TextField';
import moment from 'moment'

const TextFieldExtended = (props) => {
  const { onChange } = props;
  const _onChange = event => {
    if (typeof onChange === "function") {
      if (props.type === 'date') {
        return onChange(props.id, moment(event.target.value).unix())
      }
      onChange(props.id, event.target.value)
    }
  }
  const keyPress = (e) => {
    if (e.keyCode == 13) {
      props.onEnterKey && props.onEnterKey()
    }
  }
  return (
    <TextField
      {...props}
      onKeyDown={keyPress}
      onChange={_onChange}
    />
  )
}

export default TextFieldExtended
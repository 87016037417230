
import { get, post, patch, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getTags = (filters) => get('tags', filters).then(responseResult)
/**
 * 
 * @param {*} tag_id 
 * @returns 
 */
export const getTag = (tag_id) => get(`tag/${tag_id}`).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const createTag = (data) => post(`tag`, data)
/**
 * 
 * @param {*} tag_id 
 * @param {*} data 
 * @returns 
 */
export const putTag = (tag_id, data) => patch(`tag/${tag_id}`, data)
//export const deleteTag = tag_id => fetch(`${API}/tag/${tag_id}`, { method: "DELETE", ...options }).then(responseJson)

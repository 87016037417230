
import { get, post, patch, responseResult, del, normaliseTagText } from './api'
import { cleanPayload } from '../../utils'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getPermissionUsers = (filters) => get('permissions/users', filters).then(responseResult)
/**
 * 
 * @param {*} user_id 
 * @returns 
 */
export const getPermissionUser = (email) => get(`permissions/user/${email}`).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 * Create a new user in the system
 */
export const createPermissionUser = (data) => post(`permissions/user`, cleanPayload(data))
/**
 * 
 * @param {*} user_id 
 * @param {*} data 
 * @returns 
 * Update a user in the system
 */
export const putPermissionUser = (email, data) => patch(`permissions/user/${email}`, cleanPayload(data, true))
export const deletePermissionUser = (email) => del(`permissions/user/${email}`)
/**
 * 
 */
export const getPermissionRoles = (filters) => get('permissions/roles', filters).then(responseResult)
/**
 * 
 * @param {*} role_id 
 * @returns 
 */
export const getPermissionRole = (role_id) => get(`permissions/role/${role_id}`).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 * Create a new role in the system
 */
export const createPermissionRole = (data) => post(`permissions/role`, cleanPayload(data))
/**
 * 
 * @param {*} role_id 
 * @param {*} data 
 * @returns 
 * Update a role in the system
 */
export const putPermissionRole = (role_id, data) => patch(`permissions/role/${role_id}`, cleanPayload(data, true))

export const deletePermissionRole = (role_id) => del(`permissions/role/${role_id}`)
/**
 * 
 */
export const getPermissionGroups = (filters) => get('permissions/groups', filters).then(responseResult)
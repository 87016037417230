import React, { useState } from 'react';
import { Button, Grid, Typography, Paper, TextField, CircularProgress } from '@material-ui/core';
import {
  generateByPassThresholdKey
} from '../../../business/actions/settings'
import PageHeading from '../../../components/ui/PageHeading'
import { toast } from 'react-toastify';

export default () => {
  const [api_key, setAPIKey] = useState(null);
  const [generate_progress, setGenerateProgress] = useState(false);
  const generateKey = () => {
    setAPIKey(null)
    setGenerateProgress(true)
    generateByPassThresholdKey()
      .then(response => {
        setAPIKey(response.value);
      })
      .catch(error => toast(error.response.data?.message || 'Unexpected error while generating key'))
      .finally(() => {
        setGenerateProgress(false)
      })
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            disabled={generate_progress}
            endIcon={generate_progress ? <CircularProgress size={12} /> : null}
            onClick={generateKey} variant="contained" color="primary">
            Generate new one-time key and revoke old Key
          </Button>
        </Grid>
        <Grid item xs={12}>
          {api_key ? <TextField disabled value={api_key || ""} /> : null}
        </Grid>
      </Grid>
    </div>
  );
}
export const downloadFromS3 = (url: string | undefined | null, filename: string) => new Promise((resolve, reject) => {
  var xhr: XMLHttpRequest = new XMLHttpRequest();
  xhr.open('GET', url || "", true);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    let xhr = (e.currentTarget as XMLHttpRequest);
    let blob = new Blob([xhr.response], { type: "application/pdf" });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || "invoice.pdf";
    link.click();
    resolve("")
  }
  xhr.onerror = evt => {
    reject('Failed to make request.');
  };
  xhr.send();
})
import { CircularProgress, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    background: '#FFFFFF',
    padding: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    paddingTop: 10
  },
}));

const PaymentProgressModal = ({ open=false, note }) => {
  const classes = useStyles();
  return (<Modal
    open={open}
    className={classes.modal}
    onClose={null}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className={classes.contentWrapper}>
      <div className={classes.content}>
        <CircularProgress />
        <div className={classes.text}>{note || "Please wait while transaction is being processed."}</div>
      </div>
    </div>
  </Modal>
  )
}
export default PaymentProgressModal;
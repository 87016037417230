import List from './List'
import Create from './Create'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';
/*
export default () => {
  const auth = useAuth();
  return (<Switch>
    <Route path={'/tags/list'} component={List} />
    {auth.permission ? <Route path={'/tags/create'} component={Create} /> : null}
    {auth.permission ? <Route path={'/tags/:tag_id/edit'} component={Create} /> : null}
    <Route path={'*'}>
      <Redirect to="/tags/list" />
    </Route>
  </Switch>
  )
}
*/
const TagRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_tags']) ? <Route path={`${url}/list`} component={List} /> : null}
    {(auth.permission || authRoles['post_tag']) ? <Route path={`${url}/create`} component={Create} /> : null}
    {(auth.permission || (authRoles['get_tag_tag_id'] && authRoles['post_tag_tag_id'])) ? <Route path={`${url}/:tag_id/edit`} component={Create} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>)
}

export default TagRoutes
import React, { useEffect, useState } from 'react';
import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import Table from '../../../../lib/Table'
import Filters from '../../../../lib/Filters'
import { useConfirm } from 'material-ui-confirm';
import { getCostManagementInvoicesPDFS, deleteCostManagementInvoicesPDFS } from '../../../../business/actions/invoices-pdfs'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../business/authProvider';
import PageHeading from '../../../../components/ui/PageHeading';
import { convertDateToItalyTime, trimFilterData } from '../../../../utils';
import RichButton from '../../../../components/RichButton';
import { ListProvider, useList } from '../../../../business/providers/listProvider';
import { CMIPDFS, IColumn, IExpensesCategory, IFilter } from '../../../../core';
import { getExpensesCategories } from '../../../../business/actions/expense-categorisation';
import { downloadFromS3 } from '../../../../business/utils';
import { getCommissions } from '../../../../business/actions/commissions';
let columns: Array<IColumn> = [

];
const _filters: Array<IFilter> = [
  { id: 'from_date', type: 'Date', label: "Date From" },
  { id: 'to_date', type: 'Date', label: "Date To" }
]

function DataTable() {
  const auth = useAuth();
  let list = useList();
  const [busy, setBusy] = useState(false)
  const [listData, setData] = useState<Array<{ [key: string]: string }>>([])
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const filterFields = [..._filters];
  const authRoles = auth.roles;

  useEffect(() => {
    loadData()
  }, [list.filters])

  const loadData = () => {
    setBusy(true)
    getCommissions({ ...trimFilterData(list.filters), ...list.pagination, ...list.sort }).then(json => {
      let { TotalItems, Items, TotalAmount } = json;
      setTotalItems(TotalItems);
      setTotalAmount(TotalAmount)
      columns = [];
      let rows: Array<{ [key: string]: string }> = [{}];
      Items.map((entry: { payout_method: string, summary: string }) => {
        columns.push({ field: entry.payout_method, headerName: entry.payout_method })
        rows[0][entry.payout_method] = entry.summary;
      })
      setData(rows)
    }).finally(() => setBusy(false))
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Commissions'}>
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'id'}
            canSelect={false}
            canEdit={false}
            canView={false}
            canDelete={false}
            title={'commissions'}
            customActions={false}
            rows={listData}
            canSort={false}
            columns={columns}
            count={totalItems}
            canPaginate={false}
          />
        </div>
        <div style={{ padding: 16, textAlign: "center", fontWeight: "bold" }}>
          Grand Total {totalAmount || 0}€
        </div>
      </Paper>
    </div>
  );
}

export default ({ }): JSX.Element => <ListProvider><DataTable /></ListProvider>
import { get, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
 export const getHistoryLogs = (filters) => get('manual_payouts', filters).then(responseResult)
 /**
  * 
  * @param {*} batch_id 
  * @returns 
  */
 export const getBatch = (batch_id) => get(`batch/${batch_id}`).then(responseResult)

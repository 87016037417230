import React from 'react';
import { useList } from '../business/providers/listProvider'
import Filters from '../components/Filters'
import { IFilter } from '../core';

interface IFIlterProp {
  data: Array<IFilter>
}

export default (props: IFIlterProp) => {
  const list = useList();
  return (
    <Filters {...props} onUpdateFilter={list.onUpdateFilter} filters={list.filters} />
  )
};
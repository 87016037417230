
import { get, patch, post, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getTransactions = (filters) => get('transactions', filters).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @returns 
 */
export const getTransaction = (transaction_id) => get(`transaction/${transaction_id}`).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @returns 
 */
export const refreshTransaction = (transaction_id) => post(`transaction/${transaction_id}/refresh_status`).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @returns 
 */
export const retryTransaction = (transaction_id) => post(`transaction/${transaction_id}/retry_transaction`).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @returns 
 */
export const archiveTransaction = (transaction_id) => post(`transaction/${transaction_id}/archive`).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @returns 
 */
export const unarchiveTransaction = (transaction_id) => post(`transaction/${transaction_id}/unarchive`).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @param {*} transaction_note 
 * @returns 
 */
export const updateTransactionNotes = (transaction_id, data) => post(`transaction/${transaction_id}/update_note`, data).then(responseResult)
/**
 * 
 * @param {*} transaction_id 
 * @param {*} data 
 * @returns 
 */
export const updateTransactionNeedFixing = (transaction_id, data) => post(`transaction/${transaction_id}/update_need_fixing`, data).then(responseResult)
/**
 * 
 */
export const downloadTransactionInvoices = (data) => get(`transactions/invoices`, data)


import { get, post, responseResult } from './api';
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getBlacklistedPayees = (filters) => get('blacklisted_payees', filters).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const createBlacklistedPayee = (data) => post(`blacklisted_payees`, data)


import { TextField } from "@material-ui/core";
import AutoComplete from '@material-ui/lab/Autocomplete'
import { useEffect, useState } from "react";
import { getPermissionRoles } from "../../business/actions/permissions";
import { IRole } from "../../core";

export interface RoleFilterProp {
  id: string,
  label: string,
  name: string,
  value: string,
  onChange: (id: string, value: string) => void
}

const RoleFilter = (prop: RoleFilterProp): JSX.Element => {
  const [roles, setRoles] = useState<Array<IRole>>([]);
  const [selectedRoles, setSelectedRoles] = useState<Array<IRole>>([]);
  useEffect(() => {
    getPermissionRoles({}).then(json => setRoles(json.PaginatedItems));
  }, [])

  useEffect(() => {
    let value = prop.value.split(",");
    setSelectedRoles( roles.filter(role => value.find(text => role.name === text)) || [])
  }, [roles, prop.value])

  const onChange = (event: any, value: Array<IRole>) => {
    setSelectedRoles(value)
    prop.onChange(prop.id, value.map((entry: IRole) => entry.name).join(","))
  }
  return (
    <AutoComplete
      multiple
      filterSelectedOptions
      name={prop.name}
      key={prop.id}
      onChange={onChange}
      options={roles}
      getOptionLabel={(option: IRole) => option.name}
      renderInput={(params) => <TextField {...params} label={prop.label} margin="normal" variant="outlined" />}
      value={selectedRoles}
    />
  )
}
export default RoleFilter;
import {
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import ToolTip from '../ToolTip';
const MaterialSwitch = ({ label, input, tooltip }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...input}
          color="primary"
        />
      }
      label={<>
        <span>{label}</span>
        {tooltip ? <ToolTip tooltip={tooltip} /> : null}
      </>
      }
    />
  )
}

export default MaterialSwitch
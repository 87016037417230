import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Button,
  CssBaseline,
  Modal,
  Grid,
  TextField as MaterialTextField,
  makeStyles,
  IconButton,
  Switch,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DataGrid from '../../../components/ui/Table'
import {
  getPayeesFutureTransactions,
  deleteAllPayoutOfPayee,
  getPayee, payPayee, putPayee
} from '../../../business/actions/payees'
import { getPayout, deletePayout as deletePayoutByPayoutId, updatePayout, deletePayouts, } from '../../../business/actions/payouts'
import { validateFields, convertDateToItalyTime } from '../../../utils';
import ViewField from '../../../components/ui/ViewField'
import LinkField from '../../../components/ui/LinkField'
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { useAuth } from '../../../business/authProvider';
import ProgressModal from '../../../components/ui/ProgressModal'
import PageHeading from '../../../components/ui/PageHeading'
import NotesField from '../../../components/ui/NotesField'
import { IColumn } from '../../../core';
import PayButton from '../../../components/PayButton';
import { ADVCASH, PAYPAL, TRANSFERWISE } from '../../../utils/vars';
import DownloadInvoice from '../../../components/DownloadInvoice';
import RichButton from '../../../components/RichButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),

  },
  list: {
    height: 260,
    width: 400,
  }
}));

const columns: Array<IColumn> = [
  { field: 'payout_id', headerName: 'Payout Id' },
  { field: 'created_at', headerName: 'Date and Time', formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'payout_amount', headerName: 'Payout Amount', formatting: num => num.toFixed(2) },
  { field: 'payout_currency', headerName: 'Currency' },
  { field: 'description', headerName: 'Description' },
  { field: 'created_by', headerName: 'Created By' },
];

const historyColumns: Array<IColumn> = [
  { field: 'timestamp', headerName: 'Date and Time', formatting: value => convertDateToItalyTime(value) },
  { field: 'error', headerName: 'Details' }
];

export default function View(props) {
  const auth = useAuth();
  /**
   * Classes
   */
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  /**
   * Show the payout, so it can be edited
   */
  const [openModal, showPayout] = useState(false);
  const [payoutBusy, payoutProgress] = useState(false);
  /**
   * payee id from the route
   */
  const { payee_id } = props.match.params;
  /**
   * transactions to show in the list
   */
  const [futureTransaction, setFutureTransaction] = useState({
    created_at: 0
  });
  const [transactions, setTransactions] = useState([])
  const [totalPayout, setTotalAmount] = useState(0)
  /**
   * payout, when edit
   */
  const [payout_id, setPayoutId] = useState(null)
  /**
   * payout details, it will be fetched when user click on edit
   */
  const [payout, setPayout] = useState({})
  /**
   * payee 
   */
  const [payee, setPayee] = useState({
    "payout_email": "",
    "discord": "",
    "payout_method": "",
    "email": "",
    "payee_id": "",
    "user_id": "",
    "last_name": "",
    "pause_payee": false,
    "first_name": "",
    "username": "",
    "transferwise_id": "",
    "tags": [],
    "payee_note": "",
    errors: [],
    "executed_transactions": [],
    "future_transactions": { "number_of_payouts": 0, "payout_amount": "0" }
  });
  /**
   * Selected indexes of payouts
   */
  const [selectedItems, setSelectedItems] = React.useState([]);
  const history = useHistory();
  const confirm = useConfirm();
  const authRoles= auth.roles;
  /**
   * When user tap on edit, we will reset payout id which will make this hook to run the underlined function
   */
  useEffect(() => {
    //First time payout_id will be null
    if (payout_id !== null) {
      payoutProgress(true)
      getPayout(payout_id).then(response => {
        let { payout_id, payout_amount, description } = response
        setPayout({ payout_id, payout_amount, description })
      }).finally(() => payoutProgress(false))
    }
  }, [payout_id])
  /**
   * Fetch future transactions of a payee
   * We will need to show some details of the payee here as well
   */
  useEffect(() => { loadFT(); }, [payee_id]);
  /*
  const renderEditButton = entry => canEdit ? <IconButton to={`/${route}/${entry[idField || "id"]}/edit`} component={Link} aria-label="edit" className={classes.margin}>
    <EditIcon fontSize="small" />
  </IconButton> : null
  const renderViewButton = entry => canView ? <IconButton to={viewRoute ? viewRoute(entry) : `/${route}/${entry[idField || "id"]}/view`} component={Link} aria-label="view" className={classes.margin}>
    <VisibilityIcon fontSize="small" />
  </IconButton> : null
  */
  const getPayoutFee = (data) => {
    switch (data.payout_method) {
      case PAYPAL:
        return `${parseFloat(data.payout_fee) * 100}%`;
      case ADVCASH:
        return `${parseFloat(data.advcash_payout_fee) * 100}%`;
      case TRANSFERWISE:
        return data.transferwise_fee_by_payee ? "By Payee" : "By Blazing Boost";
      default: return "";
    }
  }
  const loadFT = () => {
    getPayeesFutureTransactions(payee_id).then(response => {
      getPayee(payee_id).then(json => setPayee({ ...json, payout_fee: getPayoutFee(json), }));
      setFutureTransaction(response)
      setSelectedItems([])
      let data = response.payouts.map(entry => {
        let customActions = [];
        if (entry.invoice) customActions.push(<DownloadInvoice invoice={entry.invoice} />);
        customActions.push(<IconButton onClick={() => showPayoutDetails(entry.payout_id)} aria-label="edit">
          <EditIcon fontSize="small" />
        </IconButton>);
        if (auth.permission || authRoles['delete_payout_payout_id']) customActions.push(<IconButton onClick={() => deletePayoutById(entry.payout_id)} >
          <DeleteIcon fontSize="small" />
        </IconButton>);
        return ({
          ...entry,
          "payout_currency": "EUR",
          customActions
        })
      })
      if (data.length) {
        setTotalAmount(data.reduce((a, b) => ({ payout_amount: a.payout_amount + b.payout_amount })).payout_amount)
      }
      setTransactions(data)

    })
  }
  /**
   * 
   * @param {*} payout_id 
   * @returns 
   */
  const deletePayoutById = (payout_id: string) => deletePayout([payout_id])
  const deleteSelectedPayouts = () => deletePayout(selectedItems.map(index => transactions[index].payout_id))
  /**
   * 
   * @param {*} payout_id 
   */
  const deletePayout = payout_ids => {
    let description = `Are you sure you want to delete this payout?`;
    if (payout_ids.length === transactions.length) {
      description = `Are you sure you want to delete all payout from the payee ${payee.payee_id}?`
    } else if (payout_ids.length > 1) {
      description = `Are you sure you want to delete ${payout_ids.length} payout?`
    }
    confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description })
      .then(response => {
        if (payout_ids.length === 1) return deletePayoutByPayoutId(payout_ids[0])
        else if (payout_ids.length === transactions.length) return deleteAllPayoutOfPayee(payee.payee_id)
        else return deletePayouts(payout_ids)
        /*return Promise.all(payout_ids.map(async payout_id => {
          await deletePayoutByPayoutId(payout_id)
        }))
        return deletePayouts(payout_ids)
          .then(response => { toast('Payout deleted.'); loadFT(); })
          .catch(error => toast(error.response.data.status.developerErrorMsg))*/
      })
      .then(response => { toast('Payout deleted.'); loadFT(); })
      .catch(error => toast(error.response.data?.message || "Unexpected error"))
  }
  /**
   * TODO:
   * When user submit, we will post the data to api so the payout can be saved
   */
  const onSubmit = (values) => {
    const { description, payout_id, payout_amount, payee_id } = values;
    updatePayout(payout_id, { description, payout_amount, payee_id })
      .then(response => { toast('Payout updated.'); loadFT(); })
      .catch(error => toast(error.response.data?.message || `Payout ${payout_id} is not updated. Unexpected error`))
    showPayout(false)
  }
  /**
   * 
   * @param {*} values 
   * @returns 
   */
  const validate = values => validateFields(values, [])
  /**
   * 
   * @param {*} payout_id 
   */
  const showPayoutDetails = payout_id => { setPayoutId(payout_id); showPayout(true) }
  /**
   * 
   * @returns 
   */
  const handleClose = () => showPayout(false)

  const onChangePausePayee = (event, value) => {
    putPayee(payee_id, { pause_payee: value })
      .then(response => {
        setPayee({ ...payee, pause_payee: value });
        toast("Payee status updated.")
      })
      .catch(error => toast(error.response.data?.message || 'Payee status is not updated. Unexpected error'))
  }
  const onSaveNote = (value) => putPayee(payee_id, { payee_note: value });
  /**
   * #BBPO-63
   * @param {*} items 
   * @returns 
   */
  const onSelectItems = items => setSelectedItems(items)
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={<div>Future Transactions Detail<br />Created at {convertDateToItalyTime(futureTransaction.created_at)}</div>}>
                  <div>
                    {authRoles['post_payout'] ? <Button component={Link} to={`/payouts/create?payee_id=${payee['payee_id']}`} style={{ marginRight: 8 }} variant="contained" color="primary">Add Payout</Button> : null}
                    {authRoles['post_pay'] ? <PayButton label="Pay" disabled={busy || payee?.pause_payee === true} futureTransactions={[{ payee_id, payout_amount: totalPayout }]} /> : null}
                  </div>
                </PageHeading>
                <Divider />
                <div style={{ padding: 16 }}>
                  <ViewField type="chips" record={payee} source="tags" labelField={"text"} label="Tags" />
                  <LinkField record={payee} source="payee_id" label="Payee ID" link={`/payees/${payee['payee_id']}/view`} />
                  <ViewField record={payee} source="username" label="Payee Username" />
                  {
                    (auth.permission || authRoles['patch_payee_payee_id']) ?
                      <ViewField label="Payee Note">
                        <NotesField
                          defaultValue={payee.payee_note}
                          saveAction={onSaveNote}
                          successMessage={'Payee note saved.'}
                          errorMessage={'Payee note is not saved. Unexpected error'}
                        />
                      </ViewField>
                      :
                      <ViewField record={payee} source="payee_note" label="Payee Note" />
                  }
                  <ViewField record={payee} source="payout_method" label="Payout Method" />
                  {
                    payee.payout_method === PAYPAL ?
                      <ViewField record={payee} source="payout_email" label="Payout Email" />
                      : payee.payout_method === ADVCASH ?
                        <ViewField record={payee} source="advcash_payout_email" label="Payout Email" />
                        : payee.payout_method === TRANSFERWISE ?
                          <ViewField record={payee} source="transferwise_id" label="TransferWise ID" />
                          :
                          null
                  }

                  <ViewField record={payee} source="payout_fee" label="Payout Fee" />
                  <ViewField label="Payee Pause Status" tooltip='This is the Payee status. When the status is turned on it means that the Payee is Paused and payments can not be sent to this payee until his status is changed back = unpaused.'>
                    <div>
                      <Switch disabled={!(auth.permission || authRoles['patch_payee_payee_id'])} onChange={onChangePausePayee} checked={payee['pause_payee']} color="primary" />
                      <span>
                        {payee['pause_payee'] ? 'Payee is paused, click to unpause.' : 'Payee is not paused, click to pause.'}
                      </span>
                    </div>
                  </ViewField>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <PageHeading title={'Payouts'}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <div>
                      EUR: <span style={{ fontWeight: 'bold' }}>{totalPayout.toFixed(2)}</span>
                    </div>
                    {(auth.permission || authRoles['delete_payouts']) ? <Button disabled={selectedItems.length === 0} onClick={deleteSelectedPayouts} style={{ marginTop: 8 }} variant="contained" color="primary">Delete Selected</Button> : null}
                  </div>
                </PageHeading>
                <Divider />
                <div style={{ padding: '0px 0px 16px' }}>
                  <DataGrid
                    selected={selectedItems}
                    onSelectItems={onSelectItems}
                    pagination={false}
                    extraColumns={false}
                    canSelect={true}
                    rows={transactions}
                    columns={columns}
                    pageSize={5}
                    checkboxSelection
                    canSort={false}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper>
            <PageHeading title={'Full error history for this payee'} />
            <Divider />
            <div style={{ padding: 16 }}>
              <DataGrid
                pagination={false}
                extraColumns={false}
                actions={false}
                canSelect={false}
                canSort={false}
                rows={payee?.errors || []} columns={historyColumns}
                pageSize={payee?.errors?.length || 0}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper>
          <PageHeading title={'Edit Payout'}>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </PageHeading>
          <Divider />
          <div style={{ padding: 16 }}>
            {payoutBusy ?
              <div style={{ textAlign: 'center' }}><CircularProgress /></div> :
              <div className={classes.list}>
                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    payout_amount: null,
                    payee_id,
                    description: "",
                    ...payout
                  }}
                  validate={validate}
                  render={({ handleSubmit, reset, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12} gutter>
                          <Field disabled name="payout_id" fullWidth required component={TextField} type="text" label="Payout Id" />
                        </Grid>
                        <Grid item xs={12}>
                          <Field disabled={!(auth.permission || authRoles['patch_payout_payout_id'])} name="payout_amount" fullWidth required component={TextField} type="text" label="Payout Amount" />
                        </Grid>
                        <Grid item xs={12}>
                          <Field disabled={!(auth.permission || authRoles['patch_payout_payout_id'])} name="description" fullWidth required component={TextField} type="text" label="Description" />
                        </Grid>
                        {(auth.permission || authRoles['patch_payout_payout_id']) ?
                          <Grid item style={{ marginTop: 16 }}>
                            <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                              Submit
                            </Button>
                          </Grid>
                          : null
                        }
                      </Grid>
                    </form>
                  )}
                />
              </div>
            }
          </div>
        </Paper>
      </Modal>
      <ProgressModal open={busy} />
    </div>
  );
}
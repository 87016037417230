import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { payPayee } from '../business/actions/payees'
import { Button, CircularProgress, IconButton, makeStyles, Modal, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DescriptionIcon from '@material-ui/icons/Description';
import { IColumn, IFutureTransaction, IPayee } from '../core/index'
import { useAuth } from '../business/authProvider';
import { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close'
import { getTransaction } from '../business/actions/transactions';
import { convertDateToItalyTime } from '../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataGrid from './ui/Table'
interface INoteButton {
  data: object,
}
const columns: Array<IColumn> = [
  { field: 'admin_name', headerName: 'Admin Name' },
  { field: 'transaction_note', headerName: 'Note Text', style: { wordBreak: "break-all" } },
  { field: 'timestamp', headerName: 'Date and Time', formatting: timestamp => convertDateToItalyTime(timestamp), style: { width: 170 } }
];
export default ({
  data,
}: INoteButton): JSX.Element | null => {
  const classes = useStyles();
  const [open, showModal] = useState(false)
  const [busy, setBusy] = useState(true)
  const [listItems, setListItems] = useState([])
  const [notes, setTransactionNotes] = useState([])
  /**
   * 
   */
  useEffect(() => setListItems(notes.slice(0, 10)), [notes])
  /**
   * 
   */
  const fetchData = () => {
    let currentIndex = listItems.length;
    setListItems(listItems.concat(notes.slice(currentIndex, currentIndex + 5)));
  }
  /**
   * 
   * @returns 
   */
  const toggleModal = () => showModal(!open);
  const onClick = () => {
    toggleModal()
    getTransaction(data.transaction_id).then(response => {
      setTransactionNotes(response.transaction_notes.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1))
    }).finally(() => setBusy(false))
  }

  return data.transaction_note ? (
    <>
      <IconButton onClick={onClick}><DescriptionIcon fontSize="small" /></IconButton>
      <Modal
        open={open}
        className={classes.modal}
        onClose={toggleModal}
      >
        <div className={classes.paper}>
          {busy ?
            <CircularProgress />
            : <>
              <div className={classes.modalHeader}>
                <Typography variant="h6" component="div" className={classes.modalTitle}>Notes</Typography>
                <IconButton onClick={toggleModal}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              {listItems.length === 0 ?
                <div className={classes.list}>No notes found!</div>
                :
                <div className={classes.list}>
                  <InfiniteScroll
                    dataLength={listItems.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={true}
                    height={380}
                  >
                    <DataGrid
                      canSort={false}
                      pagination={false}
                      extraColumns={false}
                      canSelect={false}
                      rows={listItems}
                      columns={columns}
                      pageSize={listItems.length}
                    />
                  </InfiniteScroll>
                </div>
              }
            </>}
        </div>
      </Modal>
    </>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.3rem",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 21,.5)",
    borderStyle: "solid"
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: "#D8DBE0",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    padding: theme.spacing(1)
  },
  modalTitle: {
    fontSize: 16,
    color: "rgba(44,56,74,.95)"
  },
  list: {
    minWidth: 600,
    maxWidth: 1000,
    maxHeight: 400,
    minHeight: 400,
    padding: theme.spacing(1)
  }
}));

import { get, post, del, patch, responseResult } from './api'
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const getRequests = (data) => get(`payout_requests`, data)
/**
 * 
 */
export const fakeAcceptAndSendRequest = (request_id) => post(`payout_request/${request_id}/fake_accept_send`)
/**
 * 
 */
export const acceptAndListRequest = (request_id) => post(`payout_request/${request_id}/accept`)
/**
 * 
 */
export const acceptAndSendRequest = (request_id) => post(`payout_request/${request_id}/accept_send`)
/**
 * 
 */
export const deleteRequest = (request_id) => del(`payout_request/${request_id}`)
/**
 * 
 */
export const getRequestLink = (payee_id) => get(`payout_request_link/${payee_id}`);
import React, { useEffect, useRef } from 'react'

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useTheme,
  Button,
  Collapse
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import ExpandLessIcon from '@material-ui/icons/ExpandLessSharp';
import { IRoute } from '../../core/interfaces/Menu';
import getMenuItem from './GetMenuItem';
import { useLocation } from 'react-router-dom';

const useMenuStyle = makeStyles((theme) => ({
  menuItemSelected: {
    fontWeight: "bold"
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default (props: IRoute) => {
  const classes = useMenuStyle();
  const { pathname } = useLocation();
  const [openCollapse, setOpenCollapse] = React.useState(pathname.match(props.selected)?.length >= 1 || false);

  useEffect(() => {
    if (openCollapse !== (pathname.match(props.selected)?.length >= 1 || false)) {
      setOpenCollapse(!openCollapse)
    }
  }, [pathname])

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  return (
    <>
      <ListItem button onClick={handleOpenSettings}>
        <ListItemText disableTypography={true} primary={props.label} style={{ paddingLeft: 8 * props.level }} />
        {openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div">
          {props.data?.map(getMenuItem)}
        </List>
      </Collapse>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import PayeeForm from '../../../components/forms/Payee'
import Autocomplete from '../../../components/ui/AutoComplete'
import PageHeading from '../../../components/ui/PageHeading'
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import { getPayee, putPayee, createPayee, archivePayee } from '../../../business/actions/payees'
import { getTags } from '../../../business/actions/tags'
import { validateFields } from '../../../utils'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { TPayee } from '../../../core/types';
import { ITag } from '../../../core';
import { ADVCASH, PAYPAL, TRANSFERWISE, PAYONEER, REVOLUT } from '../../../utils/vars';
const testData = {
  "tags": [],
  "username": "sameerjain",
  "payee_id": "payee-2",
  "email": "sameer.jain@saachitech.com",
  "payout_method": "Paypal",
  "payee_note": "This is a test payee",
  "pause_payee": true,
}
export default function Create(props) {
  const history = useHistory();
  const confirm = useConfirm();
  /**
   * We might be editing a payee or creating new payee. THis could be identify with thie payee_id param
   */
  const payee_id = props.match.params?.payee_id || null
  /**
   * This will be populated with the payee details if we are editing any payee
   */
  const [data, setData] = useState<TPayee>()
  /**
   * Tags
   */
  const [tagOptions, setTags] = useState<ITag[]>([])
  /**
   * If we are editing a payee, lets fetch the details of the payee
   */
  useEffect(() => {
    if (payee_id) getPayee(payee_id).then(json => {
      let {
        tags, username, payee_id, first_name, last_name, email, advcash_payout_fee,
        discord, payout_method, payout_email, transferwise_id, payee_note, pause_payee,
        transferwise_fee_by_payee, advcash_payout_email, archived, created_at, is_italian, payout_fee,
        send_regular_payout_request_link, optional_payout_email, revolut_id
      } = json;
      setData({
        tags: tags.map((tag: ITag) => tag.text), username, payee_id, first_name, last_name, email,
        discord, payout_method, payout_email, transferwise_id, payee_note, pause_payee,
        transferwise_fee_by_payee: transferwise_fee_by_payee ? "true" : "false", is_italian,
        advcash_payout_email, archived, created_at,
        payout_fee: payout_fee.toString(),
        advcash_payout_fee: advcash_payout_fee.toString(),
        send_regular_payout_request_link, optional_payout_email, revolut_id
      })
    });
    getTags({}).then(json => {
      //let { TotalItems, PaginatedItems } = json;
      setTags(json)
    }).catch(e => {/** */ });
  }, [payee_id])
  /**
   * 
   * @param {*} values 
   * This function execute by the react-final-form library
   * We decide here wheather it's an update of a payee or new payee
   */
  const onSubmit = (values) => {
    let { payout_email, optional_payout_email, send_regular_payout_request_link, advcash_payout_email, advcash_payout_fee, tags, username, first_name, last_name, email, discord, payout_method, payout_fee, payee_note, pause_payee, is_italian, transferwise_fee_by_payee, revolut_id } = values;
    let payeeData: TPayee = { tag_ids: tags.map((tag: string) => tagOptions.find(o => o.text === tag)?.id), username, first_name, last_name, email, discord, payout_method, payout_fee, payee_note, pause_payee, is_italian, send_regular_payout_request_link };
    switch (payout_method) {
      case TRANSFERWISE:
        payeeData.transferwise_fee_by_payee = transferwise_fee_by_payee === "true";
        payeeData.transferwise_id = values.transferwise_id
        break;
      case PAYPAL:
        payeeData.payout_email = payout_email;
        payeeData.payout_fee = payout_fee;
        break;
      case REVOLUT:
        payeeData.revolut_id = revolut_id;
        break
      case ADVCASH:
        payeeData.advcash_payout_email = advcash_payout_email;
        payeeData.advcash_payout_fee = advcash_payout_fee
        break;
    }
    if (send_regular_payout_request_link) {
      payeeData.optional_payout_email = optional_payout_email;
    }
    /**
     * If payee_id exist, that means we are updating a payee, hence we need to call put method
     */
    if (payee_id) {
      putPayee(payee_id, payeeData)
        .then(response => { toast('Payee updated.'); setTimeout(history.goBack, 1000) })
        .catch(error => toast(error.response.data?.message || "Unexpected error"))
    }
    else {
      payeeData.payee_id = values.payee_id;
      createPayee(payeeData)
        .then(response => { toast('Payee created.'); setTimeout(history.goBack, 1000) })
        .catch(error => toast(error.response.data?.message || "Unexpected error"))
    }
    return true;
  }
  const onArchive = () => confirm({ title: "", confirmationText: "Yes", cancellationText: "No", description: `Are you sure you want to archive payee ${payee_id}?` })
    .then(response => archivePayee(payee_id)
      .then(response => { toast('Payee archived.'); setTimeout(history.goBack, 1000) })
      .catch(error => toast(error.response.data?.message || "Payee is not archived. Unexpected error"))
    ).catch(() => { /* ... */ });
  const validate = values => validateFields(values, ["payee_id",
    "email",
    "payout_method",
    "tags",])
  return (
    <div style={{ height: '100%', width: '100%', maxWidth: 650, }}>
      <div style={{ margin: 'auto' }}>
        <CssBaseline />
        <Paper>
          <PageHeading title={`${payee_id ? 'Edit' : 'Create'} Payee`} />
          <Divider />
          <PayeeForm mode={payee_id ? 'edit' : 'add'} validate={validate} onSubmit={onSubmit} onArchive={onArchive} formData={data} data={{ tagOptions: tagOptions.map(tag => tag.text) }} />
        </Paper>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import {
  Button, Typography, Paper, Divider,
  Table, TableHead, TableBody, TableCell, TableRow, Tabs, Tab,
  List, ListItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: '100%'
  }
}));

export default function Instructions() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0)
  const handleChange = (event, index) => {
    setTabIndex(index)
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <div style={{ padding: 16 }}>
          <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Guides/Definition" />
            <Tab label="Definitions" />
            <Tab label="Practical example" />
            <Tab label="FAQ" />
          </Tabs>
          <div style={{ padding: 16 }}>
          </div>
          {tabIndex === 0 ?
            <>
              <List>
                <ListItem>
                  This is the new system used to send payments in BlazingBoost.
                </ListItem>
                <ListItem>
                  <img className={classes.image} src={'/assets/media/images/instruction1.png'} alt={''} />
                </ListItem>
              </List>
            </>
            : tabIndex === 1 ?
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 150 }}>Term</TableCell>
                      <TableCell>Defination</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Service</TableCell>
                      <TableCell>the envelope where we put prices, descriptions etc. Services get bought by customers.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order Item</TableCell>
                      <TableCell>The sale of a specific service, identified with the so-called ‘service ID’ S21ABC123.... Order items are the result of customers’ purchases and are completed by boosters.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payout</TableCell>
                      <TableCell>the payment for any activity or action at Blazing. Can be for ONE order item, or for support, or for anything that Blazing has to pay to someone (also known as Payee).</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payee</TableCell>
                      <TableCell>person receiving a Payout. Gets automatically created when a new booster is added on the website. Payees can also manually be added (such as Supporters, for example). Payees can have tags.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payout ID</TableCell>
                      <TableCell>the UNIQUE (it has to be unique!) ID of each single payout. For example, an order item completed by two different players will result in two different payout IDs, to two different payees. The payout ID is usually (for order items, at least)  the service ID plus “1” or “2” etc.Example: S21ABC123-1   and S21ABC123-2 can be the two payout IDs of the same order item being sent to two players. </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payout Description</TableCell>
                      <TableCell>Albeit slightly redundant with the concept of Payout ID, the description gives more details about what we are paying that person for. As an example, the payout ID can be  “S21ABC123-2” and the description can be “Payment for S21ABC123-2 – up to 1800 rating with 20% penalty”. </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Transaction</TableCell>
                      <TableCell>The total sum of all the payouts and payout IDs that have been sent to a payee. Corresponds to the old concept ‘payment of 100€ for order item  X (50€) Y (20€) Z (30€)’.  Transactions have an ID that will start with “BBT”. Payees will receive an email from Paypal with ONLY the “BBT” in the note. Payees will, however, receive a secondary email </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Future Transactions</TableCell>
                      <TableCell>The total sum of all the payouts and payout IDs that were not sent yet to a payee. Corresponds to the old ‘listed orders on the payments sheet’ </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Batch</TableCell>
                      <TableCell>the total sum of Transactions that were sent to a group of payees.  Corresponds to the old ‘Mass Payment file’ concept.   Batches have an ID that starts with “BBB”. </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payee Status</TableCell>
                      <TableCell>
                        <div>Allows pause for blacklisted boosters.</div>
                        <div>paused = the order will get listed, but the future transaction will not be payable and will remain there, and if admin tries to pay the error will be shown</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>BlazingBoost Batch ID</TableCell>
                      <TableCell>This is the internal code that we use to track a mass payment. A Batch ID contains a Transaction ID per booster. Each transaction ID contains Payout IDs, which corresponds to the order items like S21ABC123 etc. When a batch is executed, Paypal Transaction IDs and more will be generated.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>BlazingBoost full transaction ID</TableCell>
                      <TableCell>This is an internal code that we use to track this unique payment that was sent to this payee.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Paypal batch ID</TableCell>
                      <TableCell>If we complete a payment through Paypal using the Mass Payment system, Paypal assigns an ID to the mass Payment (which corresponds, in our language, to a batch). This is what this ID corresponds to.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Paypal Payout ID</TableCell>
                      <TableCell>This is the code that the payee will receive on Paypal when he gets paid. We also show this ID because occasionally payees see different transaction IDs on their Paypal as opposed to what we see.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Paypal Transaction ID</TableCell>
                      <TableCell>This is the code that identifies the single payment through Paypal to a payee (even if made through a Mass Payment) on our side. We always see this code and it corresponds to the code we show on our sold order items when customers buy something on Blazingboost.</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Archived payee</TableCell>
                      <TableCell>
                        <div>
                          When a Payee is archived he is only shown on the “Archived Payees” list page. All data of the payee is saved but he can not be paid until he is "Unarchived".
                        </div>
                        <div>
                          archived = the order will get listed but it won't be visible inside future transactions because payee is in the archived list
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Invoice Email</TableCell>
                      <TableCell>This is an email that is sent to the Payee Email with information about the all Payouts that are sent to him on a specific date in a specific batch.</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </>
              : tabIndex === 2 ?
                <>
                  <div>
                    <List>
                      <ListItem>
                        Paying a supporter for support + three order items + a side marketing task would let his Transaction look like:
                      </ListItem>
                    </List>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Payout Id</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Description</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Support</TableCell>
                          <TableCell>10</TableCell>
                          <TableCell>June</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>S21ABC123-1</TableCell>
                          <TableCell>5</TableCell>
                          <TableCell>Payout for order item S21ABC123-1</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>S21KJD345-2</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>Payout for order item S21KJD345-2</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>S21NDS483-1</TableCell>
                          <TableCell>3</TableCell>
                          <TableCell>Payout for order item S21NDS483-1</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Marketing</TableCell>
                          <TableCell>8</TableCell>
                          <TableCell>Newsletters</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Bonus</TableCell>
                          <TableCell>5</TableCell>
                          <TableCell>  Green Card for Good Coaching Performance</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <List>
                      <ListItem>
                        The sum of these payout IDs + the amounts would create a Transaction (Future Transaction if not sent yet) and a Transaction ID.
                      </ListItem>
                      <ListItem>
                        The second order item saying S21KJD345-2 says “2” because maybe he is the second player being paid for that order item.
                      </ListItem>
                      <ListItem>
                        There must certainly be someone else who has ever been paid for that order item and the Payout ID must have said, in that occasion, S21KJD345-1.
                      </ListItem>
                      <ListItem>
                        If there is an order item with at least one “Check” {'&'} “Zero” trigger in the Payout Description, the transaction line will be highlighted in the Future Transaction list with a warning icon. Payout IDs will not contain Check / Zero, but Descriptions will.
                      </ListItem>
                      <ListItem>
                        If the booster wants to know what he has been paid for, he has to check the email received by our Payout admin system that will give him the list of payout IDs, amounts, and descriptions. The paypal email will only say the Transaction ID in the paypal note.
                      </ListItem>
                    </List>
                  </div>
                </>
                : tabIndex === 3 ?
                  <>
                    <List>
                      <ListItem>
                        <Typography variant="h6">
                          How to search an order item if a booster complains about it not having been paid?
                        </Typography>
                      </ListItem>
                      <ListItem>
                        Admin has to check in Future Transactions {'&'} in Transactions pages (maybe it has only been listed or actually listed+paid) for the Payout ID through the Payout ID filter. The Payout ID will always contain the order item ID (S21....) so that’s how we can check if it was sent, if it wasn’t sent, and on which day, with which transaction.
                      </ListItem>
                    </List>
                  </>
                  : null
          }
        </div>
      </Paper>
    </div>
  );
}
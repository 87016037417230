import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { toast } from "react-toastify";
import {
  Paper,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { validateFields } from '../../../utils'
import PageHeading from '../../../components/ui/PageHeading'
import { createBlacklistedPayee } from '../../../business/actions/blacklisted-payees';
export default function Create(props) {
  const history = useHistory()
  const query = new URLSearchParams(props.location.search);
  const onSubmit = async values => {
    createBlacklistedPayee(values)
      .then(response => { history.goBack(); toast('Blacklisted email added.'); })
      .catch(error => toast(error.response.data?.message || "Blacklisted email is not added. Unexpected error"))
  };
  const validate = values => validateFields(values, ["email"])
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper>
            <PageHeading title={`Add Blacklisted Email`} />
            <Divider />
            <Form
              onSubmit={onSubmit}
              initialValues={{
                email: '',
                reason: '',
              }}
              validate={validate}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Paper style={{ padding: 16 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Field name="email" fullWidth required component={TextField} type="text" label="Email" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="reason" fullWidth component={TextField} type="text" label="Reason" />
                      </Grid>
                      <Grid item style={{ marginTop: 16 }}>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => form.reset()}
                          disabled={submitting || pristine}
                        >
                          Reset
                        </Button>
                      </Grid>
                      <Grid item style={{ marginTop: 16 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
import {
  Typography,
} from '@material-ui/core';
interface IPageHeadingProps {
  title: any,
  children?: any
}
const PageHeading = ({ title, children }: IPageHeadingProps) => <div style={{ display: 'flex', justifyContent: 'space-between', padding: 16, alignItems: 'center' }}>
  <Typography variant="body2" id="tableTitle" component="div" gutterBottom>{title}</Typography>
  {children}
</div>
export default PageHeading;
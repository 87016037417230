import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select, Radio } from 'final-form-material-ui';
import {
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';

const CompanyBillingForm = ({
  validate,
  onSubmit,
  formData,
  mode
}) => <Form
    onSubmit={onSubmit}
    initialValues={{
      company_name: "",
      company_address: "",
      vat_number: "",
      ...formData
    }}
    validate={validate}

    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit} noValidate>
        <FormControl>
          <div style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field name="company_name" fullWidth component={TextField} type="text" label="First Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="company_address" fullWidth required component={TextField} type="text" label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="vat_number" fullWidth component={TextField} type="text" label="Address" />
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button type="button" variant="contained" onClick={() => form.reset()} disabled={submitting || pristine}>
                  Reset
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                  {mode === 'edit' ? 'Update' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </FormControl>
      </form>
    )}
  />



export default CompanyBillingForm
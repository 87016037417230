import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import DataGrid from '../../../lib/Table'
import Filters from '../../../lib/Filters'
import { getBatches } from '../../../business/actions/batches'
import { getTags } from '../../../business/actions/tags';
import { convertDateToItalyTime, trimFilterData } from '../../../utils'
import { ListProvider, useList } from '../../../business/providers/listProvider';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
const columns: Array<IColumn> = [
  { field: 'batch_id', headerName: 'Batch ID' },
  { field: 'datetime', headerName: 'Date and Time' },
  { field: 'batch_status', headerName: "Batch Status" },
  { field: 'number_transactions', headerName: 'Transactions (Amount of people paid)' },
  { field: 'total_amount', headerName: 'Total Amount', formatting: num => num.toFixed(2) },
  { field: 'sent_by', headerName: 'Sent By' },
];

const _filters: Array<IFilter> = [
  { id: 'batch_id', type: 'TextField', label: "Batch ID" },
  { id: 'from_date', type: 'Date', label: "Date From" },
  { id: 'to_date', type: 'Date', label: "Date To" },
  { id: 'sent_by', type: 'TextField', label: "Sent By" },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
]
function DataTable() {
  const list = useList();
  const [busy, setBusy] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [listData, setListData] = useState([]);
  const filterFields = [..._filters];
  /**
   * 
   */
  useEffect(() => {
    setListData([]);
    loadData();
  }, [list.filters, list.pagination, list.sort])

  const loadData = () => {
    setBusy(true)
    getBatches({ ...trimFilterData(list.filters), ...list.pagination, ...list.sort })
      .then(json => {
        let { TotalItems, PaginatedItems } = json;
        setTotalItems(TotalItems);
        setListData(PaginatedItems.map(entry => ({
          ...entry,
          number_transactions: entry.transaction_ids?.length || 0,
          datetime: convertDateToItalyTime(entry.created_at),
        })))
      }).finally(e => setBusy(false))
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'Batches: List of groups of transactions. Corresponds to a CSV file that we uploaded in the past'}></PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <DataGrid
            idField={'batch_id'}
            canView={true}
            route={'batches'}
            title={'Batches'}
            canSort={false}
            rows={listData}
            columns={columns}
            count={totalItems}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}
const List = () => <ListProvider><DataTable /></ListProvider>
export default List
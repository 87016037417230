import React, { useEffect, useState } from 'react';
import { Button, Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import Table from '../../../../lib/Table'
import Filters from '../../../../lib/Filters'
import { useConfirm } from 'material-ui-confirm';
import { getCostManagementInvoicesPDFS, deleteCostManagementInvoicesPDFS } from '../../../../business/actions/invoices-pdfs'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../business/authProvider';
import PageHeading from '../../../../components/ui/PageHeading';
import { convertDateToItalyTime, trimFilterData } from '../../../../utils';
import RichButton from '../../../../components/RichButton';
import { ListProvider, useList } from '../../../../business/providers/listProvider';
import { CMIPDFS, IExpensesCategory, IFilter } from '../../../../core';
import { getExpensesCategories } from '../../../../business/actions/expense-categorisation';
import { downloadFromS3 } from '../../../../business/utils';
const columns = [
  { field: 'created_at', headerName: 'Date of upload', formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'name', headerName: "Name" },
  { field: 'amount', headerName: "Amount" },
  { field: 'expense_categorization', headerName: "Categorization" }
];
const _filters: Array<IFilter> = [
  { id: 'name', type: 'TextField', label: "invoice Name" },
  { id: 'from_date', type: 'Date', label: "Date From" },
  { id: 'to_date', type: 'Date', label: "Date To" },
  { id: 'category_ids', type: 'CategorizationFilter', label: "Expenses Categories", multipleSelect: true, style: { minWidth: 200 } }
]

function DataTable() {
  const auth = useAuth();
  let list = useList();
  const confirm = useConfirm();
  const [busy, setBusy] = useState(false)
  const [listData, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expensesCategories, setExpensesCategories] = useState<Array<IExpensesCategory>>([]);

  const filterFields = [..._filters];
  const authRoles = auth.roles;

  useEffect(() => {
    setBusy(true)
    getExpensesCategories({})
      .then((expensesCategories: Array<IExpensesCategory>) => setExpensesCategories(expensesCategories))
  }, [])

  useEffect(() => {
    if (expensesCategories.length > 0) loadData()
  }, [expensesCategories, list.filters, list.pagination, list.sort]);

  const loadData = () => {
    setBusy(true)
    //
    let applied_filters = { ...list.filters }
    if (applied_filters.category_ids) {
      applied_filters.category_ids = applied_filters.category_ids.split(",").map(item => expensesCategories.find(c => c.text === item).id).join(",")
    }
    //
    getCostManagementInvoicesPDFS({ ...trimFilterData(applied_filters), ...list.pagination, ...list.sort }).then(json => {
      let { TotalItems, PaginatedItems, TotalAmount } = json;
      setTotalItems(TotalItems);
      setTotalAmount(TotalAmount)
      //
      PaginatedItems = PaginatedItems.map(item => {
        item.expense_categorization = expensesCategories.find(c => c.id === item.expense_categorization_id).text;
        return item;
      })
      //
      setData(PaginatedItems.sort((a, b) => b.created_at - a.created_at))
    }).finally(e => setBusy(false))
  }

  const downloadInvoice = (data: CMIPDFS) => downloadFromS3(data.invoice,  data.original_file_name || "invoice.pdf")
 
  const handleDelete = entry => deleteCostManagementInvoicesPDFS(entry.id)

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Invoices and PDF Storage'}>
          {(auth.permission || authRoles['post_invoice']) ?
            <Button component={Link} to={'/costsmanagement/invoices/create'} variant="contained" color="primary">
              Add New Invoice
            </Button>
            : null
          }
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            idField={'id'}
            canSelect={false}
            route={'costsmanagement/invoices'}
            canEdit={false}
            canView={false}
            title={'Invoices and PDF Storage'}
            customActions={true}
            rows={listData.map(entry => ({
              ...entry,
              customActions: [<RichButton
                type='IconButton'
                icon='CloudDownload'
                action={downloadInvoice}
                data={entry}
                iconColor={"inherit"}
                toolTip="Download Invoice"
              />,<RichButton
                type='IconButton'
                icon='Delete'
                action={handleDelete}
                data={entry}
                iconColor={"inherit"}
                onComplete={loadData}
                waitBeforeTriggerComplete={1000}
                shouldConfirm={true}
                confirmText="Are you sure you want to delete this invoice?"
                toolTip="Delete Invoice"
              />]
            })
            )}
            canSort={false}
            columns={columns}
            count={totalItems}
            onDelete={handleDelete}
            checkboxSelection />

        </div>
        <div style={{ padding: 16, textAlign: "center", fontWeight: "bold" }}>
          Total {totalAmount}€
        </div>
      </Paper>
    </div>
  );
}

export default ({ }): JSX.Element => <ListProvider><DataTable /></ListProvider>
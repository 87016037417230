import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  Link
} from "react-router-dom";
import { useAuth } from '../../../../business/authProvider';
import React, { useEffect, useState } from 'react';
import { IconButton, Divider, Paper } from "@material-ui/core";
import PageHeading from "../../../../components/ui/PageHeading";
import ViewField from "../../../../components/ui/ViewField";
import { Auth } from "aws-amplify";
import ToolTip from "../../../../components/ToolTip";
import SecurityIcon from '@material-ui/icons/Security';

const Profile = () => {
  let { url } = useRouteMatch();
  const [profile, setProfileInfo] = useState<{ [key: string]: any } | undefined>(undefined)
  useEffect(() => {
    getUserInfo()
  }, [])
  const getUserInfo = async () => {
    let userInfo = await Auth.currentUserInfo();
    setProfileInfo({ email: userInfo.attributes.email })
  }
  return (<div style={{ height: 400, width: '100%' }}>
    <Paper>
      <PageHeading title={'Your Information'}>
        <div>
          <ToolTip tooltip={"Update Password"}>
            <IconButton component={Link} to={`${url}/updatePassword`}>
              <SecurityIcon fontSize={"small"} style={{ color: "inherit" }} />
            </IconButton>
          </ToolTip>
        </div>
      </PageHeading>
      <Divider />
      <div style={{ padding: 16 }}>
        <ViewField record={profile} source="email" label="Current Email" />
        <ViewField record={profile} source="account_name" label="Account Name" />
        <ViewField record={profile} source="account_accesss" label="Account Role" />
      </div>
    </Paper>
  </div>)
}

export default Profile
import List from './List'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../../business/authProvider';
import React from 'react';
/*
export default () => {
  const auth = useAuth();
  return (<Switch>
    <Route path={'/tags/list'} component={List} />
    {auth.permission ? <Route path={'/tags/create'} component={Create} /> : null}
    {auth.permission ? <Route path={'/tags/:tag_id/edit'} component={Create} /> : null}
    <Route path={'*'}>
      <Redirect to="/tags/list" />
    </Route>
  </Switch>
  )
}
*/
const CommissionsRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles = auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_commissions']) ? <Route path={`${url}/list`} component={List} /> : null}
    <Route path={'*'}>
      <Redirect to={`${url}/list`} />
    </Route>
  </Switch>)
}

export default CommissionsRoutes
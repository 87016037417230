import { TextField } from "@material-ui/core";
import AutoComplete from '@material-ui/lab/Autocomplete'
import { useEffect, useState } from "react";
import { getTags } from "../../business/actions/tags";
import { ITag } from "../../core";

export interface TagFilterProp {
  id: string,
  label: string,
  name: string,
  value: string,
  onChange: (id: string, value: string) => void
}

const TagFilter = (prop: TagFilterProp): JSX.Element => {
  const [tags, setTags] = useState<Array<ITag>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<ITag>>([]);
  useEffect(() => {
    getTags({}).then((tags: Array<ITag>) => setTags(tags));
  }, [])

  useEffect(() => {
    let value = prop.value.split(",");
    setSelectedTags( tags.filter(tag => value.find(text => tag.text === text)) || [])
  }, [tags, prop.value])

  const onChange = (event: any, value: Array<ITag>) => {
    setSelectedTags(value)
    prop.onChange(prop.id, value.map((entry: ITag) => entry.text).join(","))
  }
  return (
    <AutoComplete
      multiple
      filterSelectedOptions
      name={prop.name}
      key={prop.id}
      onChange={onChange}
      options={tags}
      getOptionLabel={(option: ITag) => option.text}
      renderInput={(params) => <TextField {...params} label={prop.label} margin="normal" variant="outlined" />}
      value={selectedTags}
    />
  )
}
export default TagFilter;
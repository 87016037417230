import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Divider, LinearProgress, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import Table from '../../../lib/Table'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../business/authProvider';
import { ListProvider, useList } from '../../../business/providers/listProvider';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
import { getBlacklistedPayees } from '../../../business/actions/blacklisted-payees';

function DataTable() {
  const auth = useAuth();
  let list = useList();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [listData, setListData] = useState([])
  const [busy, setBusy] = useState<boolean>(false);

  const authRoles= auth.roles;
  /**
   * 
   */
  let columns: Array<IColumn> = [{ field: 'email', headerName: 'Email' }, { field: 'reason', headerName: 'Reason' }]
  /**
   * Load all blacklisted
   */
  useEffect(() => {
    setListData([]);
    loadData();
  }, [list.filters, list.pagination, list.sort])
  /**
   * 
   */
  const loadData = () => {
    setBusy(true);
    getBlacklistedPayees({ ...list.pagination })
      .then(json => {
        let { TotalItems, PaginatedItems } = json;
        setTotalItems(TotalItems);
        setListData(PaginatedItems)
      }).finally(e => setBusy(false))
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Blacklisted Payees'}>
          {(auth.permission || authRoles['post_blacklisted_payees'] ) ?
            <Button component={Link} to={`/blacklisted-payees/create`} variant="contained" color="primary">
              Add Email
            </Button>
            : null
          }
        </PageHeading>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <Table
            canView={false}
            canSort={false}
            canDelete={false}
            rows={listData}
            columns={columns}
            count={totalItems}
            canSelect={false} />
        </div>
      </Paper>
    </div>
  );
}
const List = () => <ListProvider><DataTable /></ListProvider>

export default List
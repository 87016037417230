
import { get, post, patch, responseResult } from './api'
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const getExpensesCategories = (filters) => get('expense_categorizations', filters).then(responseResult)
/**
 * 
 * @param {*} tag_id 
 * @returns 
 */
export const getExpensesCategory = (categorization_id) => get(`expense_categorization/${categorization_id}`).then(responseResult)
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const createExpensesCategory = (data) => post(`expense_categorization`, data)
/**
 * 
 * @param {*} tag_id 
 * @param {*} data 
 * @returns 
 */
export const putExpensesCategory = (tag_id, data) => patch(`expense_categorization/${tag_id}`, data)
//export const deleteExpensesCategory = tag_id => fetch(`${API}/tag/${tag_id}`, { method: "DELETE", ...options }).then(responseJson)

import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { toast } from "react-toastify";
import {
  Paper,
  Grid,
  Button,
  Divider,
  LinearProgress,
  CircularProgress
} from '@material-ui/core';
import MaterialSwitch from '../../../../components/ui/MaterialSwitch';
import {
  getPermissionGroups,
  createPermissionRole,
  getPermissionRole,
  putPermissionRole
} from '../../../../business/actions/permissions'
import {
  createPayout
} from '../../../../business/actions/payouts'
import { useHistory } from 'react-router-dom';
import { validateFields } from '../../../../utils'
import PageHeading from '../../../../components/ui/PageHeading'
import { IPermissionGroup, IRole } from '../../../../core';
export default function Create(props) {
  const role_id = props.match.params?.role_id || null
  const [permissionGroups, setPermissionGroups] = useState<Array<IPermissionGroup>>([])
  const [roleGroups, setRoleGroups] = useState([
    {
      label: "Payee", name: "payees", routes: [
        { label: "Browse Payees", route: "get_payees", value: false },
        { label: "Create Payees", route: "post_payee", value: false },
        { label: "Read Payees", route: "get_payee_payee_id", value: false },
        { label: "Read Payees Details", route: "get_payee_payee_id_details", value: false },
        { label: "Edit Payees", route: "patch_payee_payee_id", value: false },
        { label: "View Payees Future Transactions", route: "get_payee_payee_id_future_transactions", value: false },
        { label: "View Payees Transactions", route: "get_payee_payee_id_transactions", value: false },
        { label: "View Payees Transferwise Details", route: "get_payee_payee_id_wise_data", value: false },
        { label: "Pay Payees", route: "post_pay", value: false },
        { label: "Delete Payees Payouts", route: "delete_payee_payee_id_payouts", value: false },
        { label: "Archive Payees", route: "delete_payee_payee_id", value: false },
        { label: "Unarchive Payees", route: "post_payee_payee_id_unarchive", value: false },
        { label: "Blacklist Payees", route: "post_blacklisted_payees", value: false },
        { label: "Browse Archived Payees", route: "get_payees_archived", value: false },
        { label: "Browse Black-Listed Payees", route: "get_blacklisted_payees", value: false },
      ]
    },
    {
      label: "Payouts", name: "payouts", routes: [
        { label: "Create Payouts", route: "post_payout", value: false },
        { label: "Read Payouts", route: "get_payout_payout_id", value: false },
        { label: "Edit Payouts", route: "patch_payout_payout_id", value: false },
        { label: "Delete Payout", route: "delete_payout_payout_id", value: false },
        { label: "Delete Payouts", route: "delete_payouts", value: false },
      ]
    },
    {
      label: "Future Transaction", name: "future_transaction", routes: [
        { label: "Browse Future Transaction", route: "get_future_transactions", value: false },
      ]
    },
    {
      label: "Transaction", name: "transaction", routes: [
        { label: "Browse Transactions", route: "get_transactions", value: false },
        { label: "Read Transactions", route: "get_transaction_transaction_id", value: false },
        { label: "Archive Transactions", route: "post_transaction_transaction_id_archive", value: false },
        { label: "Unarchive Transactions", route: "post_transaction_transaction_id_unarchive", value: false },
        { label: "Refresh Transaction Status", route: "post_transaction_transaction_id_refresh_status", value: false },
        { label: "Retry Transaction", route: "post_transaction_transaction_id_retry_transaction", value: false },
        { label: "Update Need Fixing", route: "post_transaction_transaction_id_update_need_fixing", value: false },
        { label: "Update Transaction Note", route: "post_transaction_transaction_id_update_note", value: false },
        { label: "Mass Download Invoices", route: "get_transactions_invoices", value: false },
      ]
    },
    {
      label: "Batches", name: "batches", routes: [
        { label: "Browse Batches", route: "get_batches", value: false },
        { label: "Read Batches", route: "get_batch_batch_id", value: false },
      ]
    },
    {
      label: "Payout Request", name: "requests", routes: [
        { label: "Browse Payout Request", route: "get_payout_requests", value: false },
        { label: "Generate Payout Request Link", route: "get_payout_request_link_payee_id", value: false },
        { label: "Accept Payout Request", route: "post_payout_request_request_id_accept", value: false },
        { label: "Accept and Send Payout Request", route: "post_payout_request_request_id_accept_send", value: false },
        { label: "Accept and Send Payout Request(Fake)", route: "post_payout_request_request_id_fake_accept_send", value: false },
        { label: "Delete Payout Request", route: "delete_payout_request_request_id", value: false },
      ]
    },
    {
      label: "Payouts to Italians", name: "payouts_to_italians", routes: [
        { label: "Read Settings", route: "get_settings", value: false },
        { label: "Update Settings", route: "patch_settings", value: false },
        { label: "Edit Payouts", route: "patch_payout_payout_id", value: false },
        { label: "Delete Payout", route: "delete_payout_payout_id", value: false },
        { label: "Delete Payouts", route: "delete_payouts", value: false },
      ]
    },
    {
      label: "Manual Payouts", name: "manual_payouts", routes: [
        { label: "Read Manual Payouts", route: "get_manual_payouts", value: false }
      ]
    },
    {
      label: "Tags", name: "rags", routes: [
        { label: "Browse Tags", route: "get_tags", value: false },
        { label: "Create Tags", route: "post_tag", value: false },
        { label: "Read Tags", route: "get_tag_tag_id", value: false },
        { label: "Edit Tags", route: "patch_tag_tag_id", value: false },
      ]
    },
    {
      label: "Roles", name: "roles", routes: [
        { label: "Browse Roles", route: "get_permissions_roles", value: false },
        { label: "Create Roles", route: "post_permissions_role", value: false },
        { label: "Read Roles", route: "get_permissions_role_role_id", value: false },
        { label: "Edit Roles", route: "patch_permissions_role_role_id", value: false },
        { label: "Delete Permission Group", route: "delete_permissions_role_role_id", value: false },
        { label: "Browse Permission Group", route: "get_permissions_groups", value: false }
      ]
    },
    {
      label: "Permission Users", name: "permission_users", routes: [
        { label: "Browse Permission Users", route: "get_permissions_users", value: false },
        { label: "Create Permission Users", route: "post_permissions_user", value: false },
        { label: "Read Permission Users", route: "get_permissions_user_email", value: false },
        { label: "Edit Permission Users", route: "patch_permissions_user_email", value: false },
        { label: "Delete Permission Users", route: "delete_permissions_user_email", value: false }
      ]
    },
    {
      label: "Expense Categories", name: "categorization", routes: [
        { label: "Browse Expense Category", route: "get_expense_categorizations", value: false },
        { label: "Read Expense category", route: "get_expense_categorization_categorization_id", value: false },
        { label: "Create Expenses Category", route: "post_expense_categorization", value: false },
        { label: "Update Expenses Category", route: "patch_expense_categorization_categorization_id", value: false },
        { label: "Delete Expenses Category", route: "delete_expense_categorization_categorization_id", value: false },
      ]
    },
    {
      label: "Payout Invoices", name: "invoicing", routes: [
        { label: "Browse Invoices", route: "get_invoices", value: false },
        { label: "Read Invoices", route: "get_invoice_invoice_id", value: false },
        { label: "Create Invoices", route: "post_invoice", value: false },
        { label: "Update Invoices", route: "patch_invoice_invoice_id", value: false },
        { label: "Delete Invoices", route: "delete_invoice_invoice_id", value: false },
      ]
    },
    {
      label: "Full Reports", name: "reporting", routes: [
        { label: "Browse Reports", route: "get_report" }
      ]
    },
    {
      label: "Settings", name: "settings", routes: [
        { label: "Read Company Billing Details", route: "get_company_billing_details", value: false },
        { label: "Update Company Billing Details", route: "patch_company_billing_details", value: false },
        { label: "Generate Token", route: "post_generate_token", value: false },
        { label: "Overwrite Payout Request", route: "overwrite_payout_requests", value: false },
        { label: "Read Public Key", route: "get_admin_public_key", value: false },
        { label: "Update Public Key", route: "post_admin_tw_key", value: false },
        { label: "Read Remaining Amount", route: "get_remaining_limit_amount", value: false },
      ]
    },
    {
      label: "Miscellenous", name: "misc", routes: []
    },
  ])

  const [formData, setFormData] = useState({})
  const [busy, setBusy] = useState(true)
  const [busy2, setBusy2] = useState(false)
  const history = useHistory()



  useEffect(() => {
    getPermissionGroups({}).then(json => {
      let { TotalItems, PaginatedItems } = json;
      setPermissionGroups(PaginatedItems)
      if (role_id) {
        return getPermissionRole(role_id).then(json => {
          let form_data = {}
          form_data["name"] = json.name;
          json.cognito_groups.forEach(item => {
            roleGroups.forEach(roleGroup => {
              if (!form_data.hasOwnProperty(roleGroup.name)) {
                form_data[roleGroup.name] = [];
              }
              roleGroup.routes.forEach(groupRoute => {
                if (groupRoute.route === item) {
                  form_data[roleGroup.name].push(groupRoute.route)
                }
              })
            })
          })
          //
          const unified = roleGroups.map(a => a.routes.map(b => b.route)).flat();
          const permi = PaginatedItems.map(a => a.name);
          const miscRoutes = permi.filter(a => !unified.find(b => a === b));
          roleGroups[roleGroups.length - 1].routes = miscRoutes.map(a => {
            let jsonRoute = PaginatedItems.find(b => b.name === a)
            return {
              label: jsonRoute.description, route: jsonRoute.name
            }
          })
          //
          setRoleGroups(roleGroups)
          setFormData(form_data)
        });
      }
    }).finally(() => {
      setBusy(false)
    })
  }, [])
  const onSubmit = async values => {
    setBusy2(true)
    let form_data = { name: values.name, cognito_groups: [] }
    Object.keys(values).map(key => {
      if (key !== "name") {
        form_data.cognito_groups = [...form_data.cognito_groups, ...values[key]]
      }
    })
    if (role_id) {
      putPermissionRole(role_id, form_data)
        .then(response => { toast('Role updated.'); setTimeout(history.goBack, 1000) })
        .catch(error => toast(error.response.data?.message || "Unexpected error"))
        .finally(() => { setBusy2(false) })
    } else {
      createPermissionRole(form_data)
        .then(response => { history.goBack(); toast('Role created'); })
        .catch(error => toast(error.response.data?.message || "Role is not created. Unexpected error"))
        .finally(() => { setBusy2(false) })
    }
  };
  const validate = values => validateFields(values, ["name"])
  return (
    <div className={"form-wrapper"}>
      {busy ? <LinearProgress /> :
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators
          }}
          initialValues={formData}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper>
                <PageHeading title={`${role_id ? 'Edit' : 'Create'} Role`} />
                <Divider />
                <div style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Field name="name" fullWidth required component={TextField} type="text" label="Role Name" />
                    </Grid>
                    <Grid item xs={12}><span>Permissions</span></Grid>
                    {
                      roleGroups.map(roleGroup => <Grid key={roleGroup.name} item xs={12}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>{roleGroup.label}</span>
                        </div>
                        <div>
                          <FieldArray
                            name={roleGroup.name}
                            options={roleGroup.routes}
                            type="checkbox"
                            fullWidth
                            component={CheckboxGroup} />
                        </div>
                      </Grid>
                      )
                    }
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => form.reset()}
                        disabled={submitting || pristine}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </form>
          )}
        />
      }
      {busy2 ? <div className='busy-progress-circular'><CircularProgress /></div> : null}
    </div>
  );
}



const CheckboxGroup = ({ fields, options }) => {
  const toggle = (event, option) => {
    if (event.target.checked) fields.push(option);
    else fields.remove(fields.value.findIndex(field => field === option));
  };
  return (
    <div style={{ color: "blue" }}>
      {options.map(option => <div key={option.route}>
        <MaterialSwitch
          label={option.label}
          input={{
            onChange: event => toggle(event, option.route),
            value: option.route,
            checked: fields.value?.findIndex(field => field === option.route) >= 0 || false
          }}
        />
      </div>)}
    </div>
  );
};
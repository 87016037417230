import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import DataGrid from '../../../components/ui/Table'
import Filters from '../../../components/Filters'

const columns = [
  { field: 'tag', headerName: 'Tag' },
];

const rows = [
  {
    id: 1,
    tag:'Marketing'
  },{
    id: 2,
    tag:'Booster'
  },
];
const filters = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'amount_from', type: 'TextField', label: "Amount from" },
  { id: 'amount_to', type: 'TextField', label: "Amount to" },
  { id: 'payout_method', type: 'Select', label: "Payout Method", options: [{ label: 'A' }, { label: 'B' }] }
]
export default function DataTable() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 16 }}>
        <Typography variant="h6" id="tableTitle" component="div">
        Tags
        </Typography>
      </div>
      <DataGrid route={'tags'} canEdit={true} canDelete={true} rows={rows} columns={columns} pageSize={5} checkboxSelection />
    </div>
  );
}
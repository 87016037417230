import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControlProps } from '@material-ui/core/FormControl';
import { FieldRenderProps, FieldInputProps } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

interface FormHelperTextWrapperProps extends FieldInputProps {
	label: string,
	multiple: boolean,
	formControlProps: FormControlProps,
}

const FormHelperTextWrapper: React.SFC<FormHelperTextWrapperProps> = ({
	input: { name, value, onChange, ...restInput },
	meta,
	label,
	multipleSelect,
	formControlProps,
	...rest
}, props) => {
	const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
	return (
		<FormControl {...formControlProps} error={showError}>
			<Autocomplete
				{...rest}
				multiple={multipleSelect}
				name={name}
				onChange={(event, newValue) => {
					onChange(newValue);
				}}
				renderInput={(params) => <TextField {...params} label={label} margin="normal" />}
				value={value}
			/>
			{showError &&
				<FormHelperText>{meta.error || meta.submitError}</FormHelperText>
			}
		</FormControl>
	);
};

export default FormHelperTextWrapper;
import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Paper, Typography } from '@material-ui/core';
import DataGrid from '../../../lib/Table'
import Filters from '../../../lib/Filters'
import { getHistoryLogs } from '../../../business/actions/history-logs'
import { convertDateToItalyTime, trimFilterData } from '../../../utils'
import { ListProvider, useList } from '../../../business/providers/listProvider';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
const columns: Array<IColumn> = [
  { field: 'datetime', headerName: 'Date and Time' },
  { field: 'payee_id', headerName: 'Payee ID' },
  { field: 'payout_id', headerName: 'Payout ID' },
  { field: 'payout_amount', headerName: 'Payout Amount', formatting: num => num.toFixed(2) },
  { field: 'payout_status', headerName: "Payout Status" },
  { field: 'created_by', headerName: "Created By" },
  { field: 'description', headerName: 'Description' },
];

const _filters: Array<IFilter> = [
  { id: 'batch_id', type: 'TextField', label: "Batch ID" },
  { id: 'from_date', type: 'Date', label: "Date From" },
  { id: 'to_date', type: 'Date', label: "Date To" },
  { id: 'sent_by', type: 'TextField', label: "Sent By" },
  { id: 'tags', type: 'TagFilter', label: "Tags" },
]
function DataTable() {
  const list = useList();
  const [busy, setBusy] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [listData, setListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  /**
   * Load all future transactions
   */
  useEffect(() => loadData(), [])
  useEffect(() => {
    updateDataWithFilter();
  }, [list.filters, list.pagination, list.sort])


  const loadData = () => {
    setBusy(true)
    getHistoryLogs({ ...trimFilterData(list.filters), ...list.pagination, ...list.sort })
      .then(json => {
        let { TotalItems, PaginatedItems } = json;
        let data = PaginatedItems.map(entry => ({ ...entry, datetime: convertDateToItalyTime(entry.created_at) })).sort((a, b) => b.created_at - a.created_at);
        setTotalItems(TotalItems);
        setListData(data)
        setFilteredData(data.slice(0, list.pagination.limit))
      })
      .finally(e => setBusy(false))
  }
  /**
   * 
   * @returns 
   */
  const updateDataWithFilter = () => setFilteredData(listData.slice(list.pagination.offset, list.pagination.offset + list.pagination.limit));
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper>
        <PageHeading title={'History Logs: List of manual payouts those are added through BBPO'}></PageHeading>
        <Divider />
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <DataGrid
            idField={'batch_id'}
            canView={false}
            route={'batches'}
            title={'Batches'}
            canSort={false}
            rows={filteredData}
            columns={columns}
            count={totalItems}
            checkboxSelection />
        </div>
      </Paper>
    </div>
  );
}
const List = () => <ListProvider><DataTable /></ListProvider>
export default List
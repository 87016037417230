import React, { useEffect, useState } from 'react';
import { Divider, LinearProgress, Paper, Typography, Checkbox } from '@material-ui/core';
import DataGrid from '../../../lib/Table';
import Filters from '../../../lib/Filters';
import { getTransactions, updateTransactionNeedFixing } from '../../../business/actions/transactions';
import { convertDateToItalyTime, trimFilterData, transactionStatuses } from '../../../utils';
import { ListProvider, useList } from '../../../business/providers/listProvider';
import CloudDownload from '@material-ui/icons/';
import { toast } from 'react-toastify';

import { useAuth } from '../../../business/authProvider';
import { IColumn, IFilter } from '../../../core';
import PageHeading from '../../../components/ui/PageHeading';
import IconButton from '../../../components/ui/IconButton';
import { getRequests, acceptAndListRequest, acceptAndSendRequest, deleteRequest, getRequestLink, fakeAcceptAndSendRequest } from '../../../business/actions/requests';
import ProgressActionButton from '../../../components/ProgressActionButton';
import RichButton from '../../../components/RichButton';
import { TPayoutRequest } from '../../../core';
import { Link } from 'react-router-dom';
import ToolTip from '../../../components/ToolTip';
import { downloadFromS3 } from '../../../business/utils';


const columns: Array<IColumn> = [
  { field: 'created_at', headerName: 'Date of Request', formatting: timestamp => convertDateToItalyTime(timestamp) },
  { field: 'payee_id', headerName: 'Payee ID/ Payout Email' },
  { field: 'name', headerName: 'Name and Surname' },
  {
    field: 'username', headerName: 'Username', renderValue: (value, entry) => <>
      <Link to={`/payees/${entry.payee_id}/view`} target={"_blank"}>{value}</Link>
      {
        (entry.is_italian === "Italian Ritenuta" || entry.is_italian === "Italian Partita Iva") ? <>
          <img src="/assets/media/images/italian-flag.svg" style={{ width: 24, marginLeft: 4, marginRight: 4 }} />
          {
            entry.is_italian === "Italian Ritenuta" ? "-20" : "P.Iva"
          }</> : null
      }
    </>
  },
  { field: 'payout_amount', headerName: 'Payout Amount', formatting: num => num?.toFixed(2) || num },
  { field: 'payout_method', headerName: 'Payout Method' },
  { field: 'description', headerName: 'Reason/Description' },
  { field: 'invoice', headerName: 'Invoice' }
];

const _filters: Array<IFilter> = [
  { id: 'payee_id', type: 'TextField', label: "Payee ID" },
  { id: 'payout_email', type: 'TextField', label: "Payout Email" },
  { id: 'name', type: 'TextField', label: "Name and Surname" },
  { id: 'username', type: 'TextField', label: "Username" },
  { id: 'from_date', type: 'Date', label: "Date From" },
  { id: 'to_date', type: 'Date', label: "Date To" },
  { id: 'status', type: 'MultipleSelect', label: "Status", labelField: "label", idField: "value", options: [{ label: 'Accepted & Sent', value: "paid" }, { label: 'Accepted & Listed', value: "accepted" }, { label: 'Whitelisted', value: "whitelisted" }, { label: 'Deleted', value: "deleted" }] },
  { id: 'include_deleted', type: 'CheckBox', label: "Show Deleted", value: true },
  { id: 'include_accepted', type: 'CheckBox', label: "Show Accepted", value: true }
]
function DataTable(props) {
  const list = useList();
  const auth = useAuth();
  const [busy, setBusy] = useState(false)
  const [listData, setListData] = useState<Array<TPayoutRequest>>([])
  const [totalItems, setTotalItems] = useState(0);
  const authRoles = auth.roles;
  const { failed, archived } = props;
  const filterFields = [..._filters.filter(_filter => (failed && (_filter.id !== "payout_status" && _filter.id !== "hide_failed")) || !failed)]
  /**
   * Load list data when filter/paging updates
   */
  useEffect(() => {
    setListData([]);
    loadData();
  }, [list.filters, list.pagination, list.sort])

  const loadData = () => {
    setBusy(true)
    getRequests({ ...trimFilterData(list.filters), ...list.pagination, ...list.sort })
      .then(json => {
        let { TotalItems, PaginatedItems } = json;
        setTotalItems(TotalItems);
        setListData(PaginatedItems)
      }).finally(e => setBusy(false))
  }
  const downloadInvoice = (data: TPayoutRequest) => downloadFromS3(data.invoice,data.file_name || "invoice.pdf") 
  /**
   * 
   */
  const fakeAcceptAndSendPayoutRequest = (data: TPayoutRequest) => fakeAcceptAndSendRequest(data.payout_request_id)
  /**
   * 
   */
  const acceptPayoutRequest = (data: TPayoutRequest) => acceptAndListRequest(data.payout_request_id)
  /**
   * 
   */
  const acceptAndSendPayoutRequest = (data: TPayoutRequest) => acceptAndSendRequest(data.payout_request_id)
  /**
   * 
   */
  const deletePayoutRequest = (data: TPayoutRequest) => deleteRequest(data.payout_request_id)
  /**
   * 
   */
  const generateLink = () => getRequestLink().then(response => navigator.clipboard.writeText(response.link)).then(response => toast("Link copied!", { type: toast.TYPE.INFO, position: toast.POSITION.BOTTOM_CENTER }))
  //
  const getCustomActions = (entry) => {
    //whitelisted
    let noaction = null;
    const actions = [
      <RichButton
        type='IconButton'
        icon='ThumbUp'
        action={fakeAcceptAndSendPayoutRequest}
        data={entry}
        onComplete={loadData}
        shouldConfirm={true}
        waitBeforeTriggerComplete={1000}
        confirmText="Are you sure you want to mark this request as paid?"
        toolTip="Whitelist the request as paid, without actually sending a payment. Used for payout methods that are not connected to BBPO yet"
      />,
      <RichButton
        type='IconButton'
        icon='PlaylistAddCheck'
        action={acceptPayoutRequest}
        data={entry}
        onComplete={loadData}
        shouldConfirm={true}
        waitBeforeTriggerComplete={1000}
        confirmText="Are you sure you want to accept this request?"
        toolTip="Accept and list request"
      />,
      <RichButton
        type='IconButton'
        icon='Send'
        action={acceptAndSendPayoutRequest}
        data={entry}
        onComplete={loadData}
        waitBeforeTriggerComplete={1000}
        shouldConfirm={true}
        confirmText="Are you sure you want to accept and send this request?"
        toolTip="Accept and send request"
      />,
      <RichButton
        type='IconButton'
        icon='Delete'
        action={deletePayoutRequest}
        data={entry}
        onComplete={loadData}
        waitBeforeTriggerComplete={1000}
        shouldConfirm={true}
        confirmText="Are you sure you want to delete this request?"
        toolTip="Delete request"
      />
    ]
    if (entry.status === "whitelisted") {
      noaction = [
        <ToolTip tooltip={"This request was Whitelisted, but the payment was not listed or paid. This is probably because the payment was executed from a payment method that is currently unavailable on BBPO. You should always make sure that these payments are, however, uploaded in the list of Invoices/PDFs to classify all of our costs."}><div>Whitelisted</div></ToolTip>
      ]
    }
    else if (entry.status === "accepted") {
      noaction = [<ToolTip tooltip={'This request was accepted and listed for payouts. If it was sent, you will see the correspondent payout in the ‘Transactions’ list. If it has not been sent yet, you will see it in the ‘Future Transactions’ list.'}><div>Accepted and listed</div></ToolTip>]
    }
    else if (entry.status === "paid") {
      noaction = [<ToolTip tooltip={'This request was accepted and immediately paid out. You will find it in the ‘Future transactions’ list.'}><div>Accepted and sent</div></ToolTip>]
    }
    else if (entry.status === "deleted") {
      noaction = [<ToolTip tooltip={'This request was deleted. Maybe an admin can overwrite this action, if needed.'}><div>Deleted</div></ToolTip>]
    }

    if (authRoles["overwrite_payout_requests"]) return [<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>{actions}</div>
      <div>{noaction}</div>
    </div>]

    return noaction || actions;
  }
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Paper>
        <PageHeading title={'Requests: list of the payment requests from Payees'}>
          <RichButton
            type='NormalButton'
            icon='Link'
            data={null}
            action={generateLink}
            label={"COPY LINK TO GENERATE A REQUEST"}
            toolTip="If you pass this link to a BlazingBoost company member, he can send a Payout Request to BlazingBoost Payout. The user will need to have the necessary Payout Data of the Payee, so that a request can be generated. Do NOT share this link outside of the company or you may face legal responsibility."
          />
        </PageHeading>
        <Divider />
        <div style={{ padding: 16 }}>
          <Filters data={filterFields} />
        </div>
        {busy ? <LinearProgress /> : null}
        <div style={{ padding: 16 }}>
          <DataGrid
            idField="transaction_id"
            route={'transactions'}
            canView={false}
            canDelete={false}
            customActions={true}
            rows={listData.map(entry => ({
              ...entry,
              mode: entry.is_valid ? "normal" : "error",
              payee_name: `${entry["first_name"]} ${entry["last_name"]}`,
              invoice: entry.invoice ? <RichButton
                type='IconButton'
                icon='CloudDownload'
                action={downloadInvoice}
                data={entry}
                iconColor={entry.is_valid ? "inherit" : "white"}
                toolTip="Download Invoice"
              /> : null,
              customActions: entry.is_valid ? getCustomActions(entry) : [
                <RichButton
                  type='IconButton'
                  icon='Delete'
                  action={deletePayoutRequest}
                  data={entry}
                  iconColor={"white"}
                  onComplete={loadData}
                  waitBeforeTriggerComplete={1000}
                  shouldConfirm={true}
                  confirmText="Are you sure you want to delete this request?"
                  toolTip="Delete request"
                />
              ]
            }))}
            columns={columns}
            count={totalItems}
            canSort={false}
          />
        </div>
      </Paper>
    </div>
  );
}


const List = props => <ListProvider><DataTable {...props} /></ListProvider>

export default List
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import { toast } from "react-toastify";
import {
  Paper,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import {
  getPayees
} from '../../../business/actions/payees'
import {
  createPayout
} from '../../../business/actions/payouts'
import { useHistory } from 'react-router-dom';
import { validateFields } from '../../../utils'
import PageHeading from '../../../components/ui/PageHeading'
export default function Create(props) {
  const edit = props.match.params?.id || null
  //const [payees, setPayees] = useState([])
  const history = useHistory()
  const query = new URLSearchParams(props.location.search);
  /*useEffect(() => {
    getPayees({ offset: "0" }).then(json => {
      let { PaginatedItems } = json;
      setPayees(PaginatedItems);
    })
  }, [])*/
  const onSubmit = async values => {
    createPayout(values)
      .then(response => { history.goBack(); toast('Payout created'); })
      .catch(error => toast(error.response.data?.message || "Payout is not created. Unexpected error"))
  };
  const validate = values => validateFields(values, ["payee_id", "payout_id", "payout_amount"])
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper>
            <PageHeading title={`${edit ? 'Edit' : 'Create'} Payout`} />
            <Divider />
            <Form
              onSubmit={onSubmit}
              initialValues={{
                payee_id: query.get('payee_id', ""),
                description: '',
                payout_amount: null,
                payout_currency: "EUR"
              }}
              validate={validate}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Paper style={{ padding: 16 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Field name="payee_id" fullWidth required component={TextField} type="text" label="Payee ID" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="payout_id" fullWidth required component={TextField} type="text" label="Payout ID" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="description" fullWidth component={TextField} multiline type="text" label="Payout Description" />
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="payout_amount" fullWidth required component={TextField} type="text" label="Payout Amount" />
                      </Grid>
                      <Grid item style={{ marginTop: 16 }}>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => form.reset()}
                          disabled={submitting || pristine}
                        >
                          Reset
                        </Button>
                      </Grid>
                      <Grid item style={{ marginTop: 16 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import PayeeForm from '../../../components/forms/Billing'
import Autocomplete from '../../../components/ui/AutoComplete'
import PageHeading from '../../../components/ui/PageHeading'
import {
  Typography,
  Paper,
  Switch,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import { getPayee, putPayee, createPayee, archivePayee } from '../../../business/actions/payees'
import { getTags } from '../../../business/actions/tags'
import { validateFields } from '../../../utils'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { TPayee, TPayeeBilling } from '../../../core/types';
import { ITag } from '../../../core';
import { ADVCASH, PAYPAL, TRANSFERWISE, PAYONEER, REVOLUT } from '../../../utils/vars';
const testData = {
  "tags": [],
  "username": "sameerjain",
  "payee_id": "payee-2",
  "email": "sameer.jain@saachitech.com",
  "payout_method": "Paypal",
  "payee_note": "This is a test payee",
  "pause_payee": true,
}
export default function Create(props) {
  const history = useHistory();
  /**
   * We might be editing a payee or creating new payee. THis could be identify with thie payee_id param
   */
  const payee_id = props.match.params?.payee_id || null
  /**
   * This will be populated with the payee details if we are editing any payee
   */
  const [data, setData] = useState<TPayeeBilling>()
  /**
   * If we are editing a payee, lets fetch the details of the payee
   */
  useEffect(() => {
    if (payee_id) getPayee(payee_id).then(json => {
      let { real_first_name, real_last_name, address, region, city, country } = json;
      setData({ real_first_name, real_last_name, address, region, city, country })
    });
  }, [payee_id])
  /**
   * 
   * @param {*} values 
   * This function execute by the react-final-form library
   * We decide here wheather it's an update of a payee or new payee
   */
  const onSubmit = (values) => {
    let { real_first_name, real_last_name, address, region, city, country } = values;
    let payeeData: TPayeeBilling = { real_first_name, real_last_name, address, region, city, country };
    if (payee_id) {
      putPayee(payee_id, payeeData)
        .then(response => { toast('Payee updated.'); setTimeout(history.goBack, 1000) })
        .catch(error => toast(error.response.data?.message || "Unexpected error"))
    }
    return true;
  }

  const validate = values => validateFields(values, [
    "real_first_name",
    "real_last_name",
    "address",
    "region",
    "city",
    "country"
  ])
  return (
    <div style={{ height: '100%', width: '100%', maxWidth: 650, }}>
      <div style={{ margin: 'auto' }}>
        <CssBaseline />
        <Paper>
          <PageHeading title={`${payee_id ? 'Edit' : 'Create'} Billing`} />
          <Divider />
          <PayeeForm mode={payee_id ? 'edit' : 'add'} validate={validate} onSubmit={onSubmit} formData={data} />
        </Paper>
      </div>
    </div>
  );
}
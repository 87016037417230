import Transactions from './Transactions';
import PayeeList from './Payees'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import { useAuth } from '../../../business/authProvider';

const TransactionList = props => <><Transactions archived={true} {...props} /></>
const FailedTransactionList = props => <><Transactions archived={true} failed={true} {...props} /></>
/*
const ArchiveRoutes = () => {
  return (<Switch>
    <Route path={'/archive/payees'} component={PayeeList} />
    <Route path={'/archive/transactions'} render={TransactionList} />
    <Route path={'/archive/failed-transactions'} component={FailedTransactionList} />
  </Switch>
  )
}
*/
const ArchiveRoutes = () => {
  let auth = useAuth()
  let { path, url } = useRouteMatch();
  const authRoles= auth.roles;
  return (<Switch>
    {(auth.permission || authRoles['get_payees_archived']) ? <Route path={'/archive/payees'} component={PayeeList} /> : null}
    {(auth.permission || authRoles['get_transactions']) ? <Route path={'/archive/transactions'} render={TransactionList} /> : null}
    {(auth.permission || authRoles['get_transactions']) ? <Route path={'/archive/failed-transactions'} component={FailedTransactionList} /> : null}
  </Switch>
  )
}
export default ArchiveRoutes
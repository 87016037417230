import { downloadFromS3 } from "../business/utils";
import RichButton from "./RichButton"

export default ({ invoice }: { invoice: string }) => {
  const downloadInvoice = (data: string) => downloadFromS3(data || "", "invoice.pdf")
  return (
    <RichButton
      type='IconButton'
      icon='CloudDownload'
      action={downloadInvoice}
      data={invoice}
      toolTip="Download Invoice"
    />
  )
}
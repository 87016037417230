import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

const SelectExtended = (props) => {
  let { allowEmpty, id, label, options, value, multiple } = props;
  const _onChange = event => props.onChange(props.id, event.target.value)
  return (
    <Select
      labelId={id}
      id={id}
      multiple={multiple}
      value={value}
      onChange={_onChange}
      label={label}
    >{allowEmpty ? <MenuItem value=""><em>Any</em></MenuItem> : null}
      {options.map(option => <MenuItem key={option.label} value={option.hasOwnProperty("value") ? option.value : option.label}>{option.label}</MenuItem>)}
    </Select>
  )
}

export default SelectExtended
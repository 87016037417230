import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Select, Radio } from 'final-form-material-ui';
import Autocomplete from '..//ui/AutoComplete'
import {
  Grid,
  Button,
  MenuItem,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import MaterialSwitch from '../ui/MaterialSwitch';
import { ADVCASH, PAYPAL, REVOLUT, TRANSFERWISE } from '../../utils/vars';
import ToolTip from '../ToolTip';
const PayeeForm = ({
  validate,
  onSubmit,
  onArchive,
  formData,
  data,
  mode
}) => <Form
    onSubmit={onSubmit}
    initialValues={{
      tags: [],
      username: '',
      payee_id: '',
      first_name: '',
      last_name: '',
      email: '',
      revolut_id: '',
      discord: '',
      payout_fee: "0.038",
      payout_method: null,
      payout_email: null,
      transferwise_id: null,
      payee_note: null,
      pause_payee: false,
      advcash_payout_email: '',
      advcash_payout_fee: "0",
      transferwise_fee_by_payee: "true",
      is_italian: false,
      send_regular_payout_request_link: false,
      optional_payout_email: "",
      ...formData
    }}
    validate={validate}

    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit} noValidate>
        <FormControl>
          <div style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="tags" multipleSelect
                  fullWidth required component={Autocomplete} label="Tags"
                  formControlProps={{ fullWidth: true }}
                  options={data?.tagOptions || []}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="username" fullWidth component={TextField} type="text" label="Username" />
              </Grid>
              <Grid item xs={12}>
                <Field disabled={mode === 'edit'} name="payee_id" fullWidth required component={TextField} type="text" label="Payee ID" />
              </Grid>
              <Grid item xs={12}>
                <Field name="first_name" fullWidth component={TextField} type="text" label="First Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="last_name" fullWidth component={TextField} type="text" label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <Field name="email" fullWidth required component={TextField} type="text" label="Email" />
              </Grid>
              <Grid item xs={12}>
                <Field name="discord" fullWidth component={TextField} type="text" label="Discord" />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="payout_method"
                  component={Select}
                  required
                  label="Select a Payout Method *"
                  formControlProps={{ fullWidth: true }}
                  data-testid="payee-select-payout-method"
                >
                  <MenuItem data-testid="payee-select-payout-method-paypal" value="Paypal">Paypal</MenuItem>
                  <MenuItem data-testid="payee-select-payout-method-transferwise" value="TransferWise">TransferWise</MenuItem>
                  <MenuItem data-testid="payee-select-payout-method-advcash" value="AdvCash">AdvCash</MenuItem>
                  <MenuItem data-testid="payee-select-payout-method-payoneer" value="Payoneer">Payoneer</MenuItem>
                  <MenuItem data-testid="payee-select-payout-method-revolut" value="Revolut">Revolut</MenuItem>
                </Field>
              </Grid>
              {values.payout_method === PAYPAL ?
                <Grid item xs={12}>
                  <Field name="payout_email" data-testid="payee-payout_email" fullWidth component={TextField} type="text" label="Paypal Email" />
                </Grid>
                : values.payout_method === TRANSFERWISE ?
                  <Grid item xs={12}>
                    <Field name="transferwise_id" data-testid="payee-transferwise_id" fullWidth component={TextField} type="text" label="TransferWise Recepient ID" />
                  </Grid>
                  : values.payout_method === ADVCASH ?
                    <Grid item xs={12}>
                      <Field name="advcash_payout_email" data-testid="payee-advcash_payout_email" fullWidth component={TextField} type="text" label="Advcash email" />
                    </Grid>
                    : values.payout_method === REVOLUT ?
                      <Grid item xs={12}>
                        <Field name="revolut_id" data-testid="payee-revolut_id" fullWidth component={TextField} type="text" label="Revolut Counterparty ID" />
                      </Grid>
                      : null
              }
              {values.payout_method === PAYPAL ?
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ fontWeight: "500" }} component="div" gutterBottom>Paypal Payout Fee</Typography>
                  </Grid>
                  <FormControl component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel data-testid="payee-payout_fee_2" label={"2%"} control={<Field name="payout_fee" component={Radio} type="radio" value={"0.02"} />} />
                      <FormControlLabel data-testid="payee-payout_fee_38" label={"3.8%"} control={<Field name="payout_fee" component={Radio} type="radio" value={"0.038"} />} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                : values.payout_method === TRANSFERWISE ?
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{ fontWeight: "500" }} component="div" gutterBottom>Transferwise Fee</Typography>
                    </Grid>
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel data-testid="payee-transferwise_fee_by_payee" label={"Paid by Payee"} control={<Field name="transferwise_fee_by_payee" component={Radio} type="radio" value={"true"} />} />
                        <FormControlLabel data-testid="payee-transferwise_fee_by_bb" label={"Paid by BlazingBoost"} control={<Field name="transferwise_fee_by_payee" component={Radio} type="radio" value={"false"} />} />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  : values.payout_method === ADVCASH ?
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ fontWeight: "500" }} component="div" gutterBottom>AdvCash Fee</Typography>
                      </Grid>
                      <FormControl component="fieldset">
                        <RadioGroup row>
                          <FormControlLabel data-testid="payee-advcash_payout_fee_0" label={"0%"} control={<Field name="advcash_payout_fee" component={Radio} type="radio" value={"0"} />} />
                          <FormControlLabel data-testid="payee-advcash_payout_fee_.7" label={"0.2%"} control={<Field name="advcash_payout_fee" component={Radio} type="radio" value={"0.002"} />} />
                          <FormControlLabel data-testid="payee-advcash_payout_fee_.5" label={"0.5%"} control={<Field name="advcash_payout_fee" component={Radio} type="radio" value={"0.005"} />} />
                          <FormControlLabel data-testid="payee-advcash_payout_fee_2" label={"2%"} control={<Field name="advcash_payout_fee" component={Radio} type="radio" value={"0.02"} />} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    : null
              }
              <Grid item xs={12}>
                <Field fullWidth name="payee_note" component={TextField} rows={1} multiline label="Payee Notes" />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="pause_payee"
                  label="Pause Payee"
                  tooltip={"This is the Payee status. When the status is turned on it means that the Payee is Paused and payments can not be sent to this payee until his status is changed back = unpaused."}
                  type="checkbox"
                  fullWidth
                  required
                  component={MaterialSwitch} />
              </Grid>
              {/*<Grid item xs={12}>
                <Field name="is_italian" label="Italian?"
                  type="checkbox" fullWidth required
                  component={MaterialSwitch} />
            </Grid>*/}
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ fontWeight: "500" }} component="div" gutterBottom>
                    Italian?
                    <ToolTip tooltip={"If the payee is Italian with Ritenuta -20%, then any amount listed for this payee will be reduced by 20%. If he is Italian with Partita Iva (Tax Number), then this payee will get paid fully, although the management needs to make sure that within 12 days we receive an Electronic Receipt by the Payee’s Tax Number (Fatturazione Elettronica)"} />
                  </Typography>
                </Grid>
                <FormControl component="fieldset">
                  <RadioGroup row>
                    <FormControlLabel data-testid="payee-italian_not-italian" label={"No"} control={<Field name="is_italian" component={Radio} type="radio" value={"Not italian"} />} />
                    <FormControlLabel data-testid="payee-italian_italian-ritenuta" label={"Yes with Ritenuta -20%"} control={<Field name="is_italian" component={Radio} type="radio" value={"Italian Ritenuta"} />} />
                    <FormControlLabel data-testid="payee-italian_italian-partita-iva" label={"Yes with Partita Iva Forfetaria(Tax Number)"} control={<Field name="is_italian" component={Radio} type="radio" value={"Italian Partita Iva"} />} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="send_regular_payout_request_link"
                  label="Enable URL token generator for internal workers?"
                  type="checkbox"
                  fullWidth
                  required
                  component={MaterialSwitch}
                  tooltip={"If enabled, this button will turn itself off in 6 months (180 days). While enabled, every 25th of every month, the Payee will receive an email to his Payee Email with an automated monthly-generated URL with the Request token. With this link, the payee can send a Payment Request to BlazingBoost for monthly services rendered to the company. The URL with the Request Token will work for 30 days and can only be used once"}
                />
              </Grid>
              {
                values.send_regular_payout_request_link ?
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <Field name="optional_payout_email" data-testid="payee-optional_payout_email" fullWidth component={TextField} type="text" label="Optional email address" />
                    <ToolTip tooltip={"Normally, the Payee will receive an email to his Payee Email with the URL for the request. This optional email address will ALSO receive the same Token. This is used in case another company supervisor is managing payment requests for some workers and sending the request on their behalf."} />
                  </Grid>
                  :
                  null
              }
              <Grid item style={{ marginTop: 16 }}>
                <Button type="button" variant="contained" onClick={() => form.reset()} disabled={submitting || pristine}>
                  Reset
                </Button>
              </Grid>
              <Grid item style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" type="submit" disabled={submitting || pristine}>
                  {mode === 'edit' ? 'Update' : 'Submit'}
                </Button>
              </Grid>
              {mode === "edit" ? <Grid item style={{ marginTop: 16 }} onClick={onArchive}>
                <Button variant="contained" color="primary">Archive</Button>
              </Grid> : null}
            </Grid>
          </div>
        </FormControl>
      </form>
    )}
  />



export default PayeeForm
import React from 'react'
import './App.css';
import AppBar from './containers/appBar'
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { ConfirmProvider } from 'material-ui-confirm';
import 'react-toastify/dist/ReactToastify.css';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AuthProvider, useAuth } from './business/authProvider'
import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from '@material-ui/core/styles';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [{
      id: process.env.REACT_APP_AWS_API_ID,
      name: process.env.REACT_APP_AWS_API_NAME,
      endpoint: process.env.REACT_APP_AWS_API_ENDPOINT,
      region: process.env.REACT_APP_AWS_API_REGION
    }]
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        boxShadow: "none"
      }
    },
    MuiPopover: {
      paper: {
        //boxShadow: "none"
      }
    }
  },
});
function App(props: any) {
  const auth = useAuth();
  if (!auth.roles) return null;
  return (
    <div style={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Router>
            <AppBar />
          </Router>
        </ConfirmProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover />
      </ThemeProvider>
    </div>
  )
}

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object>();
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? <AuthProvider><App /></AuthProvider> : <AmplifyAuthenticator>
    <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
  </AmplifyAuthenticator>
}
export default AuthStateApp;